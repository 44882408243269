export enum RoutePath {
  Base = '/',
  Login = '/login',
  ForgotPassword = '/forgot-password',
  Home = '/home',
  PointOfSale = '/pos',
  Customers = '/customers',
  Orders = '/orders',
  CreateOrder = '/orders/create',
  Settings = '/settings',
  SettingsKey = '/settings/:item',
  RegisterDevice = '/device/register',
}
