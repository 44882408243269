import { useState, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { alpha, AppBar, Divider, Toolbar, Box, Button } from '@mui/material'
import { Cached, MenuRounded } from '@mui/icons-material'
import {
  FastokoLogo,
  SearchInput,
} from '@tokoku-universe/react-core/components'
import { useBreakpoints } from '../../../utils/device/hooks'
import { useIsOrderMode } from '../../../utils/routing/hooks'
import { ORDER_SIDEBAR_WIDTH } from '../OrderSidebar/config'
import { useStoreList } from '../../../services/stores/query'
import {
  useItemSearchState,
  useSelectedStoreState,
} from '../../../services/stores/store'
import { RoutePath } from '../../../routes/types'
import SelectStoreDialog from '../../../components/SelectStoreDialog'
import { HeaderProps } from './types'

function Header(props: HeaderProps) {
  const { toggleDrawerOpen } = props
  const mobileBreakpoint = useBreakpoints('mobile')
  const { stores } = useStoreList()
  const isOrderMode = useIsOrderMode()
  const selectedStore = useSelectedStoreState((state) => state.selectedStore)
  const changeSearchText = useItemSearchState((state) => state.changeSearchText)
  const [isSelectStoreOpen, setIsSelectStoreOpen] = useState(false)

  const currentStore = useMemo(() => {
    return stores?.find(({ id }) => id === selectedStore)
  }, [selectedStore, stores])

  const toggleSelectStore = useCallback(() => {
    setIsSelectStoreOpen((open) => !open)
  }, [])

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer - 1,
        boxShadow: 0,
        backdropFilter: 'blur(20px)',
        backgroundImage: 'none',
        backgroundColor: ({ palette }) =>
          alpha(palette.background.default, 0.6),
        ...(isOrderMode && {
          [mobileBreakpoint]: {
            width: { md: `calc(100% - ${ORDER_SIDEBAR_WIDTH}px)` },
            mr: { md: `${ORDER_SIDEBAR_WIDTH}px` },
            borderRightColor: ({ palette }) => palette.divider,
            borderRightWidth: 1,
            borderRightStyle: 'solid',
          },
        }),
      }}
    >
      <Toolbar sx={{ backgroundColor: 'transparent' }}>
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <Button
              onClick={toggleDrawerOpen}
              variant="outlined"
              sx={{ padding: '5px', minWidth: 0, borderRadius: 1.5, mr: 1.5 }}
            >
              <MenuRounded fontSize="small" />
            </Button>
            {currentStore && (
              <Button
                variant="text"
                onClick={toggleSelectStore}
                sx={{
                  fontSize: ({ typography }) => typography.body1.fontSize,
                  color: ({ palette }) => palette.text.primary,
                }}
                endIcon={<Cached />}
              >
                {currentStore.name}
              </Button>
            )}
            <SelectStoreDialog
              open={isSelectStoreOpen}
              onClose={toggleSelectStore}
            />
          </Box>
          <Box display="flex" alignItems="center" ml={1.5}>
            {isOrderMode ? (
              <SearchInput
                onChangeValue={changeSearchText}
                InputProps={{
                  sx: {
                    pr: 1,
                    pl: 1.5,
                    height: 32,
                    maxWidth: 240,
                  },
                }}
              />
            ) : (
              <Box
                component={Link}
                to={RoutePath.Home}
                display="flex"
                alignItems="center"
              >
                <FastokoLogo width={26} height={26} />
              </Box>
            )}
          </Box>
        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  )
}

export default Header
