import { ListAlt } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import { useLocalization } from '@tokoku-universe/react-core/localization'

function NoOrderOverlay() {
  const { t } = useLocalization()

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <ListAlt sx={{ fontSize: 60 }} color="disabled" />
      <Box mb={1}>
        <Typography variant="body1">
          {t('views.orders.data_grid.no_orders_found')}
        </Typography>
      </Box>
    </Box>
  )
}

export default NoOrderOverlay
