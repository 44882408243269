import {
  format,
  startOfDay,
  endOfDay,
  subDays,
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfYear,
  endOfYear,
  subYears,
  isAfter,
  isBefore,
} from 'date-fns'

export const defaultDayFormat = 'dd/MM/y'
export const readableDayFormat = 'd MMM yyyy'

export function formatReadableDateTime(date: string) {
  const dateObj = new Date(date)
  return format(dateObj, 'd MMM y, h:mm aa')
}

export function formatReadableDateRange(start: Date, end: Date) {
  const startDate = format(start, readableDayFormat)
  const endDate = format(end, readableDayFormat)
  return `${startDate} - ${endDate}`
}

export function findStartOfDay(date: Date) {
  return startOfDay(date)
}

export function findEndOfDay(date: Date) {
  return endOfDay(date)
}

export function findTodayDateRange() {
  const now = new Date()
  return {
    start: startOfDay(now),
    end: endOfDay(now),
  }
}

export function findYesterdayDateRange() {
  const now = new Date()
  const yesterday = subDays(now, 1)

  return {
    start: startOfDay(yesterday),
    end: endOfDay(yesterday),
  }
}

export function findThisMonthDateRange() {
  const now = new Date()
  return {
    start: startOfMonth(now),
    end: endOfMonth(now),
  }
}

export function findLastMonthDateRange() {
  const now = new Date()
  const lastMonth = subMonths(now, 1)
  return {
    start: startOfMonth(lastMonth),
    end: endOfMonth(lastMonth),
  }
}

export function findThisYearDateRange() {
  const now = new Date()
  return {
    start: startOfYear(now),
    end: endOfYear(now),
  }
}

export function findLastYearDateRange() {
  const now = new Date()
  const lastYear = subYears(now, 1)
  return {
    start: startOfYear(lastYear),
    end: endOfYear(lastYear),
  }
}

export function isTodayInRange(start: Date, end: Date) {
  const now = new Date()
  return isAfter(now, start) && isBefore(now, end)
}

export function isRangeInThePast(start: Date, end: Date) {
  const now = new Date()
  return isBefore(start, now) && isBefore(end, now)
}

export function isRangeInTheFuture(start: Date, end: Date) {
  const now = new Date()
  return isAfter(start, now) && isAfter(end, now)
}
