import React, { useMemo, useState, useEffect, useCallback } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  TextField,
  Box,
  MenuItem,
  Skeleton,
  Card,
} from '@mui/material'
import { PhoneInput } from '@tokoku-universe/react-core/components'
import { useDebounce } from 'use-debounce'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { StoreChannel, StoreCountry } from '../../config/types'
import { isValidPhoneNumber } from '../../utils/string'
import {
  createCustomer,
  findCustomerByPhone,
  updateCustomer,
} from '../../services/customers'
import { useAppSnackbar } from '../../utils/snackbar/hooks'
import { useCurrentCustomerState } from '../../services/customers/store'
import { usePageLoadingState } from '../../services/ui/store'
import {
  useSelectedStore,
  useStoreActiveLoyalty,
} from '../../services/stores/query'
import { AddCustomerDialogProps } from './types'
import { ApiCustomer } from '../../services/customers/types'

const countryList = Object.values(StoreCountry)

const initialCustomer = {
  phoneNumber: '',
  name: '',
  address: '',
  city: '',
  countryCode: StoreCountry.Indonesia,
  loyalties: [],
}

function CustomerLoader() {
  return (
    <>
      {[1, 2, 3, 4].map((key) => (
        <React.Fragment key={key}>
          <Skeleton sx={{ mt: 2, mb: 1 }} variant="rectangular" width="30%" />
          <Skeleton variant="rectangular" width="100%" height={40} />
        </React.Fragment>
      ))}
    </>
  )
}

function AddCustomerDialog(props: AddCustomerDialogProps) {
  const { channel, open, onClose } = props
  const { t } = useLocalization()
  const [isNewCustomer, setIsNewCustomer] = useState(false)
  const [customerLoading, setCustomerLoading] = useState(false)
  const store = useSelectedStore()
  const storeChannel = store?.channels?.find(({ type }) => type === channel)
  const isPointOfSale = channel === StoreChannel.PointOfSale
  const { enqueueSnackbar } = useAppSnackbar()
  const currentCustomer = useCurrentCustomerState(
    (state) => state.currentCustomer
  )
  const changeCurrentCustomer = useCurrentCustomerState(
    (state) => state.changeCurrentCustomer
  )
  const setPageLoading = usePageLoadingState((state) => state.setPageLoading)
  const { loyalty: activePosLoyalty } = useStoreActiveLoyalty(channel)

  const formik = useFormik<Partial<ApiCustomer>>({
    initialValues: initialCustomer,
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .test({
          test: (phone) => (phone ? isValidPhoneNumber(phone) : false),
        })
        .required(),
      name: Yup.string().min(2).max(100).required(),
      address: Yup.string().min(0).max(100).optional(),
      city: Yup.string().min(0).max(100).optional(),
      countryCode: Yup.string().oneOf(Object.values(StoreCountry)).required(),
    }),
    onSubmit: async (values) => {
      if (!values.phoneNumber) {
        changeCurrentCustomer(null)
        onClose()
        return
      }

      setPageLoading(true)
      try {
        const currentAddress = values.address || undefined
        const currentCity = values.city || undefined

        const customer = isNewCustomer
          ? await createCustomer({
              phoneNumber: values.phoneNumber,
              name: values.name || '',
              address: currentAddress,
              city: currentCity,
              countryCode: values.countryCode,
            })
          : await updateCustomer(values.phoneNumber, {
              name: values.name,
              address: currentAddress,
              city: currentCity,
              countryCode: values.countryCode,
            })

        changeCurrentCustomer(customer)
        enqueueSnackbar({
          event: isNewCustomer ? 'create_customer' : 'update_customer',
          variant: 'success',
        })
        onClose()
      } catch (e) {
        enqueueSnackbar({
          event: isNewCustomer ? 'create_customer' : 'update_customer',
          variant: 'error',
        })
      } finally {
        setPageLoading(false)
      }
    },
    validateOnMount: true,
  })
  const phoneNumber = formik.values.phoneNumber
  const setFormValues = formik.setValues
  const setFormField = formik.setFieldValue
  const currentCustomerLoyalty = formik.values.loyalties?.find(
    ({ program }) => program?.id === activePosLoyalty?.id
  )
  const [debPhoneNumber] = useDebounce(phoneNumber || '', 500)

  const isValidCustomerPhone = useMemo(() => {
    return isValidPhoneNumber(debPhoneNumber)
  }, [debPhoneNumber])

  useEffect(() => {
    if (!open) {
      return
    }

    if (currentCustomer) {
      setFormValues({
        phoneNumber: currentCustomer.phoneNumber || '',
        name: currentCustomer.name || '',
        address: currentCustomer.address || '',
        city: currentCustomer.city || '',
        countryCode: currentCustomer.countryCode || StoreCountry.Indonesia,
        loyalties: currentCustomer.loyalties || [],
      })
    } else {
      setFormValues(initialCustomer)
    }
  }, [open, currentCustomer, setFormValues])

  useEffect(() => {
    if (!open || !isValidCustomerPhone || !storeChannel) {
      return
    }

    setCustomerLoading(true)
    findCustomerByPhone(debPhoneNumber, storeChannel.id)
      .then((customer) => {
        setIsNewCustomer(false)
        setFormValues({
          phoneNumber: customer.phoneNumber,
          name: customer.name || '',
          address: customer.address || '',
          city: customer.city || '',
          countryCode: customer.countryCode || StoreCountry.Indonesia,
          loyalties: customer.loyalties || [],
        })
      })
      .catch((err) => {
        const status = err?.response?.status
        if (status === 404) {
          // customer not found
          setIsNewCustomer(true)
          setFormValues({
            ...initialCustomer,
            phoneNumber: debPhoneNumber,
          })
        }
      })
      .finally(() => setCustomerLoading(false))
  }, [open, isValidCustomerPhone, storeChannel, debPhoneNumber, setFormValues])

  const onButtonClick = () => {
    if (!formik.isValid && currentCustomer) {
      changeCurrentCustomer(null)
      onClose()
    }
  }

  const onChangePhoneNumber = useCallback(
    (value: string) => {
      setFormField('phoneNumber', value)
    },
    [setFormField]
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          {t(
            isNewCustomer
              ? 'views.pos.dialog.add_customer.title.new'
              : 'views.pos.dialog.add_customer.title'
          )}
        </DialogTitle>
        <DialogContent>
          <Typography mb={0.5} variant="subtitle1">
            {t('views.pos.dialog.add_customer.fields.phone_number.label')}
          </Typography>
          <PhoneInput
            fullWidth
            id="phoneNumber"
            name="phoneNumber"
            size="small"
            value={phoneNumber}
            onChangeValue={onChangePhoneNumber}
            onClear={() => onChangePhoneNumber('')}
            error={phoneNumber ? !isValidCustomerPhone : false}
            placeholder={t(
              'views.pos.dialog.add_customer.fields.phone_number.placeholder'
            )}
            helperText={
              phoneNumber &&
              !isValidCustomerPhone &&
              t('views.pos.dialog.add_customer.fields.phone_number.helper')
            }
          />
          {customerLoading ? (
            <Box mt={1.5}>
              <CustomerLoader />
            </Box>
          ) : (
            <>
              {isValidCustomerPhone && (
                <Box mt={2}>
                  <Typography variant="subtitle1" mb={0.5}>
                    {t('views.pos.dialog.add_customer.fields.name.label')}
                  </Typography>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    value={formik.values.name}
                    size="small"
                    type="text"
                    onChange={formik.handleChange}
                    placeholder={t(
                      'views.pos.dialog.add_customer.fields.name.placeholder'
                    )}
                    error={Boolean(formik.values.name && formik.errors.name)}
                    helperText={
                      formik.values.name &&
                      formik.errors.name &&
                      t('views.pos.dialog.add_customer.fields.name.helper')
                    }
                  />
                  <Typography variant="subtitle1" mb={0.5} mt={2}>
                    {t('views.pos.dialog.add_customer.fields.address.label')}
                  </Typography>
                  <TextField
                    fullWidth
                    id="address"
                    name="address"
                    value={formik.values.address}
                    size="small"
                    type="text"
                    onChange={formik.handleChange}
                    placeholder={t(
                      'views.pos.dialog.add_customer.fields.address.placeholder'
                    )}
                  />
                  <Typography variant="subtitle1" mb={0.5} mt={2}>
                    {t('views.pos.dialog.add_customer.fields.city.label')}
                  </Typography>
                  <TextField
                    fullWidth
                    id="city"
                    name="city"
                    value={formik.values.city}
                    size="small"
                    type="text"
                    onChange={formik.handleChange}
                    placeholder={t(
                      'views.pos.dialog.add_customer.fields.city.placeholder'
                    )}
                  />
                  <Typography variant="subtitle1" mb={0.5} mt={2}>
                    {t('views.pos.dialog.add_customer.fields.country.label')}
                  </Typography>
                  <TextField
                    select
                    fullWidth
                    id="countryCode"
                    name="countryCode"
                    value={formik.values.countryCode}
                    onChange={formik.handleChange}
                    size="small"
                  >
                    {countryList.map((key) => (
                      <MenuItem key={key} value={key}>
                        {t(`country.label.${key}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                  {currentCustomerLoyalty && isPointOfSale && (
                    <Card sx={{ mt: 4, p: 2 }}>
                      <Typography fontWeight={500}>
                        {t(
                          'views.pos.dialog.add_customer.loyalty.points.header'
                        )}
                      </Typography>
                      <Typography
                        fontSize={20}
                        color="error.light"
                        fontWeight={600}
                      >
                        {currentCustomerLoyalty.data.current}
                      </Typography>
                    </Card>
                  )}
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('button.label.cancel')}</Button>
          <Button
            type="submit"
            variant="contained"
            disabled={
              phoneNumber
                ? !(formik.isValid && formik.dirty) || formik.isSubmitting
                : false
            }
            onClick={onButtonClick}
            disableElevation
          >
            {t(phoneNumber ? 'button.label.select' : 'button.label.remove')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddCustomerDialog
