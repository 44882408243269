import { useCallback, useState } from 'react'
import { Card, Skeleton, Stack, Theme, Typography } from '@mui/material'
import { AssignmentIndOutlined } from '@mui/icons-material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import CardHeader from './DashCardHeader'
import { useUserTodayAttendance } from '../../../services/attendance/query'
import { useAuthUser } from '../../../services/auth/query'
import { useSelectedStoreState } from '../../../services/stores/store'
import { AttendanceType } from '../../../services/attendance/types'
import RecordAttendanceDialog from '../RecordAttendanceDialog'

function AttendanceCard() {
  const { t } = useLocalization()
  const { user } = useAuthUser()
  const storeId = useSelectedStoreState((state) => state.selectedStore)
  const { isLoading, action } = useUserTodayAttendance(storeId || '', user?.id)
  const [isAttendanceDialogOpen, setIsAttendanceDialogOpen] = useState(false)

  const toggleAttendanceDialog = () =>
    setIsAttendanceDialogOpen((open) => !open)
  const onCardClick = () => {
    if (isLoading || action === 'none') {
      return
    }

    toggleAttendanceDialog()
  }
  const labelColor = useCallback(
    ({ palette }: Theme) => {
      if (action === 'in') {
        return palette.error.light
      }

      if (action === 'out') {
        return palette.success.light
      }

      return palette.warning.light
    },
    [action]
  )

  return (
    <>
      <Card
        onClick={onCardClick}
        role="button"
        aria-disabled={isLoading}
        sx={{
          p: 2,
          cursor: 'pointer',
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          textDecoration: 'none',
        }}
      >
        {isLoading ? (
          <Stack spacing={2}>
            <Skeleton width="60%" />
            <Skeleton width="100%" />
          </Stack>
        ) : (
          <>
            <CardHeader
              label={t('views.home.card.attendance.header')}
              IconComponent={AssignmentIndOutlined}
              color={action === 'in' ? 'warning' : 'success'}
            />
            <Typography sx={{ color: labelColor }} pt={2} pb={1} variant="h5">
              {t(`views.home.card.attendance.label.${action}`)}
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              {t(`views.home.card.attendance.description.${action}`)}
            </Typography>
          </>
        )}
      </Card>
      <RecordAttendanceDialog
        open={isAttendanceDialogOpen}
        onClose={toggleAttendanceDialog}
        type={action === 'in' ? AttendanceType.In : AttendanceType.Out}
        storeId={storeId || ''}
      />
    </>
  )
}

export default AttendanceCard
