import { useState } from 'react'
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormLabel,
  Typography,
} from '@mui/material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { PasswordInput } from '@tokoku-universe/react-core/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAppSnackbar } from '../../../utils/snackbar/hooks'
import { passwordValidationSchema } from '../../../utils/validation'
import { usePageLoadingState } from '../../../services/ui/store'
import { useUpdateProfileMutation } from '../../../services/auth/query'

const validationSchema = Yup.object({
  oldPassword: Yup.string().min(4).required(),
  newPassword: passwordValidationSchema.required(),
})

function SecuritySettings() {
  const { t } = useLocalization()
  const setPageLoading = usePageLoadingState((state) => state.setPageLoading)
  const updateProfileMutation = useUpdateProfileMutation()
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<string | boolean>(false)
  const { enqueueSnackbar } = useAppSnackbar()

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const { oldPassword, newPassword } = values
      try {
        setPageLoading(true)
        await updateProfileMutation({
          oldPassword,
          password: newPassword,
        })

        formik.setValues({
          oldPassword: '',
          newPassword: '',
        })
        setSuccess(true)
        enqueueSnackbar({
          event: 'change_password',
          variant: 'success',
        })
      } catch (e) {
        enqueueSnackbar({
          event: 'change_password',
          variant: 'error',
        })
        setError(t('snackbar.error.change_password'))
      } finally {
        setPageLoading(false)
      }
    },
  })

  const isPasswordSame = formik.values.oldPassword === formik.values.newPassword
  const isFormValid = formik.isValid && !isPasswordSame

  const getHelperText = () => {
    if (!formik.values.newPassword) {
      return undefined
    }

    if (!formik.values.oldPassword) {
      return t(
        'views.settings.security.change_password.old_password.helper.label'
      )
    }

    if (isPasswordSame) {
      return t(
        'views.settings.security.change_password.same_password.helper.label'
      )
    }

    return (
      !formik.isValid &&
      t('views.settings.security.change_password.new_password.helper.label')
    )
  }

  return (
    <Box maxWidth="sm" component="form" onSubmit={formik.handleSubmit}>
      <Card>
        <Typography variant="h6" px={2} py={1.5}>
          {t('views.settings.security.change_password.title')}
        </Typography>
        <Divider />
        <CardContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {t('views.settings.security.change_password.alert.error.label')}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mb: 2 }}>
              {t('views.settings.security.change_password.alert.success.label')}
            </Alert>
          )}
          <FormLabel>
            {t(
              'views.settings.security.change_password.old_password.header.label'
            )}
          </FormLabel>
          <PasswordInput
            id="oldPassword"
            name="oldPassword"
            onChange={formik.handleChange}
            value={formik.values.oldPassword}
            size="small"
            sx={{ mb: 2 }}
            fullWidth
          />
          <FormLabel>
            {t(
              'views.settings.security.change_password.new_password.header.label'
            )}
          </FormLabel>
          <PasswordInput
            id="newPassword"
            name="newPassword"
            error={formik.values.newPassword ? !isFormValid : false}
            helperText={getHelperText()}
            onChange={formik.handleChange}
            value={formik.values.newPassword}
            size="small"
            fullWidth
          />
        </CardContent>
        <Divider />
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            type="submit"
            disabled={!(isFormValid && formik.dirty)}
            variant="contained"
            disableElevation
          >
            {t('button.label.update')}
          </Button>
        </CardActions>
      </Card>
    </Box>
  )
}

export default SecuritySettings
