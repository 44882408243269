import { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import { ItemImageProps } from './types'

const Img = styled('img')({})
const DEFAULT_IMAGE_SRC =
  'https://tokoku-web-assets.pages.dev/images/placeholder.webp'

function ItemImage(props: ItemImageProps) {
  const { src, sx } = props
  const [displayedSrc, setDisplayedSrc] = useState(src)

  useEffect(() => {
    if (displayedSrc == null) {
      setDisplayedSrc(DEFAULT_IMAGE_SRC)
    }
  }, [displayedSrc])

  const onImgError = () => {
    setDisplayedSrc(DEFAULT_IMAGE_SRC)
  }

  return (
    <Img
      src={displayedSrc}
      sx={{ width: 40, height: 40, objectFit: 'cover', borderRadius: 1, ...sx }}
      onError={onImgError}
    />
  )
}

export default ItemImage
