import {
  ApiItem,
  ApiVariantValue,
  OrderItem,
} from '../../services/stores/types'
import { ApiOrder, ApiOrderItem } from '../../services/stores/types'
import { isSetEqual } from '../common'

export function calculateTotalDiscount(order?: ApiOrder | null) {
  if (!order) {
    return 0
  }

  return order.merchantDiscount + order.channelDiscount
}

export function calculateOrderItemTotal(item?: ApiOrderItem | null) {
  if (!item) {
    return 0
  }

  const { price, quantity, variants } = item
  const variantTotal =
    variants?.reduce((prev, curr) => {
      return curr.price + prev
    }, 0) || 0
  return (price + variantTotal) * quantity || 0
}

export function calculateCurrentOrderItemPrice(
  item: ApiItem,
  variants: ApiVariantValue[],
  quantity = 0
) {
  const basePrice = item ? item.price ?? item.base.price : 0
  const variantPrice = variants?.reduce((prev, curr) => prev + curr.price, 0)
  return (basePrice + variantPrice) * quantity
}

export function isOrderItemEqual(
  firstOrder: OrderItem,
  secondOrder: OrderItem
) {
  if (firstOrder.itemId !== secondOrder.itemId) {
    return false
  }

  if (firstOrder.notes !== secondOrder.notes) {
    return false
  }

  const firstOrderVariants = firstOrder.variants
  const secondOrderVariants = secondOrder.variants

  if (!firstOrderVariants && !secondOrderVariants) {
    return true
  }

  const firstOrderList = Object.values(firstOrderVariants || {}).flat()
  const secondOrderList = Object.values(secondOrderVariants || {}).flat()

  return isSetEqual(new Set(firstOrderList), new Set(secondOrderList))
}
