import { useCallback } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { PaymentMode } from '@tokoku-universe/react-core/common'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { PaymentModeSelect } from '@tokoku-universe/react-core/components'
import { PaymentSelectionDialogProps } from './types'

function PaymentSelectionDialog(props: PaymentSelectionDialogProps) {
  const {
    onConfirm,
    title,
    selectedPaymentMode,
    onChangePaymentMode,
    confirmButtonLabel,
    onClose,
    open,
  } = props
  const { t } = useLocalization()

  const onSelectPaymentMode = useCallback(
    (paymentMode: PaymentMode[]) => {
      onChangePaymentMode(paymentMode[0])
    },
    [onChangePaymentMode]
  )

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>
        {title || t('views.pos.dialog.payment_selection.title')}
      </DialogTitle>
      <DialogContent>
        <Box width="100%">
          <PaymentModeSelect
            value={[selectedPaymentMode]}
            onChange={onSelectPaymentMode}
            multiselect={false}
            singleColumn
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('button.label.cancel')}</Button>
        <Button onClick={onConfirm} variant="contained">
          {confirmButtonLabel || t('button.label.next')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PaymentSelectionDialog
