import { useCallback, useMemo } from 'react'
import { Drawer } from '@mui/material'
import { useMatch, useNavigate } from 'react-router-dom'
import OrderList from '../../../components/OrderList'
import { StoreChannel } from '../../../config/types'
import { RoutePath } from '../../../routes/types'
import { useQueryNavigation } from '../../../utils/routing/hooks'
import { ORDER_SIDEBAR_WIDTH } from './config'

const channelList = Object.values(StoreChannel)

function OrderSidebar() {
  const matchCreateOrder = useMatch(RoutePath.CreateOrder)
  const [query] = useQueryNavigation<{ channel: StoreChannel }>()
  const navigate = useNavigate()

  const currentChannel = useMemo(() => {
    const { channel } = query
    return channel && channelList.includes(channel)
      ? channel
      : StoreChannel.PointOfSale
  }, [query])

  const onOrderCompleted = useCallback(() => {
    if (!matchCreateOrder) {
      return
    }

    // navigate to order history when it's in create order page
    navigate(RoutePath.Orders)
  }, [matchCreateOrder, navigate])

  return (
    <Drawer
      sx={{
        width: ORDER_SIDEBAR_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: ORDER_SIDEBAR_WIDTH,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="right"
    >
      <OrderList onOrderComplete={onOrderCompleted} channel={currentChannel} />
    </Drawer>
  )
}

export default OrderSidebar
