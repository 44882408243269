import { create } from 'zustand'
import { ApiCustomer, CurrentCustomerState } from './types'

export const useCurrentCustomerState = create<CurrentCustomerState>()(
  (set) => ({
    currentCustomer: null,
    changeCurrentCustomer: (customer: ApiCustomer | null) =>
      set(() => ({ currentCustomer: customer })),
  })
)
