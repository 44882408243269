import { useCallback } from 'react'
import {
  Button,
  Card,
  Unstable_Grid2 as Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { AddOutlined } from '@mui/icons-material'
import {
  DateRangePicker,
  SearchInput,
} from '@tokoku-universe/react-core/components'
import { FilterHeaderLabelProps, OrderQuickFilterProps } from './types'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { StoreOrderStatus } from '../../../config/types'
import OrderStatus from '../OrderDataGrid/OrderStatus'

const orderStatusList = Object.values(StoreOrderStatus)

function FilterHeaderLabel(props: FilterHeaderLabelProps) {
  const { label } = props

  return (
    <Typography
      fontWeight={700}
      variant="body2"
      mb={0.5}
      sx={{
        display: {
          xs: 'none',
          sm: 'none',
          md: 'none',
          lg: 'flex',
        },
      }}
    >
      {label}
    </Typography>
  )
}

function OrderQuickFilter(props: OrderQuickFilterProps) {
  const {
    sx,
    onCreateClick,
    selectedDateRange,
    onChangeDateRange,
    onChangeSearchValue,
    selectedStatus,
    onChangeStatus,
  } = props
  const { t } = useLocalization()

  const onChangeStatusFilter = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeStatus(event.target.value as StoreOrderStatus)
    },
    [onChangeStatus]
  )

  return (
    <Card sx={{ px: 2, pb: 2, pt: 1.5, width: '100%', ...sx }}>
      <Grid container spacing={1}>
        <Grid xs={12} sm={8} md={8} lg={5}>
          <FilterHeaderLabel label={t('views.orders.filter.search.label')} />
          <SearchInput
            InputProps={{
              sx: {
                height: 36,
                borderRadius: 1.5,
              },
            }}
            fullWidth
            onChangeValue={onChangeSearchValue}
          />
        </Grid>
        <Grid xs={6} sm={4} md={4} lg={3}>
          <FilterHeaderLabel label={t('views.orders.filter.status.label')} />
          <TextField
            select
            value={selectedStatus}
            onChange={onChangeStatusFilter}
            fullWidth
            InputProps={{
              sx: {
                height: 36,
                borderRadius: 1.5,
              },
            }}
            size="small"
          >
            {orderStatusList.map((key) => (
              <MenuItem key={key} value={key}>
                <OrderStatus status={key} sx={{ py: 1 }} />
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid xs={6} sm={10} md={10.5} lg={3}>
          <FilterHeaderLabel label={t('views.orders.filter.date.label')} />
          <DateRangePicker
            fullWidth
            value={selectedDateRange}
            onChange={onChangeDateRange}
          />
        </Grid>
        <Grid xs={12} sm={2} md={1.5} lg={1}>
          <Button
            onClick={onCreateClick}
            sx={{
              height: 36,
              mt: {
                md: 0,
                lg: 3,
              },
            }}
            variant="contained"
            fullWidth
            disableElevation
            startIcon={<AddOutlined />}
          >
            {t('button.label.create')}
          </Button>
        </Grid>
      </Grid>
    </Card>
  )
}

export default OrderQuickFilter
