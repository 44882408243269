import { useCallback, useMemo } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Radio,
  Typography,
} from '@mui/material'
import { SelectCampaignDialogProps } from './types'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { CampaignOutlined, LocalOfferOutlined } from '@mui/icons-material'
import { CampaignType } from '../../services/stores/types'
import DiscountCampaignDescription from './DiscountCampaignDescription'
import { useStoreActiveCampaigns } from '../../services/stores/query'

function SelectCampaignDialog(props: SelectCampaignDialogProps) {
  const {
    open,
    channel,
    onClose,
    onConfirm,
    readOnly,
    selectedCampaignId,
    disabledCampaignIds,
    onChangeSelectedCampaign,
  } = props
  const { t } = useLocalization()
  const { campaigns: campaignList } = useStoreActiveCampaigns(channel)

  const disabledSet = useMemo(() => {
    return new Set(disabledCampaignIds || [])
  }, [disabledCampaignIds])

  const onCampaignClick = useCallback(
    (campaignId: string) => {
      return function onClick() {
        if (!onChangeSelectedCampaign) {
          return
        }

        onChangeSelectedCampaign(
          selectedCampaignId === campaignId ? null : campaignId
        )
      }
    },
    [onChangeSelectedCampaign, selectedCampaignId]
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        {t(
          readOnly
            ? 'views.pos.dialog.campaign_list.title'
            : 'views.pos.dialog.campaign_list.title.select'
        )}
      </DialogTitle>
      <DialogContent>
        {campaignList && campaignList.length > 0 ? (
          <List sx={{ width: '100%' }}>
            {campaignList.map(
              ({ id, type, name, config, startTime, endTime }) => (
                <ListItemButton
                  key={id}
                  disabled={disabledSet.has(id)}
                  onClick={onCampaignClick(id)}
                  selected={readOnly ? false : id === selectedCampaignId}
                  disableRipple={readOnly}
                  sx={{
                    cursor: readOnly ? 'auto' : 'pointer',
                  }}
                >
                  <ListItemAvatar sx={{ minWidth: 40 }}>
                    <LocalOfferOutlined
                      fontSize="small"
                      sx={{ color: ({ palette }) => palette.info.main }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={name}
                    secondary={
                      <>
                        {type === CampaignType.Discount && (
                          <DiscountCampaignDescription
                            config={config}
                            start={startTime}
                            end={endTime}
                          />
                        )}
                      </>
                    }
                  />
                  {!readOnly && (
                    <Radio
                      edge="end"
                      onChange={onCampaignClick(id)}
                      checked={id === selectedCampaignId}
                    />
                  )}
                </ListItemButton>
              )
            )}
          </List>
        ) : (
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <CampaignOutlined
              color="disabled"
              sx={{ width: 50, height: 50, mb: 1 }}
            />
            <Typography>
              {t('views.pos.dialog.campaign_list.no_active_campaigns')}
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant={readOnly ? 'contained' : 'text'}
          disableElevation
        >
          {t(readOnly ? 'button.label.confirm' : 'button.label.close')}
        </Button>
        {!readOnly && (
          <Button onClick={onConfirm} variant="contained" disableElevation>
            {t('button.label.next')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default SelectCampaignDialog
