import { useEffect, useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  LinearProgress,
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import MotionViewContainer from '../../components/MotionViewContainer'
import { useSearchParams } from '../../utils/routing/hooks'
import { isDeviceRegistered, registerStoreDevice } from '../../services/stores'
import { RegisterDeviceViewParams } from './types'

function RegisterDevice() {
  const { token, orgId, storeId } = useSearchParams<RegisterDeviceViewParams>()
  const [isOverwriteOpen, setIsOverwriteOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [cancelled, setCancelled] = useState(false)
  const navigate = useNavigate()
  const { t } = useLocalization()

  const onRegisterDevice = useCallback(async () => {
    setLoading(true)
    setSuccess(false)
    setError(false)
    setCancelled(false)
    if (!token || !orgId || !storeId) {
      return setError(true)
    }

    try {
      await registerStoreDevice(orgId, storeId, token)
      setSuccess(true)
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }, [orgId, storeId, token])

  useEffect(() => {
    // check if this device is already paired to a store
    if (!token || !orgId || !storeId) {
      return setError(true)
    }

    if (isDeviceRegistered()) {
      return setIsOverwriteOpen(true)
    }

    // if not immediately register the device
    onRegisterDevice()
  }, [orgId, storeId, token, onRegisterDevice])

  const renderBackToHomeButton = () => {
    return (
      <Button
        fullWidth
        sx={{ mt: 2 }}
        size="large"
        variant="outlined"
        onClick={() => navigate('/', { replace: true })}
      >
        {t('button.label.go_back')}
      </Button>
    )
  }

  const renderInvalidToken = () => {
    return (
      <>
        <Alert color="error">
          {t('views.register_device.alert.invalid_token')}
        </Alert>
        {renderBackToHomeButton()}
      </>
    )
  }

  const renderRegisterResult = () => {
    if (success) {
      return (
        <>
          <Alert color="success">
            {t('views.register_device.alert.register_success')}
          </Alert>
          {renderBackToHomeButton()}
        </>
      )
    }

    if (error) {
      return (
        <>
          <Alert color="error">
            {t('views.register_device.alert.register_error')}
          </Alert>
          {renderBackToHomeButton()}
        </>
      )
    }

    return renderInvalidToken()
  }

  const renderTokenExists = () => {
    if (cancelled) {
      return (
        <>
          <Alert color="warning" sx={{ mb: 2 }}>
            {t('views.register_device.alert.cancelled')}
          </Alert>
          <LinearProgress color="info" variant="indeterminate" />
        </>
      )
    }

    return loading ? (
      <>
        <Alert color="info" sx={{ mb: 2 }}>
          {t('views.register_device.alert.loading')}
        </Alert>
        <LinearProgress color="info" variant="indeterminate" />
      </>
    ) : (
      renderRegisterResult()
    )
  }

  return (
    <MotionViewContainer
      maxWidth="xs"
      sx={{ height: '100%', display: 'flex', alignItems: 'center' }}
    >
      <Card sx={{ width: '100%', p: 3 }}>
        {token && storeId && orgId ? renderTokenExists() : renderInvalidToken()}
      </Card>
      <Dialog open={isOverwriteOpen} maxWidth="xs">
        <DialogTitle>
          {t('views.register_device.overwrite_dialog.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('views.register_device.overwrite_dialog.description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsOverwriteOpen(false)
              setCancelled(true)
            }}
          >
            {t('button.label.cancel')}
          </Button>
          <Button
            color="error"
            onClick={() => {
              setIsOverwriteOpen(false)
              onRegisterDevice()
            }}
          >
            {t('button.label.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </MotionViewContainer>
  )
}

export default RegisterDevice
