import React from 'react'
import {
  Card,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { settingsNavigationItems } from './config'
import { SettingsNavigationProps } from './types'

function SettingsNavigation(props: SettingsNavigationProps) {
  const { selected, onChange } = props
  const { t } = useLocalization()

  return (
    <Card variant="outlined">
      <List disablePadding sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {settingsNavigationItems.map(({ key, IconComponent }, idx) => {
          const isSelected = selected === key

          return (
            <React.Fragment key={key}>
              {idx !== 0 && <Divider />}
              <ListItemButton
                disableRipple
                sx={{
                  pl: 1.8,
                  borderLeftWidth: '4px',
                  borderLeftStyle: 'solid',
                  borderLeftColor: 'transparent',
                  '&.Mui-selected': {
                    borderLeftColor: ({ palette }) => palette.primary.main,
                  },
                }}
                onClick={() => onChange(key)}
                selected={isSelected}
              >
                {IconComponent && (
                  <ListItemIcon sx={{ minWidth: 32 }}>
                    <IconComponent sx={{ fontSize: '1.1rem' }} />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={t(`views.settings.navigation.label.${key}`)}
                />
              </ListItemButton>
            </React.Fragment>
          )
        })}
      </List>
    </Card>
  )
}

export default SettingsNavigation
