import React from 'react'
import { Tab, Tabs, TabProps, styled } from '@mui/material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { useLatestStoreCatalog } from '../../../services/stores/query'
import { useSelectedCategoryState } from '../../../services/stores/store'
import { CategoryTabsProps } from './types'

const ALL_CATEGORY_KEY = ''

const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    '&.Mui-selected': {
      color: theme.palette.text.primary,
      fontWeight: 500,
    },
  })
)

function CategoryTabs(props: CategoryTabsProps) {
  const { scrollButtons = false, channel } = props
  const { catalog } = useLatestStoreCatalog(channel)
  const selectedCategory = useSelectedCategoryState(
    (state) => state.selectedCategory
  )
  const changeSelectedCategory = useSelectedCategoryState(
    (state) => state.changeSelectedCategory
  )
  const { t } = useLocalization()

  const onChangeCategory = (_: React.SyntheticEvent, categoryId: string) => {
    changeSelectedCategory(categoryId)
  }

  return (
    <Tabs
      value={selectedCategory || ALL_CATEGORY_KEY}
      onChange={onChangeCategory}
      variant="scrollable"
      scrollButtons={scrollButtons}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
      sx={{
        '& .MuiTabs-indicator': {
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'transparent',
        },
        '& .MuiTabs-indicatorSpan': {
          maxWidth: 40,
          width: '100%',
          backgroundColor: ({ palette }) => palette.primary.main,
        },
      }}
    >
      <StyledTab value={ALL_CATEGORY_KEY} label={t('tabs.label.all')} />
      {catalog &&
        catalog.categories.map(({ id, name }) => (
          <StyledTab key={id} value={id} label={name} />
        ))}
    </Tabs>
  )
}

export default CategoryTabs
