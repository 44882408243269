import { useMemo } from 'react'
import { Theme, useMediaQuery } from '@mui/material'
import useTheming from '../theme/hooks'
import { GenericBreakpoint } from './types'

export function useDeviceType(type: GenericBreakpoint) {
  const mdDown = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const isDeviceType = useMemo(() => {
    return type === 'mobile' ? mdDown : !mdDown
  }, [type, mdDown])
  return isDeviceType
}

export function useBreakpoints(type: GenericBreakpoint) {
  const { breakpoints } = useTheming()
  return type === 'mobile' ? breakpoints.up('md') : breakpoints.down('lg')
}
