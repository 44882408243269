import { useCallback, useMemo } from 'react'
import {
  Box,
  Unstable_Grid2 as Grid,
  Card,
  Typography,
  ButtonBase,
  styled,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import MotionViewContainer from '../../components/MotionViewContainer'
import PointOfSale from '../PointOfSale'
import NotFound from '../NotFound'
import { channelConfig } from '../../config/channel'
import { StoreChannel } from '../../config/types'
import ViewBackButton from '../../components/ViewBackButton'
import { useQueryNavigation } from '../../utils/routing/hooks'
import { RoutePath } from '../../routes/types'
import { useCurrentOrderState } from '../../services/stores/store'
import { CreateOrderQueryParams } from './types'

const Img = styled('img')({})

function CreateOrder() {
  const { t } = useLocalization()
  const navigate = useNavigate()
  const [query, modifyQuery] = useQueryNavigation<CreateOrderQueryParams>()
  const clearCurrentOrders = useCurrentOrderState(
    (state) => state.clearCurrentOrders
  )
  const { channel: selectedChannel } = query

  const navigateToChannelCatalog = useCallback(
    (channel: StoreChannel) => {
      return function navigateToCatalog() {
        modifyQuery({ channel })
        clearCurrentOrders()
      }
    },
    [modifyQuery, clearCurrentOrders]
  )

  const navigateToOrderHistory = useCallback(() => {
    navigate(RoutePath.Orders)
  }, [navigate])

  const navigateToChannelSelect = useCallback(() => {
    navigate(RoutePath.CreateOrder)
  }, [navigate])

  const isChannelValid = useMemo(() => {
    if (!selectedChannel) {
      return false
    }

    return channelConfig[selectedChannel]?.enabled
  }, [selectedChannel])

  if (!selectedChannel) {
    return (
      <MotionViewContainer maxWidth="md">
        <Box
          display="flex"
          alignItems="center"
          maxWidth="lg"
          width="100%"
          px={2}
          my={2}
        >
          <ViewBackButton />
          <Typography variant="h5">
            {t('views.channels.heading.title')}
          </Typography>
        </Box>
        <Box width="100%" height="100%" maxWidth="lg" mb={2} px={2}>
          <Grid container spacing={1.5}>
            {Object.values(channelConfig).map(
              ({ key, enabled, color, imageSrc }) =>
                enabled && (
                  <Grid key={key} xs={12} sm={2} md={4}>
                    <Card sx={{ borderRadius: 2 }}>
                      <ButtonBase
                        onClick={navigateToChannelCatalog(key)}
                        sx={{
                          backgroundColor: color,
                          width: '100%',
                          height: 80,
                          px: 2,
                          py: 3,
                        }}
                      >
                        <Img
                          src={imageSrc}
                          sx={{
                            objectFit: 'contain',
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      </ButtonBase>
                    </Card>
                  </Grid>
                )
            )}
          </Grid>
        </Box>
      </MotionViewContainer>
    )
  }

  return isChannelValid ? (
    <PointOfSale
      channel={selectedChannel}
      onOrderComplete={navigateToOrderHistory}
      onChannelClick={navigateToChannelSelect}
    />
  ) : (
    <NotFound />
  )
}

export default CreateOrder
