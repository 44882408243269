import { ComponentClass, FunctionComponent } from 'react'

export interface RouteConfig {
  key: string
  path?: string
  Component: FunctionComponent | ComponentClass
  exact?: boolean
}

export enum StorageKey {
  JwtAuthToken = 'JWT_AUTH_TOKEN',
  DeviceIdentifier = 'DEVICE_IDENTIFIER',
}

export enum Navigation {
  Default = '',
  Home = 'home',
  PointOfSale = 'point_of_sale',
  Customers = 'customers',
  Orders = 'orders',
  Settings = 'settings',
}

export enum StoreIndustry {
  Beauty = 'beauty',
  Clothing = 'clothing',
  Electronics = 'electronics',
  Furniture = 'furniture',
  Handcrafts = 'handcrafts',
  Jewelry = 'jewelry',
  Painting = 'painting',
  Photography = 'photography',
  Restaurants = 'restaurants',
  Groceries = 'groceries',
  FoodAndBeverages = 'food_and_beverages',
  Sports = 'sports',
  Toys = 'toys',
  Services = 'services',
  VirtualServices = 'virtual_services',
  Others = 'others',
}

export enum StoreChannel {
  PointOfSale = 'POS',
  GoFood = 'GOFOOD',
  GrabFood = 'GRABFOOD',
}

export enum StoreCountry {
  Indonesia = 'ID',
  Singapore = 'SG',
}

export enum StoreCurrency {
  IndonesianRupiah = 'IDR',
}

export enum StoreOrderType {
  DineIn = 'DINE_IN',
  Takeaway = 'TAKEAWAY',
  Delivery = 'DELIVERY',
}

export enum StoreOrderStatus {
  All = 'ALL',
  Paid = 'PAID',
  Pending = 'PENDING',
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  Refunded = 'REFUNDED',
  Accepted = 'ACCEPTED',
  Ready = 'READY',
  Delivering = 'DELIVERING',
}

export enum StoreTimezone {
  PlusSeven = '+07:00',
  PlusEight = '+08:00',
  PlusNine = '+09:00',
}

export enum TransactionType {
  Revenue = 'REVENUE',
  Expense = 'EXPENSE',
}

export enum TransactionCategory {
  Order = 'ORDER',
  Rent = 'RENT',
  Utilities = 'UTILITIES',
  Salary = 'SALARY',
  Inventory = 'INVENTORY',
  Assets = 'ASSETS',
  Research = 'RESEARCH',
  Marketing = 'MARKETING',
  Others = 'OTHERS',
}
