import { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
} from '@mui/material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { useStoreList } from '../../services/stores/query'
import { useSelectedStoreState } from '../../services/stores/store'
import { SelectStoreDialogProps } from './types'

function SelectStoreDialog(props: SelectStoreDialogProps) {
  const { onClose, open } = props
  const { t } = useLocalization()
  const { stores } = useStoreList()
  const selectedStore = useSelectedStoreState((state) => state.selectedStore)
  const changeSelectedStore = useSelectedStoreState(
    (state) => state.changeSelectedStore
  )
  const [currSelectedStore, setCurrSelectedStore] = useState<string | null>(
    selectedStore
  )

  useEffect(() => {
    if (!open) {
      return
    }

    setCurrSelectedStore(selectedStore)
  }, [open, selectedStore])

  const onChangeClick = useCallback(() => {
    if (!currSelectedStore) {
      return
    }

    changeSelectedStore(currSelectedStore)
    onClose()
  }, [changeSelectedStore, currSelectedStore, onClose])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{t('store_picker.title')}</DialogTitle>
      <DialogContent>
        <List disablePadding>
          {stores &&
            stores.map(({ id, name }) => (
              <ListItem key={id} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={() => setCurrSelectedStore(id)}
                  dense
                >
                  <ListItemIcon>
                    <Radio
                      edge="start"
                      checked={currSelectedStore === id}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': name }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('button.label.cancel')}</Button>
        <Button onClick={onChangeClick} variant="contained">
          {t('button.label.change')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectStoreDialog
