import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material'
import { Close, Person, PersonAddAlt1 } from '@mui/icons-material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { PaymentMode } from '@tokoku-universe/react-core/common'
import { formatPrice } from '../../utils/string'
import OrderItemActions from './OrderItemActions'
import DeleteOrderItemDialog from './DeleteOrderItemDialog'
import AddCustomerDialog from './AddCustomerDialog'
import ConfirmDialog from '../ConfirmDialog'
import {
  StoreChannel,
  StoreOrderType,
  TransactionCategory,
  TransactionType,
} from '../../config/types'
import CreateOrderDialog from './CreateOrderDialog'
import PaymentSelectionDialog from '../PaymentSelectionDialog'
import PaymentConfirmDialog from '../PaymentConfirmDialog'
import RedeemPointsDialog from './RedeemPointsDialog'
import { printOrder } from '../../services/printer'
import ActiveCampaignButton from '../ActiveCampaignButton'
import SelectCampaignDialog from '../SelectCampaignDialog'
import ActiveLoyaltyButton from '../ActiveLoyaltyButton'
import { useAppSnackbar } from '../../utils/snackbar/hooks'
import {
  useCreateOrderMutation,
  useCreateTransactionMutation,
  useCurrentOrders,
  useSelectedStore,
  useStoreActiveCampaigns,
  useStoreActiveLoyalty,
} from '../../services/stores/query'
import { useCurrentCustomerState } from '../../services/customers/store'
import { ApiOrder, DiscountCampaignConfig } from '../../services/stores/types'
import { useCurrentOrderState } from '../../services/stores/store'
import { usePageLoadingState } from '../../services/ui/store'
import { LoyaltyProgramType } from '../../services/customers/types'
import {
  CreateOrderConfirmParams,
  CreateOrderNextStep,
  OrderListProps,
} from './types'

const baseOrderConfig: CreateOrderConfirmParams = {
  nextStep: CreateOrderNextStep.PayNow,
  orderType: StoreOrderType.DineIn,
}

const defaultPaymentMode = PaymentMode.Qris

const initialDialogOpen = {
  paymentSelection: false,
  paymentConfirm: false,
  clearConfirm: false,
  deleteConfirm: false,
  createConfirm: false,
  addCustomer: false,
  selectCampaign: false,
  redeemPoints: false,
}

function OrderList(props: OrderListProps) {
  const { closeVisible, onCloseClick, onOrderComplete, channel } = props
  const { t } = useLocalization()
  const currentStore = useSelectedStore()
  const currentCustomer = useCurrentCustomerState(
    (state) => state.currentCustomer
  )

  const setPageLoading = usePageLoadingState((state) => state.setPageLoading)

  const currentOrders = useCurrentOrders(channel)
  const incrementOrderQuantity = useCurrentOrderState(
    (state) => state.incrementOrderQuantity
  )
  const decrementOrderQuantity = useCurrentOrderState(
    (state) => state.decrementOrderQuantity
  )
  const clearCurrentOrders = useCurrentOrderState(
    (state) => state.clearCurrentOrders
  )
  const removeOrder = useCurrentOrderState((state) => state.removeOrder)

  const changeCurrentCustomer = useCurrentCustomerState(
    (state) => state.changeCurrentCustomer
  )

  const [dialogOpen, setDialogOpen] = useState(initialDialogOpen)
  const [selectedOrderIndex, setSelectedOrderIndex] = useState(0)
  const [redemptionAmount, setRedemptionAmount] = useState(0)
  const [createdOrder, setCreatedOrder] = useState<ApiOrder | null>(null)
  const [selectedPaymentMode, setSelectedPaymentMode] =
    useState<PaymentMode>(defaultPaymentMode)
  const [createOrderConfig, setCreateOrderConfig] =
    useState<CreateOrderConfirmParams>(baseOrderConfig)
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(
    null
  )
  const [disabledCampaignIds, setDisabledCampaignIds] = useState<string[]>([])
  const { enqueueSnackbar } = useAppSnackbar()
  const { campaigns: campaignList } = useStoreActiveCampaigns(channel)
  const { loyalty } = useStoreActiveLoyalty(channel)
  const createTransactionMutation = useCreateTransactionMutation()
  const createOrderMutation = useCreateOrderMutation()
  const isOrderEmpty = currentOrders.length <= 0
  const isPointOfSale = channel === StoreChannel.PointOfSale
  const isCampaignAvailable = Boolean(campaignList && campaignList.length > 0)
  const isRedeemPointsAvailable = Boolean(
    loyalty && currentCustomer && loyalty.type === LoyaltyProgramType.Points
  )

  const totalPrice = useMemo(() => {
    return currentOrders.reduce((prev, curr) => {
      return prev + curr.totalPrice
    }, 0)
  }, [currentOrders])

  const openDialog = useCallback((dialog: keyof typeof dialogOpen | null) => {
    setDialogOpen((dialogOpenState) => {
      const keyList = Object.keys(
        dialogOpenState
      ) as (keyof typeof dialogOpenState)[]
      const dialogState = keyList.reduce(
        (prev, key) => {
          prev[key] = false
          return prev
        },
        { ...dialogOpenState }
      )

      return dialog
        ? {
            ...dialogState,
            [dialog]: true,
          }
        : dialogState
    })
  }, [])

  const refreshOrderConfig = useCallback(() => {
    setCreateOrderConfig({
      ...baseOrderConfig,
      orderType: isPointOfSale
        ? StoreOrderType.DineIn
        : StoreOrderType.Delivery,
    })
    setSelectedPaymentMode(defaultPaymentMode)
    setRedemptionAmount(0)
  }, [isPointOfSale])

  useEffect(() => {
    refreshOrderConfig()
  }, [channel, refreshOrderConfig])

  useEffect(() => {
    if (!campaignList || campaignList.length <= 0) {
      return
    }

    const disabledList: string[] = []
    const eligibleCampaigns = campaignList.filter(({ id, config }) => {
      const minOrderAmount = (config as DiscountCampaignConfig).minOrderAmount
      const { paymentModes: supportedPaymentModes } = config

      if (minOrderAmount && minOrderAmount > 0 && totalPrice < minOrderAmount) {
        // when the current price doesn't meet
        // the minimum order amount disable it
        disabledList.push(id)
        return false
      }

      if (Array.isArray(supportedPaymentModes)) {
        const isSelectedPaymentModeSupported =
          supportedPaymentModes.includes(selectedPaymentMode)
        if (!isSelectedPaymentModeSupported) {
          disabledList.push(id)
        }
        return isSelectedPaymentModeSupported
      }

      // campaign is eligible for all payments
      return true
    })

    setDisabledCampaignIds(disabledList)
    setSelectedCampaignId(eligibleCampaigns[0].id || null)
  }, [campaignList, selectedPaymentMode, totalPrice])

  const onOrderFinished = useCallback(() => {
    refreshOrderConfig()
    onOrderComplete?.()
  }, [onOrderComplete, refreshOrderConfig])

  const clearOrder = useCallback(() => {
    clearCurrentOrders()
    changeCurrentCustomer(null)
  }, [clearCurrentOrders, changeCurrentCustomer])

  const onClosePaymentConfirm = useCallback(() => {
    openDialog(null)
    clearOrder()
    onOrderFinished()
  }, [clearOrder, onOrderFinished, openDialog])

  const onChangeOrderConfig = useCallback(
    (config: CreateOrderConfirmParams) => {
      setCreateOrderConfig(config)
    },
    []
  )

  const onIncrementQuantity = useCallback(
    (index: number) => {
      return function onIncrement() {
        incrementOrderQuantity(index)
      }
    },
    [incrementOrderQuantity]
  )

  const onDecrementQuantity = useCallback(
    (index: number) => {
      return function onDecrement() {
        const order = currentOrders[index]
        if (!order) {
          return
        }

        if (order.quantity <= 1) {
          setSelectedOrderIndex(index)
          openDialog('deleteConfirm')
          return
        }

        decrementOrderQuantity(index)
      }
    },
    [currentOrders, decrementOrderQuantity, openDialog]
  )

  const onRemoveOrderItem = useCallback(
    (index: number) => {
      return function onRemove() {
        setSelectedOrderIndex(index)
        openDialog('deleteConfirm')
      }
    },
    [openDialog]
  )

  const onDeleteOrder = useCallback(
    (index: number) => removeOrder(index),
    [removeOrder]
  )

  const onCreateStoreOrder = useCallback(async () => {
    if (!currentStore || !createOrderConfig) {
      return
    }

    const { orderType, notes, nextStep, referenceId } = createOrderConfig

    try {
      setPageLoading(true)
      const order = await createOrderMutation({
        notes,
        channel,
        shortId: referenceId,
        type: orderType,
        paymentMode: selectedPaymentMode,
        campaignId: selectedCampaignId || undefined,
        // only add customer id to point of sale as adding this
        // will cause the customer to directly get loyalty points
        ...(isPointOfSale && {
          customerId: currentCustomer ? currentCustomer.id : undefined,
        }),
        ...(isRedeemPointsAvailable &&
          redemptionAmount > 0 && {
            redeem: redemptionAmount,
          }),
        items: currentOrders.map(({ id, quantity, notes, variants }) => ({
          id,
          quantity,
          notes,
          variants: Array.isArray(variants)
            ? variants
                .map(({ values }) => values?.map(({ id }) => ({ id })) || [])
                .flat()
            : [],
        })),
      })

      if (order && order.total) {
        setCreatedOrder(order)

        if (nextStep === CreateOrderNextStep.PayNow) {
          // open payment dialog an close create dialog
          openDialog('paymentConfirm')
        } else {
          openDialog(null)
          clearCurrentOrders()
          onOrderFinished()
        }
      }

      enqueueSnackbar({
        event: 'create_order',
        variant: 'success',
      })
    } catch (e) {
      enqueueSnackbar({
        event: 'create_order',
        variant: 'error',
      })
    } finally {
      setPageLoading(false)
    }
  }, [
    channel,
    isPointOfSale,
    setPageLoading,
    openDialog,
    currentOrders,
    currentStore,
    createOrderConfig,
    currentCustomer,
    selectedPaymentMode,
    selectedCampaignId,
    isRedeemPointsAvailable,
    redemptionAmount,
    enqueueSnackbar,
    onOrderFinished,
    createOrderMutation,
    clearCurrentOrders,
  ])

  const onConfirmPayment = useCallback(
    async (paymentAmount: number) => {
      if (!createdOrder || !currentStore) {
        return
      }

      try {
        setPageLoading(true)
        const transaction = await createTransactionMutation({
          amount: createdOrder.total,
          amountReceived: paymentAmount,
          currencyCode: currentStore.currencyCode,
          paymentMode: selectedPaymentMode,
          type: TransactionType.Revenue,
          category: TransactionCategory.Order,
          orderId: createdOrder.id,
        })
        printOrder(currentStore, createdOrder, { ...transaction })
        setCreatedOrder(null)
        clearOrder()
        openDialog(null)
        onOrderFinished()
        enqueueSnackbar({
          event: 'complete_payment',
          variant: 'success',
        })
      } catch (e) {
        enqueueSnackbar({
          event: 'complete_payment',
          variant: 'error',
        })
      } finally {
        setPageLoading(false)
      }
    },
    [
      createdOrder,
      currentStore,
      selectedPaymentMode,
      openDialog,
      enqueueSnackbar,
      clearOrder,
      onOrderFinished,
      createTransactionMutation,
      setPageLoading,
    ]
  )

  const onPaymentSelectionChange = useCallback((paymentMode: PaymentMode) => {
    setSelectedPaymentMode(paymentMode)
  }, [])

  const onPaymentSelectionConfirm = useCallback(() => {
    if (isCampaignAvailable) {
      return openDialog('selectCampaign')
    }

    if (isRedeemPointsAvailable) {
      return openDialog('redeemPoints')
    }

    onCreateStoreOrder()
  }, [
    onCreateStoreOrder,
    isRedeemPointsAvailable,
    isCampaignAvailable,
    openDialog,
  ])

  const onCampaignSelectionConfirm = useCallback(() => {
    if (isRedeemPointsAvailable) {
      return openDialog('redeemPoints')
    }

    onCreateStoreOrder()
  }, [onCreateStoreOrder, openDialog, isRedeemPointsAvailable])

  const onCloseRedeemPoints = useCallback(() => {
    if (isCampaignAvailable) {
      return openDialog('selectCampaign')
    }

    openDialog('paymentSelection')
  }, [openDialog, isCampaignAvailable])

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Toolbar
        sx={{
          paddingLeft: { xs: 1.5 },
          paddingRight: { xs: 1.5 },
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" gap={1} alignItems="center">
          <ActiveLoyaltyButton channel={channel} variant="text" />
          <ActiveCampaignButton channel={channel} variant="text" />
          <IconButton
            onClick={() => openDialog('addCustomer')}
            size="small"
            color="info"
          >
            {currentCustomer ? (
              <Person fontSize="small" />
            ) : (
              <PersonAddAlt1 fontSize="small" />
            )}
          </IconButton>
          {currentCustomer && (
            <Box
              maxWidth={120}
              sx={{ cursor: 'pointer' }}
              onClick={() => openDialog('addCustomer')}
            >
              <Typography
                ml={0.25}
                noWrap
                letterSpacing={1}
                lineHeight={1}
                variant="body2"
                sx={{ color: ({ palette }) => palette.info.main }}
              >
                {currentCustomer.name?.toLocaleUpperCase()}
              </Typography>
            </Box>
          )}
        </Stack>
        <Box display="flex">
          <Button
            size="small"
            onClick={() => openDialog('clearConfirm')}
            disabled={isOrderEmpty}
          >
            {t('button.label.clear')}
          </Button>
          {closeVisible && (
            <IconButton sx={{ mb: 0.2 }} onClick={onCloseClick} size="small">
              <Close fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Toolbar>
      <Divider flexItem />
      <Box flex={1} sx={{ overflowY: 'auto' }}>
        <List sx={{ width: '100%', py: 0 }}>
          {currentOrders.map(
            (
              { name: orderName, quantity, notes, totalPrice, variants },
              idx
            ) => (
              <Box key={String(idx)}>
                <ListItem
                  sx={{ flexDirection: 'column', py: 1.5 }}
                  alignItems="flex-start"
                >
                  <OrderItemActions
                    onIncrement={onIncrementQuantity(idx)}
                    onDecrement={onDecrementQuantity(idx)}
                    onDelete={onRemoveOrderItem(idx)}
                  />
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    width="100%"
                    mt={1.5}
                  >
                    <Typography variant="body1" flex={0}>
                      {quantity}
                    </Typography>
                    <Box flex={1} width="100%" pl={2}>
                      <Typography variant="body1" flex={1}>
                        {orderName}
                      </Typography>
                      {variants.map((variant, idx) => (
                        <Box
                          key={variant.id}
                          display="flex"
                          flexDirection="column"
                        >
                          {idx === 0 && <Divider sx={{ my: 0.5 }} />}
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ mb: 0.25 }}
                          >
                            {variant.name + ':'}
                          </Typography>
                          {variant.values?.map(
                            ({ id, name: variantValueName, price }) => (
                              <Box
                                key={id}
                                display="flex"
                                width="100%"
                                justifyContent="space-between"
                              >
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                >
                                  {variantValueName}
                                </Typography>
                                {price ? (
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    {'+' + formatPrice(price)}
                                  </Typography>
                                ) : null}
                              </Box>
                            )
                          )}
                          <Divider sx={{ my: 0.5 }} />
                        </Box>
                      ))}
                      {notes && (
                        <Box display="flex" flexDirection="column">
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ mb: 0.25 }}
                          >
                            {t('views.pos.order_list.label.notes')}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {notes}
                          </Typography>
                          <Divider sx={{ my: 0.5 }} />
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    width="100%"
                    mt={0.5}
                  >
                    <Typography variant="body1">
                      {formatPrice(totalPrice)}
                    </Typography>
                  </Box>
                </ListItem>
                <Divider />
              </Box>
            )
          )}
        </List>
      </Box>
      <Divider flexItem />
      <Box flex={0} p={1.5} display="flex" justifyContent="space-between">
        <Typography variant="body1">
          {t('views.pos.order_list.label.total')}
        </Typography>
        <Typography variant="body1">{formatPrice(totalPrice)}</Typography>
      </Box>
      <Divider flexItem />
      <Box flex={0} p={1.5} sx={{ minHeight: 62 }}>
        <Button
          fullWidth
          onClick={() => openDialog('createConfirm')}
          variant="contained"
          disabled={isOrderEmpty}
          disableElevation
        >
          {t('button.label.create_order')}
        </Button>
      </Box>
      <DeleteOrderItemDialog
        open={dialogOpen.deleteConfirm}
        onClose={() => openDialog(null)}
        onConfirm={onDeleteOrder}
        orderIndex={selectedOrderIndex}
        channel={channel}
      />
      <AddCustomerDialog
        open={dialogOpen.addCustomer}
        channel={channel}
        onClose={() => openDialog(null)}
        onConfirm={() => openDialog(null)}
      />
      <ConfirmDialog
        open={dialogOpen.clearConfirm}
        title={t('views.pos.dialog.clear_order.title')}
        content={t('views.pos.dialog.clear_order.content')}
        onClose={() => openDialog(null)}
        onConfirm={() => {
          clearOrder()
          openDialog(null)
        }}
      />
      <PaymentSelectionDialog
        open={dialogOpen.paymentSelection}
        selectedPaymentMode={selectedPaymentMode}
        onChangePaymentMode={onPaymentSelectionChange}
        onClose={() => openDialog('createConfirm')}
        onConfirm={onPaymentSelectionConfirm}
      />
      <PaymentConfirmDialog
        open={dialogOpen.paymentConfirm}
        paymentMode={selectedPaymentMode}
        onClose={onClosePaymentConfirm}
        onConfirm={onConfirmPayment}
        order={createdOrder}
      />
      <CreateOrderDialog
        open={dialogOpen.createConfirm}
        onClose={() => openDialog(null)}
        onConfirm={() => openDialog('paymentSelection')}
        configValue={createOrderConfig}
        onChange={onChangeOrderConfig}
        channel={channel}
      />
      <SelectCampaignDialog
        open={dialogOpen.selectCampaign}
        channel={channel}
        selectedCampaignId={selectedCampaignId}
        onChangeSelectedCampaign={setSelectedCampaignId}
        disabledCampaignIds={disabledCampaignIds}
        onClose={() => openDialog('paymentSelection')}
        onConfirm={onCampaignSelectionConfirm}
        readOnly={false}
      />
      <RedeemPointsDialog
        open={dialogOpen.redeemPoints}
        channel={channel}
        redemptionAmount={redemptionAmount}
        onChangeRedemptionAmount={setRedemptionAmount}
        onClose={onCloseRedeemPoints}
        onConfirm={onCreateStoreOrder}
      />
    </Box>
  )
}

export default OrderList
