import apiClient from '../api'
import {
  ApiAttendance,
  ApiAttendanceReport,
  FindAttendanceQueryDto,
  FindAttendanceQueryOptions,
  RecordAttendanceDto,
} from './types'

export function recordAttendance(params: RecordAttendanceDto) {
  const path = `/attendances`
  return apiClient
    .post<ApiAttendance, RecordAttendanceDto>(path, params)
    .then((res) => res.data)
}

export function findAttendanceReport(options: FindAttendanceQueryOptions) {
  const path = '/attendances/report'
  const { employeeIds, storeIds, start, end } = options
  return apiClient
    .get<ApiAttendanceReport[], FindAttendanceQueryDto>(path, {
      employeeIds: employeeIds?.join(','),
      storeIds: storeIds?.join(','),
      start,
      end,
    })
    .then((res) => res.data)
}
