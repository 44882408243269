import qs from 'qs'
import urlJoin from 'url-join'

export function stringifyQuery(object: object) {
  return qs.stringify(object, {
    skipNulls: true,
    addQueryPrefix: true,
  })
}

export function parseQueryString(query: string) {
  return qs.parse(query, {
    ignoreQueryPrefix: true,
  })
}

export function joinUrlPath(baseUrl: string, ...args: string[]) {
  return urlJoin([baseUrl, ...args])
}
