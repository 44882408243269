import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { UseAppSnackbarOptions } from './types'

export function useAppSnackbar() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { t } = useLocalization()

  const enqueue = useCallback(
    ({
      event = 'default',
      variant = 'default',
    }: UseAppSnackbarOptions = {}) => {
      const translationKey = `snackbar.${variant}.${event}`
      return enqueueSnackbar(t(translationKey), {
        variant,
      })
    },
    [t, enqueueSnackbar]
  )

  return { enqueueSnackbar: enqueue, closeSnackbar }
}
