import { useCallback, useEffect, useState } from 'react'
import {
  connectBlePrinter,
  disconnectPrinter,
  findPrinterName,
  isConnectedToPrinter,
} from '.'

export function usePrinterConnection() {
  const [connected, setConnected] = useState(false)
  const [name, setName] = useState<string | undefined>(undefined)

  useEffect(() => {
    const isConnected = isConnectedToPrinter()
    setName(findPrinterName())
    setConnected(isConnected)
  }, [])

  const connect = useCallback(async () => {
    try {
      await connectBlePrinter()
      setConnected(true)
      setName(findPrinterName())
    } catch (e) {
      console.error(e)
      disconnectPrinter()
      setConnected(false)
      setName(undefined)
    }
  }, [])

  const disconnect = useCallback(() => {
    disconnectPrinter()
    setConnected(false)
    setName(undefined)
  }, [])

  return {
    connected,
    name,
    connect,
    disconnect,
  }
}
