import { useState } from 'react'
import { Button, IconButton } from '@mui/material'
import { LoyaltyOutlined } from '@mui/icons-material'
import { useStoreActiveLoyalty } from '../../services/stores/query'
import LoyaltyDetailDialog from '../LoyaltyDetailDialog'
import { ActiveLoyaltyButtonProps } from './types'

const buttonHeight = 28

function ActiveLoyaltyButton(props: ActiveLoyaltyButtonProps) {
  const { variant = 'contained', channel, sx } = props
  const [isLoyaltyDetailOpen, setIsLoyaltyDetailOpen] = useState(false)
  const { loyalty } = useStoreActiveLoyalty(channel)

  const toggleCampaignListDialog = () => {
    setIsLoyaltyDetailOpen((open) => !open)
  }

  if (!loyalty) {
    return null
  }

  return (
    <>
      {variant === 'contained' ? (
        <Button
          onClick={toggleCampaignListDialog}
          color="success"
          variant="contained"
          disableElevation
          sx={{
            height: buttonHeight,
            minWidth: buttonHeight,
            width: buttonHeight,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            ...sx,
          }}
        >
          <LoyaltyOutlined fontSize="small" />
        </Button>
      ) : (
        <IconButton sx={sx} onClick={toggleCampaignListDialog} size="small">
          <LoyaltyOutlined
            sx={{ color: ({ palette }) => palette.success.main }}
            fontSize="small"
          />
        </IconButton>
      )}
      <LoyaltyDetailDialog
        open={isLoyaltyDetailOpen}
        onClose={toggleCampaignListDialog}
        channel={channel}
      />
    </>
  )
}

export default ActiveLoyaltyButton
