import { useCallback } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { useCurrentOrderInIndex } from '../../services/stores/query'
import { DeleteOrderDialogProps } from './types'

function DeleteOrderItemDialog(props: DeleteOrderDialogProps) {
  const { open, onClose, onConfirm, orderIndex, channel } = props
  const order = useCurrentOrderInIndex(orderIndex, channel)
  const { t } = useLocalization()

  const onConfirmClicked = useCallback(() => {
    onConfirm(orderIndex)
    onClose()
  }, [orderIndex, onConfirm, onClose])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle>{t('views.pos.dialog.delete_order.title')}</DialogTitle>
      <DialogContent>
        {t('views.pos.dialog.delete_order.content')}
        {order && (
          <>
            <Typography mt={2}>{order.name}</Typography>
            {order.variants?.map(({ id, name, values }) => (
              <Box key={id} mb={1}>
                <Typography
                  key={id}
                  variant="subtitle1"
                  color="textSecondary"
                  sx={{ mt: 0.5 }}
                >
                  {name + ':'}
                </Typography>
                <Typography color="textSecondary" variant="subtitle2">
                  {values?.map(({ name }) => name).join(', ')}
                </Typography>
              </Box>
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('button.label.cancel')}</Button>
        <Button onClick={onConfirmClicked}>{t('button.label.confirm')}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteOrderItemDialog
