import { StoreChannel } from '../../../config/types'
import { ApiItem, ApiVariantValue } from '../../../services/stores/types'

export interface ItemListProps {
  channel: StoreChannel
}

export interface ItemGridListProps {
  categoryId: string
}

export interface VariantSelectDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: (
    item: ApiItem,
    variants: Record<string, string[]>,
    notes?: string
  ) => void
  item: ApiItem | null
}

export interface VariantSelectionProps {
  minSelection: number
  maxSelection: number
  variantGroupId: string
  variants: ApiVariantValue[]
  selected: string[]
  onSelect: (groupId: string, variantId: string) => void
}

export enum VariantSelectionType {
  Single = 'single_selection',
  Eqaul = 'equal_threshold',
  Unequal = 'unequal_threshold',
  UnequalMax = 'unequal_threshold_max_only',
  Disabled = 'disabled',
}
