import { Box, Typography, alpha } from '@mui/material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { Launch } from '@mui/icons-material'
import { NAV_ITEM_MIN_HEIGHT } from '../config'
import { NavItemProps } from './types'

function NavItem(props: NavItemProps) {
  const { titleKey, active, IconComponent, external = false } = props
  const { t } = useLocalization()
  return (
    <Box
      component="li"
      sx={{
        textTransform: 'none',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        pl: 2.5,
        pr: 1.5,
        borderLeftColor: 'transparent',
        borderLeftStyle: 'solid',
        borderLeftWidth: '5px',
        borderRadius: 0,
        minHeight: NAV_ITEM_MIN_HEIGHT,
        color: ({ palette }) => palette.text.secondary,
        ':hover': {
          color: ({ palette }) =>
            active ? palette.primary.main : palette.text.primary,
          backgroundColor: ({ palette }) =>
            alpha(palette.primary.main, active ? 0.08 : 0.05),
        },
        ...(active && {
          backgroundColor: ({ palette }) => alpha(palette.primary.main, 0.08),
          color: ({ palette }) => palette.primary.light,
          borderLeftColor: ({ palette }) => palette.primary.main,
        }),
      }}
    >
      <IconComponent
        sx={{
          color: active ? 'primary.light' : 'action',
          mr: 1.5,
          width: '0.75em',
          height: '0.75em',
        }}
      />
      <Typography
        component="span"
        flex={1}
        variant="body1"
        justifyContent="flex-start"
        display="flex"
        sx={{ textDecoration: 'none' }}
      >
        {t(`drawer.item.title.${titleKey}`)}
      </Typography>
      {external && (
        <Launch
          sx={{
            color: ({ palette }) => palette.action.active,
            width: '0.7em',
            height: '0.7em',
            mr: 1.4,
          }}
        />
      )}
    </Box>
  )
}

export default NavItem
