import numeral from 'numeral'
import { PaymentMode } from '@tokoku-universe/react-core/common'
import isEmail from 'validator/es/lib/isEmail'
import isMobilePhone from 'validator/es/lib/isMobilePhone'
import { mobilePhoneLocaleByCountry } from '../../config/constants'

export function formatPrice(price: number): string {
  return numeral(price).format('0,0')
}

export function formatPriceFloat(price: number): string {
  return numeral(price).format('0,0.00')
}

export function cleanupSearchText(search: string) {
  return search.replace(/\s+/g, ' ').trim().toLocaleLowerCase()
}

export function isValidEmail(email: string) {
  return isEmail(email)
}

export function isValidPhoneNumber(phone: string) {
  return isMobilePhone(phone, Object.values(mobilePhoneLocaleByCountry), {
    strictMode: true,
  })
}

export function sanitizePhoneNumber(phone: string) {
  return phone.replace(/\s/g, '')
}

export function isValidChannelReferenceId(referenceId: string) {
  return referenceId.length > 2
}

export function parseNumberStringInput(input: string) {
  const defaultValue = 0
  if (!input) {
    return String(defaultValue)
  }

  const parsed = Number(input)
  if (isNaN(parsed)) {
    return String(defaultValue)
  }

  return String(parsed)
}

export function isValidPaymentMode(paymentMode?: string) {
  const mode = Object.values(PaymentMode).find((mode) => mode === paymentMode)
  return Boolean(mode)
}
