import { Backdrop } from '@mui/material'
import ClockLoader from 'react-spinners/ClockLoader'
import useTheming from '../../utils/theme/hooks'
import { usePageLoadingState } from '../../services/ui/store'

function PageLoader() {
  const { palette } = useTheming()
  const isPageLoading = usePageLoadingState((state) => state.pageLoading)

  return (
    <Backdrop
      open={isPageLoading}
      sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
    >
      <ClockLoader size={64} loading color={palette.primary.main} />
    </Backdrop>
  )
}

export default PageLoader
