import { PaymentMode } from '@tokoku-universe/react-core/common'
import {
  StoreChannel,
  StoreCountry,
  StoreCurrency,
  StoreOrderStatus,
  StoreOrderType,
  StoreTimezone,
  TransactionCategory,
  TransactionType,
} from '../../config/types'
import { ApiCustomer } from '../customers/types'

export interface StoreOrganization {
  id: string
  name: string
  isDeleted: boolean
  createdAt: string
  updatedAt: string
}

export interface ApiStore {
  id: string
  orgId: string
  name: string
  address: string
  address2?: string
  city: string
  countryCode: StoreCountry
  currencyCode: StoreCurrency
  timezone: StoreTimezone
  isDeleted: boolean
  createdAt: string
  updatedAt: string
  channels?: ApiStoreChannel[]
}

export type FindStoreInclude = 'channels' | 'org'

export interface FindStoreOptions {
  include?: FindStoreInclude[]
}

export interface FindStoreQueryDto {
  include?: string
}

export interface ApiMedia {
  id: string
  orgId: string
  filename?: string
  versionId: string
  mimetype: string
  url: string
  size: number
  width: number
  height: number
}

export interface ApiVariant {
  id: string
  orgId: string
  name: string
  description: string | null
  internalName: string
  minSelection: number
  maxSelection: number
  isDeleted: boolean
  values?: ApiVariantValue[]
  createdAt: string
  updatedAt: string
}

export interface ApiVariantValue {
  id: string
  variantId: string
  name: string
  price: number
  cost: number
  isDeleted: boolean
  createdAt: string
  updatedAt: string
}

export interface ApiProduct {
  id: string
  orgId: string
  description: string | null
  name: string
  price: number
  internalName: string | null
  createdAt: string
  updatedAt: string
  byWeight: boolean
  imageUrl: string | null
  isDeleted: boolean
  media: ApiMedia[]
  variants?: ApiVariant[]
}

export interface ApiItem {
  id: string
  sequence: number
  name?: string | null
  description?: string | null
  price?: number | null
  base: ApiProduct
}

export interface ApiCategory {
  id: string
  name: string
  sequence: number
  items: ApiItem[]
}

export interface ApiCatalog {
  id: string
  channelId: string
  status: string
  isDeleted: boolean
  categories: ApiCategory[]
  createdAt: string
  updatedAt: string
}

export interface CreateOrderVariantDto {
  id: string
}

export interface CreateOrderItemDto {
  id: string
  quantity: number
  notes?: string
  variants?: CreateOrderVariantDto[]
}

export interface CreateOrderDto {
  channel: StoreChannel
  type: StoreOrderType
  notes?: string
  shortId?: string
  customerId?: string
  campaignId?: string
  redeem?: number
  paymentMode?: PaymentMode
  items: CreateOrderItemDto[]
}

export interface UpdateOrderDto {
  status?: StoreOrderStatus
  paymentMode?: PaymentMode
}

export interface ApiOrderItem {
  id: string
  orderId: string
  itemId: string
  productId: string
  name: string
  price: number
  variants: ApiVariantValue[]
  notes?: string
  quantity: number
}

export interface ApiStoreChannel {
  id: string
  storeId: string
  type: StoreChannel
  externalId: string | null
  isDeleted: boolean
  createdAt: string
  updatedAt: string
}

export interface ApiOrder {
  id: string
  orgId: string
  storeId: string
  channel: ApiStoreChannel
  channelId: string
  shortId?: string
  externalId?: string
  type: StoreOrderType
  status: StoreOrderStatus
  customer?: ApiCustomer | null
  notes?: string
  subtotal: number
  tax: number
  total: number
  channelDiscount: number
  merchantDiscount: number
  loyaltyId?: string | null
  loyaltyDiscount?: number
  loyaltyPointsAccrued?: number | null
  loyaltyPointsRedeemed?: number | null
  loyaltyPointsUsed?: number | null
  acceptedAt?: string
  completedAt?: string
  paymentMode?: PaymentMode
  store?: ApiStore
  items: ApiOrderItem[]
  transactions: ApiTransaction[]
  createdAt: string
  updatedAt: string
}

export interface ApiTransaction {
  id: string
  orgId: string
  storeId: string
  orderId?: string
  paymentMode: PaymentMode
  currencyCode: StoreCurrency
  amount: number
  redemptionToken?: string | null
  amountReceived?: number
  change?: number
  type: TransactionType
  order?: ApiOrder
  createdAt: string
  updatedAt: string
}

export interface CreateTransactionDto {
  amount: number
  amountReceived?: number
  category: TransactionCategory
  currencyCode: StoreCurrency
  paymentMode: PaymentMode
  type: TransactionType
  note?: string
  orderId?: string
}

export enum DiscountType {
  PercentageOff = 'PERCENTAGE_OFF',
  AmountOff = 'AMOUNT_OFF',
}

export enum DiscountScope {
  Order = 'ORDER',
  Product = 'PRODUCT',
}

export enum DiscountQuotaType {
  Campaign = 'CAMPAIGN',
}

export enum DiscountQuotaScope {
  Total = 'TOTAL',
}

export enum CampaignType {
  Discount = 'DISCOUNT',
}
export interface BaseCreateCampaignDto {
  name: string
  startTime: string
  endTime: string
  channelIds?: string[]
}

export type CreateCampaignDto = BaseCreateCampaignDto & DiscountCampaign

export interface BaseUpdateCampaignDto {
  isActive?: boolean
  name?: string
  startTime?: string
  endTime?: string
}

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

export type UpdateCampaignDto = BaseUpdateCampaignDto &
  DeepPartial<DiscountCampaign>

export interface BaseDiscountCampaignConfig {
  type: DiscountType
  amount: number
  merchantPercentage?: number
  paymentModes?: PaymentMode[]
  quotaType?: DiscountQuotaType
  quotaScope?: DiscountQuotaScope
  quotaAmount?: number
}

export type DiscountCampaignConfig = BaseDiscountCampaignConfig & {
  scope: DiscountScope.Order
  minOrderAmount?: number
  maxOrderAmount?: number
}

export type ProductDiscountCampaignConfig = BaseDiscountCampaignConfig & {
  scope: DiscountScope.Product
  productIds: string[]
}

export interface DiscountCampaign {
  type: CampaignType.Discount
  config: DiscountCampaignConfig | ProductDiscountCampaignConfig
}

export interface ApiBaseCampaign {
  id: string
  orgId: string
  isActive: boolean
  name: string
  startTime: string
  endTime: string
  isDeleted: boolean
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

export type ApiCampaign = ApiBaseCampaign & DiscountCampaign

export interface FindStoreOrderOptions {
  start?: string
  end?: string
}

export interface ItemSearchState {
  searchText: string | null
  changeSearchText: (searchText: string) => void
}

export interface SelectedCategoryState {
  selectedCategory: string | null
  changeSelectedCategory: (categoryId: string) => void
}

export interface OrderItem {
  itemId: string
  quantity: number
  notes?: string
  variants?: Record<string, string[]>
}

export interface CurrentOrderState {
  currentOrders: OrderItem[]
  addOrder: (order: OrderItem) => void
  removeOrder: (orderIdx: number) => void
  incrementOrderQuantity: (orderIdx: number) => void
  decrementOrderQuantity: (orderIdx: number) => void
  clearCurrentOrders: () => void
}

export interface SelectedStoreState {
  selectedStore: string | null
  changeSelectedStore: (storeId: string) => void
}

export interface RegisterDeviceDto {
  did: string
}
