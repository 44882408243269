import MotionViewContainer from '../MotionViewContainer'
import { ViewContainerProps } from './types'

function ViewContainer(props: ViewContainerProps) {
  const {
    children,
    alignItems = 'flex-start',
    justifyContent = 'flex-start',
    withToolbar = true,
    maxWidth = false,
    px,
    sx,
  } = props

  return (
    <MotionViewContainer
      maxWidth={maxWidth}
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        '&.MuiContainer-root': {
          px: px ?? 'inherit',
        },
        pt: withToolbar ? 8 : 0,
        alignItems,
        justifyContent,
        ...sx,
      }}
    >
      {children}
    </MotionViewContainer>
  )
}

export default ViewContainer
