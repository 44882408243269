import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Card, Typography } from '@mui/material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import OrderDataGrid from './OrderDataGrid'
import { StoreOrderStatus } from '../../config/types'
import { findTodayDateRange } from '../../utils/date'
import MotionViewContainer from '../../components/MotionViewContainer'
import OrderQuickFilter from './OrderQuickFilter'
import { useQueryNavigation } from '../../utils/routing/hooks'
import { RoutePath } from '../../routes/types'
import { OrderHistoryQueryParams } from './types'

const { start: todayStart, end: todayEnd } = findTodayDateRange()
const orderStatusSet = new Set(Object.values(StoreOrderStatus))

function OrderHistory() {
  const { t } = useLocalization()
  const navigate = useNavigate()
  const [query, modifyQuery] = useQueryNavigation<OrderHistoryQueryParams>()
  const { start, end, search, status } = query
  const searchValue = search || ''

  const selectedDateRange = useMemo<[Date, Date]>(() => {
    const dateStart = new Date(Number(start))
    const dateEnd = new Date(Number(end))
    if (isNaN(dateStart.getTime()) || isNaN(dateEnd.getTime())) {
      return [todayStart, todayEnd]
    }

    return [dateStart, dateEnd]
  }, [start, end])

  const selectedStatus = useMemo(() => {
    const statusQuery = status as StoreOrderStatus
    if (orderStatusSet.has(statusQuery)) {
      return statusQuery
    }

    return StoreOrderStatus.All
  }, [status])

  const onNavigateToChannelList = useCallback(() => {
    navigate(RoutePath.CreateOrder)
  }, [navigate])

  const onChangeDateRange = useCallback(
    (range: [Date, Date]) => {
      modifyQuery({
        start: String(range[0].getTime()),
        end: String(range[1].getTime()),
      })
    },
    [modifyQuery]
  )

  const onChangeSearchValue = useCallback(
    (value: string) => {
      modifyQuery({
        search: value || undefined,
      })
    },
    [modifyQuery]
  )

  const onChangeStatus = useCallback(
    (status: StoreOrderStatus) => {
      modifyQuery({
        status: status || undefined,
      })
    },
    [modifyQuery]
  )

  return (
    <MotionViewContainer
      maxWidth="lg"
      sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
      disableGutters
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        maxWidth="lg"
        width="100%"
        px={1}
        mt={2}
      >
        <Typography variant="h5">{t('views.orders.heading.title')}</Typography>
      </Box>
      <Box maxWidth="lg" width="100%" mt={2}>
        <OrderQuickFilter
          selectedDateRange={selectedDateRange}
          selectedStatus={selectedStatus}
          onChangeDateRange={onChangeDateRange}
          onChangeSearchValue={onChangeSearchValue}
          onChangeStatus={onChangeStatus}
          onCreateClick={onNavigateToChannelList}
        />
      </Box>
      <Box
        width="100%"
        flex={1}
        maxWidth="lg"
        mt={2}
        mb={{
          xs: 0,
          sm: 0,
          md: 0,
          lg: 2,
        }}
      >
        <Card sx={{ height: '100%' }}>
          <OrderDataGrid
            dateRange={selectedDateRange}
            searchValue={searchValue}
            status={selectedStatus}
          />
        </Card>
      </Box>
    </MotionViewContainer>
  )
}

export default OrderHistory
