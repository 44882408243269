import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { StoreOrderStatus } from '../../../config/types'
import useTheming from '../../../utils/theme/hooks'
import { OrderStatusConfig, OrderStatusProps } from './types'

function OrderStatus(props: OrderStatusProps) {
  const { status, variant = 'dot', sx } = props
  const { palette } = useTheming()
  const { t } = useLocalization()

  const statusConfig = useMemo<
    Record<StoreOrderStatus, OrderStatusConfig>
  >(() => {
    return {
      [StoreOrderStatus.All]: {
        key: StoreOrderStatus.All,
        color: palette.info.light,
      },
      [StoreOrderStatus.Pending]: {
        key: StoreOrderStatus.Pending,
        color: palette.warning.light,
      },
      [StoreOrderStatus.Accepted]: {
        key: StoreOrderStatus.Accepted,
        color: palette.secondary.main,
      },
      [StoreOrderStatus.Cancelled]: {
        key: StoreOrderStatus.Cancelled,
        color: palette.error.light,
      },
      [StoreOrderStatus.Paid]: {
        key: StoreOrderStatus.Paid,
        color: palette.success.light,
      },
      [StoreOrderStatus.Complete]: {
        key: StoreOrderStatus.Complete,
        color: palette.success.dark,
      },
      [StoreOrderStatus.Delivering]: {
        key: StoreOrderStatus.Delivering,
        color: palette.warning.main,
      },
      [StoreOrderStatus.Ready]: {
        key: StoreOrderStatus.Ready,
        color: palette.success.main,
      },
      [StoreOrderStatus.Refunded]: {
        key: StoreOrderStatus.Refunded,
        color: palette.error.dark,
      },
    }
  }, [palette])

  const config = status && statusConfig[status]

  return config ? (
    <>
      {variant === 'dot' ? (
        <Box display="flex" alignItems="center" justifySelf="center" sx={sx}>
          <Box
            width={8}
            height={8}
            mr={0.6}
            borderRadius={2}
            sx={{ backgroundColor: config.color }}
          />
          <Typography lineHeight={1} variant="body2">
            {t(`product.status.${config.key}`)}
          </Typography>
        </Box>
      ) : (
        <Typography sx={{ color: config.color, ...sx }}>
          {t(`product.status.${config.key}`)}
        </Typography>
      )}
    </>
  ) : null
}

export default OrderStatus
