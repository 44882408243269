import { Box, Typography, alpha } from '@mui/material'
import { DashCardHeaderProps } from './types'

function DashCardHeader(props: DashCardHeaderProps) {
  const { IconComponent, label, color } = props

  return (
    <Box display="flex" alignItems="center">
      <Box
        width={36}
        height={36}
        borderRadius={1}
        sx={{
          backgroundColor: ({ palette }) => alpha(palette[color].light, 0.1),
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <IconComponent fontSize="small" color={color} />
      </Box>
      <Typography ml={1.5} color="textSecondary">
        {label}
      </Typography>
    </Box>
  )
}

export default DashCardHeader
