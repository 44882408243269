import React, { useState, useMemo, useCallback } from 'react'
import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { Close, ReceiptOutlined } from '@mui/icons-material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import OrderStatus from './OrderStatus'
import { formatReadableDateTime } from '../../../utils/date'
import { useDeviceType } from '../../../utils/device/hooks'
import { StoreChannel, StoreOrderStatus } from '../../../config/types'
import { formatPrice } from '../../../utils/string'
import { printOrder } from '../../../services/printer'
import PaymentBadge from './PaymentBadge'
import { useAppSnackbar } from '../../../utils/snackbar/hooks'
import {
  useSelectedStore,
  useStoreOrderById,
} from '../../../services/stores/query'
import { OrderDetailDialogProps } from './types'
import {
  calculateOrderItemTotal,
  calculateTotalDiscount,
} from '../../../utils/order'
import ChannelBadge from '../../../components/ChannelBadge'

function OrderDetailLoader() {
  return (
    <>
      <DialogTitle>
        <Skeleton />
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Stack
            spacing={2}
            border="1px solid"
            borderColor={({ palette }) => palette.divider}
            borderRadius={1}
            p={2}
          >
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Skeleton sx={{ alignSelf: 'flex-end' }} height={60} width={100} />
      </DialogActions>
    </>
  )
}

function OrderDetailDialog(props: OrderDetailDialogProps) {
  const { open, onClose, onPaymentClick, onCancelClick, orderId } = props
  const [printDisabled, setPrintDisabled] = useState(false)
  const isMobile = useDeviceType('mobile')
  const { t } = useLocalization()
  const { isLoading, order } = useStoreOrderById(orderId)
  const firstOrderTransaction =
    order && order.transactions?.length > 0 ? order?.transactions[0] : null
  const totalDiscount = calculateTotalDiscount(order)
  const store = useSelectedStore()
  const { enqueueSnackbar } = useAppSnackbar()

  const payment = useMemo(() => {
    if (!order) {
      return {
        hasPaid: false,
      }
    }

    const hasPaid = order.status === StoreOrderStatus.Paid

    if (!firstOrderTransaction) {
      return {
        hasPaid,
      }
    }

    return {
      hasPaid,
      hasPayment: Boolean(firstOrderTransaction),
      paymentAmount: firstOrderTransaction?.amount || 0,
      paymentChange: firstOrderTransaction?.change || 0,
      mode: firstOrderTransaction?.paymentMode,
      timestamp: firstOrderTransaction?.createdAt,
    }
  }, [order, firstOrderTransaction])

  const onPrintOrder = useCallback(async () => {
    if (!order || !store) {
      return
    }

    try {
      setPrintDisabled(true)
      await printOrder(store, order, firstOrderTransaction)
    } catch (e) {
      console.error(e)
      enqueueSnackbar({
        event: 'print_order',
        variant: 'error',
      })
    } finally {
      setPrintDisabled(false)
    }
  }, [order, store, firstOrderTransaction, enqueueSnackbar])

  const shouldPay = useMemo(() => {
    if (!order) {
      return false
    }

    const statusList = [StoreOrderStatus.Pending]
    return statusList.includes(order.status)
  }, [order])

  const isCancellable = useMemo(() => {
    if (!order) {
      return false
    }

    const statusList = [StoreOrderStatus.Paid, StoreOrderStatus.Cancelled]
    return !statusList.includes(order.status)
  }, [order])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen={isMobile}
      maxWidth="xs"
    >
      {isLoading ? (
        <OrderDetailLoader />
      ) : (
        order && (
          <>
            <DialogTitle>
              {t('views.orders.dialog.order_detail.title', {
                orderId: order.shortId,
              })}
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 10,
                  color: ({ palette }) => palette.text.secondary,
                }}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Box display="flex">
                <Box flex={1}>
                  <Typography>
                    <b>{t('views.orders.dialog.order_detail.status.header')}</b>
                  </Typography>
                  <Box>
                    <OrderStatus variant="text" status={order.status} />
                  </Box>
                </Box>
                <Box flex={1}>
                  <Typography>
                    <b>
                      {t('views.orders.dialog.order_detail.created_at.header')}
                    </b>
                  </Typography>
                  <Typography>
                    {formatReadableDateTime(order.createdAt)}
                  </Typography>
                </Box>
              </Box>
              {order.channel.type !== StoreChannel.PointOfSale && (
                <Box mt={2}>
                  <Typography>
                    <b>
                      {t('views.orders.dialog.order_detail.channel.header')}
                    </b>
                  </Typography>
                  <ChannelBadge size="small" channel={order.channel.type} />
                </Box>
              )}
              <Box mt={2}>
                <Typography>
                  <b>
                    {t(
                      'views.orders.dialog.order_detail.payment_method.header'
                    )}
                  </b>
                </Typography>
                {payment.hasPaid && !order.paymentMode && payment.mode && (
                  <PaymentBadge paymentMode={payment.mode} />
                )}
                {order.paymentMode ? (
                  <PaymentBadge paymentMode={order.paymentMode} />
                ) : (
                  <>{!payment.hasPaid && <Typography>-</Typography>}</>
                )}
              </Box>
              {payment.hasPaid && payment.timestamp && (
                <Box flex={1} mt={2}>
                  <Typography>
                    <b>
                      {t(
                        'views.orders.dialog.order_detail.payment_time.header'
                      )}
                    </b>
                  </Typography>
                  <Typography>
                    {formatReadableDateTime(payment.timestamp)}
                  </Typography>
                </Box>
              )}
              {order.customer && (
                <Box mt={2}>
                  <Typography>
                    <b>
                      {t('views.orders.dialog.order_detail.customer.header')}
                    </b>
                  </Typography>
                  <Typography>
                    {`${order.customer.name} (${order.customer.phoneNumber})`}
                  </Typography>
                </Box>
              )}
              <Divider sx={{ mt: 6 }} />
              {Array.isArray(order.items) && (
                <TableContainer
                  sx={{
                    mb: 3,
                    backgroundColor: ({ palette }) =>
                      alpha(
                        palette.grey[100],
                        palette.mode === 'dark' ? 0 : 0.5
                      ),
                    borderRightColor: ({ palette }) => palette.divider,
                    borderRightStyle: 'solid',
                    borderRightWidth: 1,
                    borderLeftColor: ({ palette }) => palette.divider,
                    borderLeftStyle: 'solid',
                    borderLeftWidth: 1,
                  }}
                >
                  <Table size="small">
                    <TableHead>
                      <TableRow
                        sx={{
                          '.MuiTableCell-root': {
                            fontWeight: 700,
                          },
                        }}
                      >
                        <TableCell>
                          {t(
                            'views.orders.dialog.order_detail.header.quantity.label'
                          )}
                        </TableCell>
                        <TableCell>
                          {t(
                            'views.orders.dialog.order_detail.header.item.label'
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {t(
                            'views.orders.dialog.order_detail.header.price.label'
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {order.items.map((orderItem) => {
                        const { id, quantity, price, name, notes, variants } =
                          orderItem
                        const total = calculateOrderItemTotal(orderItem)
                        return (
                          <React.Fragment key={id}>
                            <TableRow
                              key={id}
                              sx={{
                                '.MuiTableCell-root': {
                                  borderBottomWidth: 0,
                                },
                              }}
                            >
                              <TableCell
                                align="right"
                                sx={{
                                  verticalAlign: 'top',
                                }}
                              >
                                {quantity}
                              </TableCell>
                              <TableCell>{name}</TableCell>
                              {price && variants?.length > 0 && (
                                <TableCell
                                  align="right"
                                  sx={{
                                    verticalAlign: 'bottom',
                                  }}
                                >
                                  {formatPrice(price)}
                                </TableCell>
                              )}
                            </TableRow>
                            {Array.isArray(variants) &&
                              variants.map(({ id, name, price }) => (
                                <TableRow
                                  key={id}
                                  sx={{
                                    '.MuiTableCell-root': {
                                      borderBottomWidth: 0,
                                      color: ({ palette }) =>
                                        palette.text.secondary,
                                      py: 0.5,
                                    },
                                  }}
                                >
                                  <TableCell />
                                  <TableCell>{name}</TableCell>
                                  <TableCell align="right">
                                    {price ? formatPrice(price) : 0}
                                  </TableCell>
                                </TableRow>
                              ))}
                            {notes && (
                              <TableRow
                                sx={{
                                  '.MuiTableCell-root': {
                                    borderBottomWidth: 0,
                                    color: ({ palette }) =>
                                      palette.text.secondary,
                                    py: 0.5,
                                  },
                                }}
                              >
                                <TableCell />
                                <TableCell>{notes}</TableCell>
                                <TableCell align="right" />
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell colSpan={2} />
                              <TableCell
                                align="right"
                                sx={{
                                  borderTopWidth: 1,
                                  borderTopStyle: 'solid',
                                  borderTopColor: ({ palette }) =>
                                    palette.divider,
                                }}
                              >
                                {formatPrice(total)}
                              </TableCell>
                            </TableRow>
                          </React.Fragment>
                        )
                      })}
                      <TableRow
                        sx={{
                          '.MuiTableCell-root': {
                            borderBottomWidth: 0,
                          },
                        }}
                      >
                        <TableCell colSpan={2} align="right">
                          {t(
                            'views.orders.dialog.order_detail.footer.subtotal.label'
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {formatPrice(order.subtotal)}
                        </TableCell>
                      </TableRow>
                      {totalDiscount > 0 && (
                        <TableRow
                          sx={{
                            '.MuiTableCell-root': {
                              borderBottomWidth: 0,
                            },
                          }}
                        >
                          <TableCell colSpan={2} align="right">
                            {t(
                              'views.orders.dialog.order_detail.footer.discount.label'
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {'-' + formatPrice(totalDiscount)}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell colSpan={2} align="right">
                          {t(
                            'views.orders.dialog.order_detail.footer.tax.label'
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {formatPrice(order.tax)}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          '.MuiTableCell-root': {
                            fontWeight: 700,
                          },
                        }}
                      >
                        <TableCell colSpan={2} align="right">
                          {t(
                            'views.orders.dialog.order_detail.footer.total.label'
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {formatPrice(order.total)}
                        </TableCell>
                      </TableRow>
                      {payment.hasPayment && (
                        <>
                          <TableRow>
                            <TableCell colSpan={2} align="right">
                              {t(
                                'views.orders.dialog.order_detail.footer.payment_received.label'
                              )}
                            </TableCell>
                            <TableCell align="right">
                              {formatPrice(payment.paymentAmount)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={2} align="right">
                              {t(
                                'views.orders.dialog.order_detail.footer.change.label'
                              )}
                            </TableCell>
                            <TableCell align="right">
                              {formatPrice(payment.paymentChange)}
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
              {order.notes && (
                <>
                  <Typography fontWeight={700}>
                    {t('views.orders.dialog.order_detail.notes.header')}
                  </Typography>
                  <Typography mb={3}>{order.notes}</Typography>
                </>
              )}
              <Box width="100%">
                {isCancellable && (
                  <Button
                    variant="contained"
                    color="error"
                    size="large"
                    onClick={onCancelClick}
                    disableElevation
                    fullWidth
                  >
                    {t('button.label.cancel_order')}
                  </Button>
                )}
                {shouldPay && (
                  <Button
                    onClick={onPaymentClick}
                    variant="contained"
                    color="info"
                    size="large"
                    fullWidth
                    disableElevation
                    sx={{ mt: 1.5 }}
                  >
                    {t('button.label.payment')}
                  </Button>
                )}
                {payment.hasPayment && (
                  <Button
                    fullWidth
                    size="large"
                    variant="outlined"
                    color="inherit"
                    onClick={onPrintOrder}
                    sx={{ mt: 1.5 }}
                    startIcon={
                      <ReceiptOutlined fontSize="small" color="inherit" />
                    }
                    disabled={printDisabled}
                  >
                    {t('button.label.print_receipt')}
                  </Button>
                )}
              </Box>
            </DialogContent>
          </>
        )
      )}
    </Dialog>
  )
}

export default OrderDetailDialog
