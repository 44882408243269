import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { PaymentMode } from '@tokoku-universe/react-core/common'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { paymentModeSelectConfig } from '@tokoku-universe/react-core/components/PaymentModeSelect/config'
import { formatPrice, formatPriceFloat } from '../../utils/string'
import { calculateTotalDiscount } from '../../utils/order'
import { PaymentConfirmDialogProps } from './types'

function PaymentConfirmDialog(props: PaymentConfirmDialogProps) {
  const { open, paymentMode, order, onClose, onConfirm } = props
  const [paymentAmount, setPaymentAmount] = useState('')
  const isCashPayment = paymentMode === PaymentMode.Cash
  const { t } = useLocalization()
  const requiredAmount = order?.total || 0
  const subtotal = order?.subtotal || 0
  const discount = calculateTotalDiscount(order)
  const loyaltyDiscount = order?.loyaltyDiscount || 0

  const payment = useMemo(() => {
    const orderPaymentMode =
      order && order.paymentMode ? order.paymentMode : paymentMode
    return paymentModeSelectConfig[orderPaymentMode]
  }, [paymentMode, order])

  useEffect(() => {
    if (!open) {
      return
    }

    if (isCashPayment) {
      setPaymentAmount('')
      return
    }

    setPaymentAmount(String(requiredAmount))
  }, [open, isCashPayment, requiredAmount])

  const onChangePaymentAmount = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const paymentValue = event.target.value
      const defaultValue = 0
      if (!paymentValue) {
        setPaymentAmount(String(defaultValue))
        return
      }

      const parsed = Number(paymentValue)
      if (isNaN(parsed)) {
        setPaymentAmount(String(defaultValue))
        return
      }

      setPaymentAmount(String(parsed))
    },
    []
  )

  const buttonDisabled = useMemo(() => {
    const paymentValue = Number(paymentAmount)
    if (!requiredAmount || isNaN(paymentValue)) {
      return true
    }

    return paymentValue < requiredAmount
  }, [paymentAmount, requiredAmount])

  const changeAmount = useMemo(() => {
    const paymentValue = Number(paymentAmount) || 0
    return paymentValue - requiredAmount
  }, [paymentAmount, requiredAmount])

  const onConfirmPayment = useCallback(() => {
    onConfirm(Number(paymentAmount))
  }, [onConfirm, paymentAmount])

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{t('views.pos.dialog.payment_confirm.title')}</DialogTitle>
      <DialogContent>
        <Typography
          fontWeight={700}
          sx={{ color: ({ palette }) => palette.warning.main }}
        >
          {t('views.pos.dialog.payment_confirm.content')}
        </Typography>
        {payment && (
          <Box display="flex" alignItems="center" mt={2}>
            {payment.adornment}
            <Typography ml={1}>{t(`payment.type.${payment.key}`)}</Typography>
          </Box>
        )}
        {typeof requiredAmount === 'number' && (
          <>
            {(discount > 0 || loyaltyDiscount > 0) && (
              <>
                <Typography mt={3} fontWeight={600}>
                  {t('views.pos.dialog.payment_confirm.subtotal.label')}
                </Typography>
                <Typography variant="h4" color="textSecondary">
                  {formatPrice(subtotal)}
                </Typography>
                {discount > 0 && (
                  <>
                    <Typography mt={3} fontWeight={600}>
                      {t('views.pos.dialog.payment_confirm.discount.label')}
                    </Typography>
                    <Typography
                      sx={{ color: ({ palette }) => palette.error.main }}
                      variant="h4"
                    >
                      -{formatPrice(discount)}
                    </Typography>
                  </>
                )}
                {loyaltyDiscount > 0 && (
                  <>
                    <Typography mt={3} fontWeight={600}>
                      {t(
                        'views.pos.dialog.payment_confirm.loyalty_discount.label'
                      )}
                    </Typography>
                    <Typography
                      sx={{ color: ({ palette }) => palette.error.main }}
                      variant="h4"
                    >
                      -{formatPrice(loyaltyDiscount)}
                    </Typography>
                  </>
                )}
              </>
            )}
            <Typography mt={3} fontWeight={600}>
              {t('views.pos.dialog.payment_confirm.payment_amount.label')}
            </Typography>
            <Typography
              sx={{ color: ({ palette }) => palette.success.light }}
              variant="h4"
            >
              {formatPrice(requiredAmount)}
            </Typography>
            <Typography mt={3} mb={0.5} fontWeight={600}>
              {t('views.pos.dialog.payment_confirm.payment_received.label')}
            </Typography>
            <TextField
              type="number"
              inputProps={{
                step: 0.01,
              }}
              value={paymentAmount}
              onChange={onChangePaymentAmount}
              disabled={!isCashPayment}
              fullWidth
            />
            <Typography mt={3} fontWeight={600}>
              {t('views.pos.dialog.payment_confirm.change.label')}
            </Typography>
            <Typography
              sx={{
                color: ({ palette }) =>
                  changeAmount < 0 ? palette.error.main : palette.success.light,
              }}
              variant="h4"
            >
              {formatPriceFloat(changeAmount)}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('button.label.back')}</Button>
        <Button
          disabled={buttonDisabled}
          onClick={onConfirmPayment}
          variant="contained"
          disableElevation
        >
          {t('button.label.complete')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PaymentConfirmDialog
