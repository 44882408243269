import { useNavigate } from 'react-router-dom'
import { Card, Typography } from '@mui/material'
import { PointOfSaleOutlined } from '@mui/icons-material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import CardHeader from './DashCardHeader'
import { RoutePath } from '../../../routes/types'

function PosCard() {
  const { t } = useLocalization()
  const navigate = useNavigate()

  const onCardClick = () => {
    navigate(RoutePath.PointOfSale)
  }

  return (
    <Card
      onClick={onCardClick}
      role="button"
      sx={{
        cursor: 'pointer',
        display: 'flex',
        p: 2,
        height: '100%',
        flexDirection: 'column',
        textDecoration: 'none',
      }}
    >
      <CardHeader
        label={t('views.home.card.pos.header')}
        IconComponent={PointOfSaleOutlined}
        color="primary"
      />
      <Typography
        sx={{ color: ({ palette }) => palette.success.light }}
        pt={2}
        pb={1}
        variant="h5"
      >
        {t('views.home.card.pos.label.ready')}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        {t('views.home.card.pos.label.description')}
      </Typography>
    </Card>
  )
}

export default PosCard
