import * as Yup from 'yup'
import isStrongPassword from 'validator/es/lib/isStrongPassword'

export function isValidPassword(password: string) {
  return isStrongPassword(password, {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
  })
}

export const passwordValidationSchema = Yup.string().test({
  test: (pwd) => (pwd ? isValidPassword(pwd) : false),
})
