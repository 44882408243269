import { useCallback, useMemo, useState } from 'react'
import {
  Card,
  Box,
  Typography,
  Unstable_Grid2 as Grid,
  alpha,
} from '@mui/material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import Highlighter from 'react-highlight-words'
import { formatPrice } from '../../../utils/string'
import VariantSelectDialog from './VariantSelectDialog'
import ItemImage from '../ItemImage'
import ItemListLoader from './ItemListLoader'
import useTheming from '../../../utils/theme/hooks'
import {
  useCurrentOrderState,
  useItemSearchState,
} from '../../../services/stores/store'
import { useCatalogItemList } from '../../../services/stores/query'
import { ApiItem } from '../../../services/stores/types'
import { ItemListProps } from './types'

const CARD_IMAGE_HEIGHT = 160

function ItemList(props: ItemListProps) {
  const { channel } = props
  const { palette } = useTheming()
  const { t } = useLocalization()
  const { isLoading, items: itemList } = useCatalogItemList(channel)
  const searchText = useItemSearchState((state) => state.searchText)

  const currentOrders = useCurrentOrderState((state) => state.currentOrders)
  const addOrder = useCurrentOrderState((state) => state.addOrder)

  const [isVariantDialogOpen, setIsVariantDialogOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<ApiItem | null>(null)
  const toggleVariantDialogOpen = useCallback(() => {
    setIsVariantDialogOpen((open) => !open)
  }, [])

  const cleanedSearch = useMemo(() => {
    return searchText ? searchText.split(' ') : []
  }, [searchText])

  const itemInOrderSet = useMemo(() => {
    return new Set(currentOrders.map(({ itemId }) => itemId))
  }, [currentOrders])

  const onItemAdded = useCallback(
    (item: ApiItem, variants: Record<string, string[]>, notes?: string) => {
      addOrder({
        quantity: 1,
        itemId: item.id,
        variants,
        notes,
      })
    },
    [addOrder]
  )

  const onCardClick = useCallback(
    (item: ApiItem) => {
      return function onCardClick() {
        toggleVariantDialogOpen()
        setSelectedItem(item)
      }
    },
    [toggleVariantDialogOpen]
  )

  if (isLoading) {
    return <ItemListLoader />
  }

  return itemList.length > 0 ? (
    <Box width="100%" height="100%" pt={2} px={2}>
      <Grid container spacing={1.5} pb={12} disableEqualOverflow>
        {itemList.map((item) => (
          <Grid key={item.id} xs={6} sm={4} md={4} lg={3} xl={2}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                backgroundColor: ({ palette }) =>
                  itemInOrderSet.has(item.id)
                    ? alpha(palette.secondary.light, 0.18)
                    : 'transparent',
              }}
              variant={palette.mode === 'dark' ? 'elevation' : 'outlined'}
              data-id={item.id}
              onClick={onCardClick(item)}
            >
              <Box height={CARD_IMAGE_HEIGHT}>
                <ItemImage
                  src={item.base.media?.[0].url}
                  sx={{
                    objectFit: 'cover',
                    height: CARD_IMAGE_HEIGHT,
                    width: '100%',
                  }}
                />
              </Box>
              <Box
                px={1.5}
                pt={1.5}
                pb={1}
                display="flex"
                flexDirection="column"
                flex={1}
              >
                <Box flex={1}>
                  <Highlighter
                    searchWords={cleanedSearch}
                    textToHighlight={item.name || ''}
                  />
                </Box>
                <Box pt={1} display="flex" justifyContent="flex-end">
                  <Typography variant="body2" color="textSecondary">
                    {formatPrice(item.price)}
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <VariantSelectDialog
        open={isVariantDialogOpen}
        onClose={toggleVariantDialogOpen}
        onConfirm={onItemAdded}
        item={selectedItem}
      />
    </Box>
  ) : (
    <Box
      flex={1}
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="body1">
        {t('views.pos.item_list.label.not_found')}
      </Typography>
    </Box>
  )
}

export default ItemList
