import { useCallback, useMemo } from 'react'
import { Box, Typography, Radio, Checkbox } from '@mui/material'
import { VariantSelectionProps } from './types'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { formatPrice } from '../../../utils/string'
import { isMultiSelect } from './utils'

function VariantSelection(props: VariantSelectionProps) {
  const {
    variants,
    selected,
    variantGroupId,
    minSelection,
    maxSelection,
    onSelect,
  } = props
  const { t } = useLocalization()

  const isMultipleSelection = useMemo(
    () => isMultiSelect(minSelection, maxSelection),
    [minSelection, maxSelection]
  )
  const selectedSet = useMemo(() => new Set(selected), [selected])

  const variantList = useMemo(() => {
    const selectedLength = selectedSet.size

    return variants.map(({ id, name, price }) => {
      const isSelected = selectedSet.has(id)
      const nonSelectedDisabled =
        isMultipleSelection && selectedLength >= maxSelection

      return {
        id,
        name,
        price,
        selected: isSelected,
        disabled: isSelected ? false : nonSelectedDisabled,
      }
    })
  }, [variants, selectedSet, isMultipleSelection, maxSelection])

  const onVariantSelected = useCallback(
    (variantId: string) => {
      onSelect(variantGroupId, variantId)
    },
    [onSelect, variantGroupId]
  )

  return (
    <Box>
      {variantList.map(({ id, name, price, selected, disabled }) => (
        <Box
          key={id}
          pr={1.5}
          py={0.2}
          mt={0.75}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          onClick={() => !disabled && onVariantSelected(id)}
          sx={{
            cursor: disabled ? 'default' : 'pointer',
            border: ({ palette }) => `1px solid ${palette.divider}`,
            borderRadius: 1,
            '&:hover': {
              backgroundColor: ({ palette }) =>
                selected ? palette.action.selected : palette.action.hover,
            },
            ...(selected && {
              backgroundColor: ({ palette }) => palette.action.selected,
            }),
          }}
        >
          {isMultipleSelection ? (
            <Checkbox
              onClick={() => onVariantSelected(id)}
              disabled={disabled}
              sx={{ '& .MuiSvgIcon-root': { width: '0.8em', height: '0.8em' } }}
              checked={selected}
              size="small"
            />
          ) : (
            <Radio
              onClick={() => onVariantSelected(id)}
              disabled={disabled}
              sx={{ '& .MuiSvgIcon-root': { width: '0.8em', height: '0.8em' } }}
              checked={selected}
              size="small"
            />
          )}
          <Typography
            flex={1}
            pl={0.5}
            color={disabled ? 'textSecondary' : 'textPrimary'}
          >
            {name}
          </Typography>
          {price ? (
            <Typography flex={0} color="textSecondary">
              {t('views.pos.dialog.variant_select.additional_price', {
                price: formatPrice(price),
              })}
            </Typography>
          ) : null}
        </Box>
      ))}
    </Box>
  )
}

export default VariantSelection
