import { SvgIconComponent } from '@mui/icons-material'
import { BoxProps, ButtonProps } from '@mui/material'
import { StoreChannel, StoreCountry, StoreOrderType } from '../../config/types'
import { ApiCustomerLoyalty } from '../../services/customers/types'

export interface ClearOrderDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export interface DeleteOrderDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: (index: number) => void
  orderIndex: number
  channel: StoreChannel
}

export interface AddCustomerDialogProps {
  open: boolean
  channel: StoreChannel
  onClose: () => void
  onConfirm: () => void
}

export interface RedeemPointsDialogProps {
  open: boolean
  channel: StoreChannel
  redemptionAmount: number
  onChangeRedemptionAmount: (redeeem: number) => void
  onClose: () => void
  onConfirm: () => void
}

export interface OrderItemActionsProps {
  onIncrement: () => void
  onDecrement: () => void
  onDelete: () => void
}

export interface StyledButtonProps {
  color: ButtonProps['color']
  onClick: ButtonProps['onClick']
  IconComponent: SvgIconComponent
  sx?: ButtonProps['sx']
}

export interface OrderListProps {
  closeVisible?: boolean
  onCloseClick?: () => void
  onOrderComplete?: () => void
  channel: StoreChannel
}

export interface CreateOrderConfirmParams {
  notes?: string
  orderType: StoreOrderType
  nextStep: CreateOrderNextStep
  referenceId?: string
}

export interface CreateOrderDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  onChange: (config: CreateOrderConfirmParams) => void
  channel: StoreChannel
  configValue: CreateOrderConfirmParams
}

export enum CreateOrderNextStep {
  PayNow = 'pay_now',
  PayLater = 'pay_later',
}

export interface ChannelBadgeProps {
  channel: StoreChannel
  sx?: BoxProps['sx']
}

export interface CustomerDetail {
  name: string
  address: string
  city: string
  country: StoreCountry
}

export interface CustomerForm {
  phoneNumber: string
  name: string
  address: string
  city: string
  countryCode: StoreCountry
  loyalty: ApiCustomerLoyalty | null
}
