import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import queryClient from '../../config/query'
import { findAllProducts } from '.'
import { ApiProduct } from '../stores/types'

const PRODUCT_QUERY_KEY = 'products'
const PRODUCT_CACHE_TIME = 5 * 60 * 1000 // 2 minutes

export function useProductList(staleTime = PRODUCT_CACHE_TIME) {
  const { isLoading, data: products } = useQuery({
    queryKey: [PRODUCT_QUERY_KEY],
    queryFn: findAllProducts,
    staleTime,
  })

  return { isLoading, products }
}

export function useProductListById(staleTime = PRODUCT_CACHE_TIME) {
  const { products } = useProductList(staleTime)

  const productListById = useMemo<Record<string, ApiProduct>>(() => {
    if (!products) {
      return {}
    }

    return products.reduce<Record<string, ApiProduct>>((prev, curr) => {
      prev[curr.id] = curr
      return prev
    }, {})
  }, [products])

  return productListById
}

export function invalidateProductList() {
  return queryClient.invalidateQueries({ queryKey: [PRODUCT_QUERY_KEY] })
}
