import { useMemo, useState, useCallback } from 'react'
import { alpha, LinearProgress, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import {
  GridColDef,
  GridEventListener,
  GridRenderCellParams,
} from '@mui/x-data-grid/models'
import { PaymentMode } from '@tokoku-universe/react-core/common'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import NoOrderOverlay from './NoOrderOverlay'
import {
  cleanupSearchText,
  formatPrice,
  isValidPaymentMode,
} from '../../../utils/string'
import {
  StoreOrderStatus,
  StoreOrderType,
  TransactionCategory,
  TransactionType,
} from '../../../config/types'
import { formatReadableDateTime } from '../../../utils/date'
import OrderStatus from './OrderStatus'
import OrderDetailDialog from './OrderDetailDialog'
import PaymentSelectionDialog from '../../../components/PaymentSelectionDialog'
import PaymentConfirmDialog from '../../../components/PaymentConfirmDialog'
import ConfirmDialog from '../../../components/ConfirmDialog'
import { printOrder } from '../../../services/printer'
import OrderChannel from './OrderChannel'
import PaymentBadge from './PaymentBadge'
import { useAppSnackbar } from '../../../utils/snackbar/hooks'
import {
  useCancelStoreOrderMutation,
  useCreateTransactionMutation,
  useSelectedStore,
  useStoreOrderById,
  useStoreOrderList,
  useUpdateOrderPaymentModeMutation,
} from '../../../services/stores/query'
import { ApiOrder, ApiStoreChannel } from '../../../services/stores/types'
import { usePageLoadingState } from '../../../services/ui/store'
import { OrderDataGridProps } from './types'

const defaultPaymentMode = PaymentMode.Qris

function OrderDataGrid(props: OrderDataGridProps) {
  const { dateRange, searchValue, status: statusQuery } = props
  const [selectedOrderId, setSelectedOrderId] = useState('')
  const [selectedPaymentMode, setSelectedPaymentMode] =
    useState<PaymentMode>(defaultPaymentMode)
  const [isCancelOrderOpen, setIsCancelOrderOpen] = useState(false)
  const [isPaymentSelectionOpen, setIsPaymentSelectionOpen] = useState(false)
  const [isPaymentConfirmOpen, setIsPaymentConfirmOpen] = useState(false)
  const [isOrderDetailOpen, setIsOrderDetailOpen] = useState(false)
  const [isChangingOrderPaymentMode, setIsChangingOrderPaymentMode] =
    useState(false)
  const currentStore = useSelectedStore()
  const { isLoading, orders } = useStoreOrderList(
    dateRange[0].getTime(),
    dateRange[1].getTime()
  )
  const setPageLoading = usePageLoadingState((state) => state.setPageLoading)
  const { order: currentOrder } = useStoreOrderById(selectedOrderId)
  const cancelStoreOrderMutation = useCancelStoreOrderMutation(selectedOrderId)
  const updateStoreOrderPaymentModeMutation =
    useUpdateOrderPaymentModeMutation(selectedOrderId)
  const createTransactionMutation = useCreateTransactionMutation()
  const { enqueueSnackbar } = useAppSnackbar()
  const { t } = useLocalization()

  const toggleCancelOrder = useCallback(() => {
    setIsCancelOrderOpen((open) => !open)
  }, [])

  const togglePaymentSelection = useCallback(() => {
    setIsPaymentSelectionOpen((open) => !open)
  }, [])

  const togglePaymentConfirm = useCallback(() => {
    setIsPaymentConfirmOpen((open) => !open)
  }, [])

  const toggleOrderDetail = useCallback(() => {
    setIsOrderDetailOpen((open) => !open)
  }, [])

  const cleanedSearch = useMemo(() => {
    return cleanupSearchText(searchValue).split(' ')
  }, [searchValue])

  const filteredOrders = useMemo(() => {
    if (!orders) {
      return []
    }

    const initialOrders = orders.filter(({ status }) => {
      if (statusQuery && statusQuery !== StoreOrderStatus.All) {
        return status === statusQuery
      }

      return true
    })

    if (!searchValue) {
      return initialOrders
    }

    return initialOrders.filter(({ shortId, type, status, paymentMode }) => {
      const cleanedShortId = shortId?.toLocaleLowerCase() || ''
      const translatedType = t(`order.type.${type}`)
      const translatedStatus = t(`product.status.${status}`)
      const translatedPaymentMode = t(`payment.mode.${paymentMode}`)
      const cleanedType = translatedType.toLocaleLowerCase()
      const cleanedStatus = translatedStatus.toLocaleLowerCase()
      const cleanedPaymentMode = translatedPaymentMode.toLocaleLowerCase()

      return cleanedSearch.reduce((prev, curr) => {
        return (
          prev &&
          [cleanedShortId, cleanedType, cleanedStatus, cleanedPaymentMode]
            .join(' ')
            .includes(curr)
        )
      }, true)
    })
  }, [orders, statusQuery, searchValue, cleanedSearch, t])

  const columns = useMemo<GridColDef<ApiOrder>[]>(() => {
    return [
      {
        field: 'shortId',
        headerName: t('views.orders.data_grid.column.order_id'),
        align: 'center',
        headerAlign: 'center',
        minWidth: 120,
        flex: 0,
      },
      {
        field: 'status',
        headerName: t('views.orders.data_grid.column.status'),
        headerAlign: 'center',
        renderCell: (
          params: GridRenderCellParams<ApiOrder, StoreOrderStatus>
        ) => <OrderStatus status={params.value} />,
        align: 'center',
        minWidth: 180,
        flex: 0,
      },
      {
        field: 'channel',
        headerName: t('views.orders.data_grid.column.channel'),
        headerAlign: 'center',
        renderCell: (
          params: GridRenderCellParams<ApiOrder, ApiStoreChannel>
        ) => <OrderChannel channel={params.value?.type} />,
        align: 'center',
        width: 200,
        flex: 0,
      },
      {
        field: 'type',
        headerName: t('views.orders.data_grid.column.type'),
        headerAlign: 'center',
        renderCell: (
          params: GridRenderCellParams<ApiOrder, StoreOrderType>
        ) => <Typography>{t(`order.type.${params.value}`)}</Typography>,
        align: 'center',
        minWidth: 140,
        flex: 1,
      },
      {
        field: 'total',
        headerName: t('views.orders.data_grid.column.total'),
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams<ApiOrder, number>) =>
          params.value && formatPrice(params.value),
        align: 'right',
        minWidth: 120,
        flex: 0,
      },
      {
        field: 'paymentMode',
        headerName: t('views.orders.data_grid.column.payment_mode'),
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams<ApiOrder, PaymentMode>) => (
          <PaymentBadge paymentMode={params.value} />
        ),
        align: 'center',
        minWidth: 200,
        flex: 0,
      },
      {
        field: 'createdAt',
        headerName: t('views.orders.data_grid.column.order_time'),
        headerAlign: 'center',
        renderCell: (params: GridRenderCellParams<ApiOrder, string>) =>
          params.value && formatReadableDateTime(params.value),
        align: 'center',
        minWidth: 200,
        flex: 1,
      },
    ]
  }, [t])

  const onPaymentSelectionChange = useCallback((paymentMode: PaymentMode) => {
    setSelectedPaymentMode(paymentMode)
  }, [])

  const onPaymentClick = useCallback(() => {
    toggleOrderDetail()

    if (isValidPaymentMode(currentOrder?.paymentMode)) {
      togglePaymentConfirm()
    } else {
      setIsChangingOrderPaymentMode(false)
      togglePaymentSelection()
    }
  }, [
    toggleOrderDetail,
    togglePaymentSelection,
    togglePaymentConfirm,
    currentOrder,
  ])

  const onPaymentSelectionClose = useCallback(() => {
    toggleOrderDetail()
    togglePaymentSelection()
  }, [toggleOrderDetail, togglePaymentSelection])

  const onPaymentSelectionConfirm = useCallback(async () => {
    if (isChangingOrderPaymentMode) {
      // we will update current order payment mode here
      if (!currentOrder) {
        return
      }

      try {
        setPageLoading(true)
        await updateStoreOrderPaymentModeMutation(selectedPaymentMode)
        togglePaymentSelection()
      } catch (e) {
        // TODO: handle error gracefully here
      } finally {
        setPageLoading(false)
      }
      return
    }

    togglePaymentConfirm()
    if (isValidPaymentMode(currentOrder?.paymentMode)) {
      toggleOrderDetail()
    } else {
      togglePaymentSelection()
    }
  }, [
    togglePaymentConfirm,
    togglePaymentSelection,
    toggleOrderDetail,
    selectedPaymentMode,
    currentOrder,
    isChangingOrderPaymentMode,
    updateStoreOrderPaymentModeMutation,
    setPageLoading,
  ])

  const onConfirmPayment = useCallback(
    async (paymentAmount: number) => {
      if (!currentOrder || !currentStore) {
        return
      }

      try {
        setPageLoading(true)
        const transaction = await createTransactionMutation({
          amount: paymentAmount,
          orderId: selectedOrderId,
          category: TransactionCategory.Order,
          paymentMode: selectedPaymentMode,
          type: TransactionType.Revenue,
          currencyCode: currentStore.currencyCode,
        })
        togglePaymentConfirm()
        enqueueSnackbar({
          event: 'create_transaction',
          variant: 'success',
        })
        printOrder(currentStore, currentOrder, { ...transaction })
      } catch (e) {
        enqueueSnackbar({
          event: 'create_transaction',
          variant: 'error',
        })
      } finally {
        setPageLoading(false)
      }
    },
    [
      selectedOrderId,
      currentStore,
      currentOrder,
      selectedPaymentMode,
      togglePaymentConfirm,
      enqueueSnackbar,
      createTransactionMutation,
      setPageLoading,
    ]
  )

  const onCancelOrder = useCallback(async () => {
    try {
      setPageLoading(true)
      await cancelStoreOrderMutation()
      toggleCancelOrder()
      enqueueSnackbar({
        event: 'cancel_order',
        variant: 'success',
      })
    } catch (e) {
      enqueueSnackbar({
        event: 'cancel_order',
        variant: 'error',
      })
    } finally {
      setPageLoading(false)
    }
  }, [
    enqueueSnackbar,
    setPageLoading,
    cancelStoreOrderMutation,
    toggleCancelOrder,
  ])

  const onCancelOrderClick = useCallback(() => {
    toggleCancelOrder()
    toggleOrderDetail()
  }, [toggleCancelOrder, toggleOrderDetail])

  const onRowClick: GridEventListener<'rowClick'> = useCallback(
    (event) => {
      const { id } = event
      setSelectedOrderId(String(id))
      toggleOrderDetail()
    },
    [toggleOrderDetail]
  )

  return (
    <>
      <DataGrid
        hideFooter
        columns={columns}
        rows={filteredOrders}
        loading={isLoading}
        components={{
          NoRowsOverlay: NoOrderOverlay,
          NoResultsOverlay: NoOrderOverlay,
          LoadingOverlay: LinearProgress,
        }}
        onRowClick={onRowClick}
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }],
          },
        }}
        disableRowSelectionOnClick
        sx={{
          '& .MuiDataGrid-cell:hover': {
            cursor: 'pointer',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: ({ palette }) => alpha(palette.action.hover, 0.03),
          },
          borderWidth: 0,
        }}
      />
      <OrderDetailDialog
        open={isOrderDetailOpen}
        onClose={toggleOrderDetail}
        orderId={selectedOrderId}
        onPaymentClick={onPaymentClick}
        onCancelClick={onCancelOrderClick}
      />
      <PaymentSelectionDialog
        title={
          isChangingOrderPaymentMode
            ? t('views.pos.dialog.payment_selection.update_order.title')
            : undefined
        }
        open={isPaymentSelectionOpen}
        selectedPaymentMode={selectedPaymentMode}
        onChangePaymentMode={onPaymentSelectionChange}
        onClose={onPaymentSelectionClose}
        onConfirm={onPaymentSelectionConfirm}
      />
      <PaymentConfirmDialog
        open={isPaymentConfirmOpen}
        paymentMode={selectedPaymentMode}
        onClose={onPaymentSelectionConfirm}
        onConfirm={onConfirmPayment}
        order={currentOrder}
      />
      <ConfirmDialog
        open={isCancelOrderOpen}
        title={t('views.orders.dialog.cancel_confirm.title')}
        content={t('views.orders.dialog.cancel_confirm.content')}
        onConfirm={onCancelOrder}
        onClose={onCancelOrderClick}
      />
    </>
  )
}

export default OrderDataGrid
