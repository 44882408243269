import React, { useCallback, useMemo } from 'react'
import {
  Box,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import ConfirmDialog from '../ConfirmDialog'
import { CreateOrderDialogProps, CreateOrderNextStep } from './types'
import { StoreChannel, StoreOrderType } from '../../config/types'
import ChannelBadge from '../ChannelBadge'
import { isValidChannelReferenceId } from '../../utils/string'

const storeOrderTypes = Object.values(StoreOrderType)
const orderNextSteps = Object.values(CreateOrderNextStep)

function CreateOrderDialog(props: CreateOrderDialogProps) {
  const { open, onClose, onConfirm, onChange, channel, configValue } = props
  const isPos = channel === StoreChannel.PointOfSale
  const { orderType, nextStep, referenceId, notes } = configValue
  const { t } = useLocalization()

  const onChangeOrderType = useCallback(
    (_: React.MouseEvent, value: StoreOrderType) => {
      onChange({
        ...configValue,
        orderType: value,
      })
    },
    [configValue, onChange]
  )

  const onChangeNextStep = useCallback(
    (_: React.MouseEvent, value: CreateOrderNextStep) => {
      onChange({
        ...configValue,
        nextStep: value,
      })
    },
    [configValue, onChange]
  )

  const onChangeNotes = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...configValue,
        notes: event.target.value,
      })
    },
    [configValue, onChange]
  )

  const onChangeReferenceId = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange({
        ...configValue,
        referenceId: event.target.value,
      })
    },
    [onChange, configValue]
  )

  const isValidReferenceId = useMemo(() => {
    return !isPos ? isValidChannelReferenceId(referenceId || '') : true
  }, [isPos, referenceId])

  const isFormValid = useMemo(() => {
    return Boolean(orderType) && Boolean(nextStep) && isValidReferenceId
  }, [orderType, nextStep, isValidReferenceId])

  return (
    <ConfirmDialog
      title={t('views.pos.dialog.create_order.title')}
      content={t('views.pos.dialog.create_order.content')}
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmDisabled={!isFormValid}
    >
      <Box px={3}>
        <ChannelBadge channel={channel} />
        {isPos && (
          <>
            <Typography mt={2} mb={0.5}>
              {t('views.pos.dialog.create_order.order_type.header')}
            </Typography>
            <ToggleButtonGroup
              exclusive
              onChange={onChangeOrderType}
              color="info"
              value={orderType}
              orientation="horizontal"
              fullWidth
            >
              {storeOrderTypes.map((key) => (
                <ToggleButton disableRipple key={key} value={key}>
                  {t(`order.type.${key}`)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <Typography mt={2} mb={0.5}>
              {t('views.pos.dialog.create_order.payment.header')}
            </Typography>
            <ToggleButtonGroup
              exclusive
              onChange={onChangeNextStep}
              color="error"
              value={nextStep}
              orientation="horizontal"
              fullWidth
            >
              {orderNextSteps.map((key) => (
                <ToggleButton disableRipple key={key} value={key}>
                  {t(`views.pos.dialog.create_order.next_step.${key}`)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </>
        )}
        {!isPos && (
          <>
            <Typography mt={2} mb={0.5}>
              {t('views.pos.dialog.create_order.reference_id.header')}
            </Typography>
            <TextField
              fullWidth
              value={referenceId || ''}
              size="small"
              onChange={onChangeReferenceId}
              placeholder={t(
                'views.pos.dialog.create_order.reference_id.placeholder'
              )}
              error={!isValidReferenceId}
              helperText={
                !isValidReferenceId &&
                t('views.pos.dialog.create_order.reference_id.helper')
              }
            />
          </>
        )}
        <Typography mt={2} mb={0.5}>
          {t('views.pos.dialog.create_order.notes.header')}
        </Typography>
        <TextField
          fullWidth
          value={notes}
          size="small"
          multiline
          onChange={onChangeNotes}
          minRows={3}
          placeholder={t('views.pos.dialog.create_order.notes.placeholder')}
        />
      </Box>
    </ConfirmDialog>
  )
}

export default CreateOrderDialog
