import { DevicesOther, LockOutlined, Settings } from '@mui/icons-material'
import { SettingsNavigationRoutes, SettingsNavigationConfig } from './types'

export const settingsNavigationItems: SettingsNavigationConfig[] = [
  {
    key: SettingsNavigationRoutes.General,
    IconComponent: Settings,
  },
  {
    key: SettingsNavigationRoutes.Hardware,
    IconComponent: DevicesOther,
  },
  {
    key: SettingsNavigationRoutes.Security,
    IconComponent: LockOutlined,
  },
]
