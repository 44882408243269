import axios, { AxiosResponse } from 'axios'
import { joinUrlPath } from '../../utils/routing'
import { createHttpClient } from '../http'
import { HTTP_BASE_URL } from './config'
import { IS_HTTP_WITH_CREDENTIALS } from '../../config/constants'

const publicClient = axios.create({
  baseURL: HTTP_BASE_URL,
  withCredentials: IS_HTTP_WITH_CREDENTIALS,
})

const apiClient = axios.create({
  baseURL: HTTP_BASE_URL,
  withCredentials: IS_HTTP_WITH_CREDENTIALS,
})

export function setApiClientBaseRoute(orgId: string) {
  const path = joinUrlPath(HTTP_BASE_URL, `/orgs/${orgId}`)
  apiClient.defaults.baseURL = path
}

export function setApiClientToken(token: string, logoutCallback: () => void) {
  const initialHeaders = apiClient.defaults.headers.common
  apiClient.defaults.headers.common = {
    ...initialHeaders,
    Authorization: `Bearer ${token}`,
  }

  apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
      const response = error.response as AxiosResponse
      const statusCode = response.status
      if (statusCode === 401) {
        logoutCallback()
      }
      return Promise.reject(error)
    }
  )
}

export const publicApiClient = createHttpClient(publicClient)
export default createHttpClient(apiClient)
