import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import {
  SelectedStoreState,
  SelectedCategoryState,
  ItemSearchState,
  CurrentOrderState,
  OrderItem,
} from './types'
import { isOrderItemEqual } from '../../utils/order'

export const useSelectedStoreState = create<SelectedStoreState>()((set) => ({
  selectedStore: null,
  changeSelectedStore: (storeId: string) =>
    set(() => ({ selectedStore: storeId })),
}))

export const useSelectedCategoryState = create<SelectedCategoryState>()(
  (set) => ({
    selectedCategory: null,
    changeSelectedCategory: (categoryId: string) =>
      set(() => ({ selectedCategory: categoryId })),
  })
)

export const useItemSearchState = create<ItemSearchState>()((set) => ({
  searchText: null,
  changeSearchText: (searchText: string) => set(() => ({ searchText })),
}))

export const useCurrentOrderState = create(
  immer<CurrentOrderState>((set) => ({
    currentOrders: [],
    clearCurrentOrders: () =>
      set((state) => {
        state.currentOrders = []
      }),
    addOrder: (order: OrderItem) =>
      set((state) => {
        let matched = false
        state.currentOrders.forEach((currOrderItem) => {
          if (isOrderItemEqual(order, currOrderItem)) {
            currOrderItem.quantity += order.quantity
            matched = true
          }
        })

        if (!matched) {
          state.currentOrders.push(order)
        }
      }),
    removeOrder: (idx: number) =>
      set((state) => {
        state.currentOrders.splice(idx, 1)
      }),
    incrementOrderQuantity: (idx: number) =>
      set((state) => {
        state.currentOrders[idx].quantity += 1
      }),
    decrementOrderQuantity: (idx: number) =>
      set((state) => {
        const currQuantity = state.currentOrders[idx].quantity
        if (currQuantity <= 1) {
          // remove order if resulting quantity is 0
          state.currentOrders.splice(idx, 1)
        } else {
          state.currentOrders[idx].quantity -= 1
        }
      }),
  }))
)
