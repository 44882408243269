import { useMemo } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { PaymentMode } from '@tokoku-universe/react-core/common'
import queryClient from '../../config/query'
import { StoreChannel } from '../../config/types'
import {
  useCurrentOrderState,
  useItemSearchState,
  useSelectedCategoryState,
  useSelectedStoreState,
} from './store'
import {
  cancelOrder,
  createOrder,
  createTransaction,
  findActiveChannelCampaigns,
  findActiveChannelLoyalty,
  findAllStoreOrders,
  findAllStores,
  findLatestPosCatalog,
  findOneStore,
  findOrderById,
  updateOrder,
} from '.'
import { isRangeInThePast, isTodayInRange } from '../../utils/date'
import {
  ApiItem,
  ApiStore,
  ApiVariant,
  ApiVariantValue,
  CreateOrderDto,
  CreateTransactionDto,
  OrderItem,
} from './types'
import { calculateCurrentOrderItemPrice } from '../../utils/order'

const STORE_QUERY_KEY = 'stores'
const STORE_CATALOG_QUERY_KEY = 'storeCatalog'
const STORE_ACTIVE_CAMPAIGN_QUERY_KEY = 'storeActiveCampaign'
const STORE_ACTIVE_LOYALTY_QUERY_KEY = 'storeActiveLoyalty'
const STORE_ORDERS_QUERY_KEY = 'storeOrders'
const STORE_CACHE_TIME = 10 * 60 * 1000 // 10 minutes

export function useStoreList(staleTime = STORE_CACHE_TIME) {
  const selectedStore = useSelectedStoreState((state) => state.selectedStore)
  const changeSelectedStore = useSelectedStoreState(
    (state) => state.changeSelectedStore
  )

  const { isLoading, data: stores } = useQuery({
    queryKey: [STORE_QUERY_KEY],
    queryFn: async () => {
      const stores = await findAllStores({
        include: ['channels'],
      })

      // check if user has selected current store
      if (!selectedStore && stores.length > 0) {
        changeSelectedStore(stores[0].id)
      }

      return stores
    },
    staleTime,
  })

  return { isLoading, stores }
}

export function useStoreListById(staleTime = STORE_CACHE_TIME) {
  const { stores } = useStoreList(staleTime)

  const storeListById = useMemo<Record<string, ApiStore>>(() => {
    if (!stores) {
      return {}
    }

    return stores.reduce<Record<string, ApiStore>>((prev, curr) => {
      prev[curr.id] = curr
      return prev
    }, {})
  }, [stores])

  return storeListById
}

export function useStoreChannelList(storeId?: string) {
  const { stores } = useStoreList(Infinity)

  const storeChannels = useMemo(() => {
    if (!storeId || !stores) {
      return []
    }

    const store = stores.find(({ id }) => id === storeId)
    if (!store) {
      return []
    }

    return store.channels || []
  }, [stores, storeId])

  return storeChannels
}

export function useStoreById(storeId?: string) {
  const { isLoading, data: store } = useQuery({
    queryKey: [STORE_QUERY_KEY, storeId],
    queryFn: () => findOneStore(storeId || ''),
    enabled: Boolean(storeId),
    staleTime: Infinity,
  })

  return { isLoading, store }
}

export function useSelectedStore() {
  const selectedStore = useSelectedStoreState((state) => state.selectedStore)
  const { stores } = useStoreList()

  const store = useMemo(() => {
    if (!selectedStore || !stores) {
      return undefined
    }

    return stores.find(({ id }) => selectedStore === id)
  }, [stores, selectedStore])

  return store
}

export function useStoreActiveLoyalty(channel: StoreChannel) {
  const selectedStore = useSelectedStoreState((state) => state.selectedStore)
  const { isLoading, data: loyalty } = useQuery({
    queryKey: [STORE_ACTIVE_LOYALTY_QUERY_KEY, selectedStore, channel],
    queryFn: () =>
      selectedStore
        ? findActiveChannelLoyalty(selectedStore, channel)
        : Promise.resolve(null),
    staleTime: 60 * 60 * 1000, // 60 minutes
    retry: false,
  })

  return { isLoading, loyalty }
}

export function useStoreActiveCampaigns(channel: StoreChannel) {
  const selectedStore = useSelectedStoreState((state) => state.selectedStore)
  const { isLoading, data: campaigns } = useQuery({
    queryKey: [STORE_ACTIVE_CAMPAIGN_QUERY_KEY, selectedStore, channel],
    queryFn: () =>
      selectedStore
        ? findActiveChannelCampaigns(selectedStore, channel)
        : Promise.resolve(null),
    enabled: Boolean(selectedStore),
    staleTime: 60 * 60 * 1000, // 60 minutes
    retry: false,
  })

  return { isLoading, campaigns }
}

export function useLatestStoreCatalog(channel: StoreChannel) {
  const selectedStore = useSelectedStoreState((state) => state.selectedStore)
  const { isLoading, data } = useQuery({
    queryKey: [STORE_CATALOG_QUERY_KEY, selectedStore, channel],
    queryFn: async () => {
      if (!selectedStore) {
        return Promise.resolve(null)
      }

      const catalog = await findLatestPosCatalog(selectedStore, channel)
      const itemsById: Record<string, ApiItem> = {}
      const variantsById: Record<string, ApiVariant> = {}
      const variantValuesById: Record<string, ApiVariantValue> = {}
      catalog.categories?.forEach((category) => {
        category.items?.forEach((item) => {
          itemsById[item.id] = item
          item.base.variants?.forEach((variant) => {
            variantsById[variant.id] = variant
            variant.values?.forEach((variantValue) => {
              variantValuesById[variantValue.id] = variantValue
            })
          })
        })
      })

      return {
        catalog,
        itemsById,
        variantsById,
        variantValuesById,
      }
    },
    enabled: Boolean(selectedStore),
    staleTime: 60 * 60 * 1000, // 60 minutes
  })

  return { isLoading, ...data }
}

export function useCatalogItemList(channel: StoreChannel) {
  const { isLoading, catalog } = useLatestStoreCatalog(channel)
  const searchText = useItemSearchState((state) => state.searchText)
  const selectedCategory = useSelectedCategoryState(
    (state) => state.selectedCategory
  )

  const itemList = useMemo(() => {
    if (!catalog) {
      return []
    }

    return catalog.categories
      .map((category) => {
        if (!selectedCategory || category.id === selectedCategory) {
          return category.items || []
        }

        return []
      })
      .flat()
      .map((item) => ({
        ...item,
        name: item.name ?? item.base.name,
        price: item.price ?? item.base.price,
        description: item.description ?? item.base.description,
      }))
  }, [catalog, selectedCategory])

  const filteredItemList = useMemo(() => {
    if (!searchText) {
      return itemList
    }

    const cleanedSearch = searchText.toLocaleLowerCase().trim().split(' ')
    return itemList.filter(({ name }) => {
      return cleanedSearch.reduce((prev, curr) => {
        return (
          prev &&
          Boolean(name && name.trim().toLocaleLowerCase().includes(curr))
        )
      }, true)
    })
  }, [itemList, searchText])

  return { isLoading, items: filteredItemList }
}

function findStoreOrderQueryFunc(
  selectedStore: string | null,
  start: number,
  end: number
) {
  return () =>
    selectedStore
      ? findAllStoreOrders(selectedStore, {
          start: new Date(start).toISOString(),
          end: new Date(end).toISOString(),
        })
      : Promise.resolve(null)
}

export function useStoreOrderList(start: number, end: number) {
  const selectedStore = useSelectedStoreState((state) => state.selectedStore)
  const isPastDateRange = useMemo(() => {
    return isRangeInThePast(new Date(start), new Date(end))
  }, [start, end])
  const isRangeHasToday = useMemo(() => {
    return isTodayInRange(new Date(start), new Date(end))
  }, [start, end])

  const { isLoading: pastIsLoading, data: pastOrders } = useQuery({
    queryKey: [STORE_ORDERS_QUERY_KEY, selectedStore, start, end],
    queryFn: findStoreOrderQueryFunc(selectedStore, start, end),
    enabled: Boolean(selectedStore && isPastDateRange),
    staleTime: Infinity,
  })
  const { isLoading: todayIsLoading, data: todayOrders } = useQuery({
    queryKey: [STORE_ORDERS_QUERY_KEY, selectedStore, start, end],
    queryFn: findStoreOrderQueryFunc(selectedStore, start, end),
    enabled: Boolean(selectedStore && isRangeHasToday),
    refetchOnMount: true,
    staleTime: 1 * 60 * 1000, // 1 minute
  })

  return {
    isLoading: isPastDateRange ? pastIsLoading : todayIsLoading,
    orders: isPastDateRange ? pastOrders : todayOrders,
  }
}

export function useStoreOrderById(orderId: string) {
  const selectedStore = useSelectedStoreState((state) => state.selectedStore)
  const { isLoading, data: order } = useQuery({
    queryKey: [STORE_ORDERS_QUERY_KEY, selectedStore, orderId],
    queryFn: () =>
      selectedStore
        ? findOrderById(selectedStore, orderId)
        : Promise.resolve(null),
    enabled: Boolean(selectedStore && orderId),
    staleTime: Infinity,
  })

  return { isLoading, order }
}

export function useCreateTransactionMutation() {
  const selectedStore = useSelectedStoreState((state) => state.selectedStore)
  const mutation = useMutation({
    mutationFn: async (params: CreateTransactionDto) => {
      if (!selectedStore) {
        throw new Error('No store selected')
      }

      const transaction = await createTransaction(selectedStore, params)
      invalidateStoreOrderList()
      return transaction
    },
  })

  return mutation.mutateAsync
}

export function useCreateOrderMutation() {
  const selectedStore = useSelectedStoreState((state) => state.selectedStore)
  const mutation = useMutation({
    mutationFn: async (order: CreateOrderDto) => {
      if (!selectedStore) {
        throw new Error('No store selected')
      }

      const createdOrder = await createOrder(selectedStore, order)
      invalidateStoreOrderList()
      return createdOrder
    },
  })

  return mutation.mutateAsync
}

export function useCancelStoreOrderMutation(orderId: string) {
  const selectedStore = useSelectedStoreState((state) => state.selectedStore)
  const mutation = useMutation({
    mutationFn: async () => {
      if (!selectedStore) {
        throw new Error('No store selected')
      }

      const order = await cancelOrder(selectedStore, orderId)
      invalidateStoreOrderList()
      return order
    },
  })

  return mutation.mutateAsync
}

export function useUpdateOrderPaymentModeMutation(orderId: string) {
  const selectedStore = useSelectedStoreState((state) => state.selectedStore)
  const mutation = useMutation({
    mutationFn: async (paymentMode: PaymentMode) => {
      if (!selectedStore) {
        throw new Error('No store selected')
      }

      const order = await updateOrder(selectedStore, orderId, {
        paymentMode,
      })
      invalidateStoreOrderList()
      return order
    },
  })

  return mutation.mutateAsync
}

function addOrderItemDetail(
  orderItem: OrderItem,
  itemsById: Record<string, ApiItem>,
  variantsById: Record<string, ApiVariant>,
  variantValuesById: Record<string, ApiVariantValue>
) {
  const { itemId, variants, quantity, notes } = orderItem
  const item = itemsById[itemId]
  const variantValueList: ApiVariantValue[] = []
  const variantList = variants
    ? Object.entries(variants).reduce<ApiVariant[]>(
        (prev, [key, variantIds]) => {
          const variant = variantsById[key]
          prev.push({
            ...variant,
            values: variantIds.map((id) => {
              const variantValue = variantValuesById[id]
              variantValueList.push(variantValue)
              return variantValue
            }),
          })
          return prev
        },
        []
      )
    : []

  return {
    id: itemId,
    name: item?.name || item?.base.name,
    totalPrice: calculateCurrentOrderItemPrice(
      item,
      variantValueList,
      quantity
    ),
    variants: variantList,
    quantity,
    notes,
  }
}

export function useCurrentOrderInIndex(index: number, channel: StoreChannel) {
  const currentOrders = useCurrentOrderState((state) => state.currentOrders)
  const { itemsById, variantsById, variantValuesById } =
    useLatestStoreCatalog(channel)

  const order = useMemo(() => {
    const currOrder = currentOrders[index]
    if (!currOrder || !itemsById || !variantsById || !variantValuesById) {
      return undefined
    }
    return addOrderItemDetail(
      currentOrders[index],
      itemsById,
      variantsById,
      variantValuesById
    )
  }, [currentOrders, index, itemsById, variantsById, variantValuesById])

  return order
}

export function useCurrentOrders(channel: StoreChannel) {
  const currentOrders = useCurrentOrderState((state) => state.currentOrders)
  const { itemsById, variantsById, variantValuesById } =
    useLatestStoreCatalog(channel)

  const orders = useMemo(() => {
    if (!itemsById || !variantsById || !variantValuesById) {
      return []
    }

    return currentOrders.map((orderItem) => {
      return addOrderItemDetail(
        orderItem,
        itemsById,
        variantsById,
        variantValuesById
      )
    })
  }, [currentOrders, itemsById, variantsById, variantValuesById])

  return orders
}

export function invalidateStoreList() {
  return queryClient.invalidateQueries({ queryKey: [STORE_QUERY_KEY] })
}

export function invalidateStoreCatalog() {
  return queryClient.invalidateQueries({ queryKey: [STORE_CATALOG_QUERY_KEY] })
}

export function invalidateStoreOrderList() {
  return queryClient.invalidateQueries({ queryKey: [STORE_ORDERS_QUERY_KEY] })
}

export function invalidateStoreOrderById(orderId: string) {
  return queryClient.invalidateQueries({
    queryKey: [STORE_ORDERS_QUERY_KEY, orderId],
  })
}
