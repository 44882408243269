import { useMemo } from 'react'
import { Box, Typography, styled } from '@mui/material'
import { OrderChannelProps } from './types'
import { StoreChannel } from '../../../config/types'
import useTheming from '../../../utils/theme/hooks'
import { channelConfig } from '../../../config/channel'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { PointOfSaleOutlined } from '@mui/icons-material'

const Img = styled('img')({})

function OrderChannel(props: OrderChannelProps) {
  const { channel } = props
  const theme = useTheming()
  const { t } = useLocalization()

  const backgroundColor = useMemo(() => {
    const { palette } = theme

    if (!channel) {
      return palette.action.disabled
    }

    if (channel === StoreChannel.PointOfSale) {
      return palette.primary.dark
    }

    return channelConfig[channel].color || palette.action.disabled
  }, [channel, theme])

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height={30}
      p={1}
      sx={{ backgroundColor, borderRadius: 1 }}
    >
      {channel === StoreChannel.PointOfSale ? (
        <Box display="flex" alignItems="center">
          <PointOfSaleOutlined
            sx={{
              mr: 0.5,
              fontSize: ({ typography }) => typography.subtitle2.fontSize,
              color: ({ palette }) => palette.getContrastText(backgroundColor),
            }}
          />
          <Typography
            variant="subtitle2"
            sx={{
              color: ({ palette }) => palette.getContrastText(backgroundColor),
            }}
          >
            {t(`channel.label.${channel}`)}
          </Typography>
        </Box>
      ) : (
        <Img
          src={channel && channelConfig[channel].imageSrc}
          sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
        />
      )}
    </Box>
  )
}

export default OrderChannel
