import { useCallback, useState } from 'react'
import {
  Card,
  Box,
  Divider,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { PrintOutlined } from '@mui/icons-material'
import { usePrinterConnection } from '../../../services/printer/hooks'
import { useAppSnackbar } from '../../../utils/snackbar/hooks'

function HardwareSettings() {
  const { t } = useLocalization()
  const [printerConnectDisabled, setPrinterConnectDisabled] = useState(false)
  const [printerConnectLoading, setPrinterConnectLoading] = useState(false)
  const { connected, name, connect, disconnect } = usePrinterConnection()
  const { enqueueSnackbar } = useAppSnackbar()

  const onConnectionClick = useCallback(async () => {
    try {
      setPrinterConnectDisabled(true)
      setPrinterConnectLoading(true)
      if (connected) {
        disconnect()
      } else {
        await connect()
      }
    } catch (e) {
      enqueueSnackbar({
        event: 'connect_printer',
        variant: 'error',
      })
    } finally {
      setPrinterConnectLoading(false)
      setPrinterConnectDisabled(false)
    }
  }, [connected, disconnect, enqueueSnackbar, connect])

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 1 }}>
        {t('views.settings.hardware.heading.title')}
      </Typography>
      <Divider />
      <Typography sx={{ my: 2 }}>
        {t('views.settings.hardware.heading.description')}
      </Typography>
      <Card sx={{ p: 2, pb: 1.75 }}>
        <Box display="flex" alignItems="center">
          <PrintOutlined
            fontSize="small"
            sx={{ color: ({ palette }) => palette.text.secondary }}
          />
          <Typography flex={1} ml={1.5}>
            {t('views.settings.hardware.section.thermal_printer.label')}
          </Typography>
          <Typography
            sx={{
              color: ({ palette }) =>
                connected ? palette.success.main : palette.text.secondary,
            }}
          >
            {t(
              `views.settings.hardware.section.thermal_printer.connection.${connected}.label`
            )}
          </Typography>
        </Box>
        {name && (
          <Box display="flex" justifyContent="flex-end">
            <Typography color="textSecondary" mt={0.5}>
              {t(`views.settings.hardware.section.thermal_printer.name.label`, {
                name,
              })}
            </Typography>
          </Box>
        )}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          mt={1.5}
        >
          {printerConnectLoading && (
            <CircularProgress
              sx={{ mr: 1 }}
              size={20}
              color="info"
              variant="indeterminate"
            />
          )}
          <Button
            onClick={onConnectionClick}
            disabled={printerConnectDisabled}
            size="small"
            disableElevation
            variant="outlined"
            color={connected ? 'error' : 'primary'}
          >
            {t(connected ? 'button.label.disconnect' : 'button.label.connect')}
          </Button>
        </Box>
      </Card>
    </Box>
  )
}

export default HardwareSettings
