import { useMemo } from 'react'
import { Typography, Box, Unstable_Grid2 as Grid } from '@mui/material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import AttendanceCard from './DashboardShortcut/AttendanceCard'
import PrinterCard from './DashboardShortcut/PrinterCard'
import OrderCard from './DashboardShortcut/OrderCard'
import PosCard from './DashboardShortcut/PosCard'
import MotionViewContainer from '../../components/MotionViewContainer'
import { useAuthUser } from '../../services/auth/query'

function Home() {
  const { t } = useLocalization()
  const { user } = useAuthUser()

  const config = useMemo(() => {
    return [
      {
        key: 'attendance',
        CardComponent: AttendanceCard,
      },
      {
        key: 'pos',
        CardComponent: PosCard,
      },
      {
        key: 'printer',
        CardComponent: PrinterCard,
      },
      {
        key: 'order',
        CardComponent: OrderCard,
      },
    ]
  }, [])

  return (
    <MotionViewContainer maxWidth="lg">
      <Typography variant="h5" mt={3} mb={3}>
        {t('views.home.title', {
          name: user?.firstName,
        })}
      </Typography>
      <Box width="100%">
        <Grid container spacing={2}>
          {config.map(({ key, CardComponent }) => (
            <Grid xs={12} sm={6} md={4} lg={3} key={key}>
              <CardComponent />
            </Grid>
          ))}
        </Grid>
      </Box>
    </MotionViewContainer>
  )
}

export default Home
