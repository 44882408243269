import React, { useCallback, useContext } from 'react'
import { Box, Card, MenuItem, TextField, Typography } from '@mui/material'
import { ThemeContext } from '@tokoku-universe/react-core/components'
import { ThemePreference } from '@tokoku-universe/react-core/theme'
import {
  useLocalization,
  SupportedLocale,
} from '@tokoku-universe/react-core/localization'

const themeList = ['light', 'dark']
const languageList = Object.values(SupportedLocale)

function GeneralSettings() {
  const { t, currentLocale, changeLanguage } = useLocalization()
  const { changeTheme, currentTheme } = useContext(ThemeContext)

  const onChangeLanguage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const lang = event.target.value as SupportedLocale
      if (lang === currentLocale) {
        return
      }

      changeLanguage(lang)
    },
    [currentLocale, changeLanguage]
  )

  const onChangeTheme = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const theme = event.target.value as ThemePreference
      if (theme === currentTheme) {
        return
      }

      changeTheme(theme)
    },
    [currentTheme, changeTheme]
  )

  return (
    <Box>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 1.5,
          pt: 1,
        }}
      >
        <Box mb={1}>
          <Typography variant="body1">
            {t('views.settings.appearance.theme.heading.title')}
          </Typography>
          <Typography variant="body2" mb={1} color="textSecondary">
            {t('views.settings.appearance.theme.heading.description')}
          </Typography>
        </Box>
        <TextField
          InputProps={{ sx: { maxWidth: 200 } }}
          onChange={onChangeTheme}
          value={currentTheme}
          variant="outlined"
          size="small"
          select
        >
          {themeList.map((theme) => (
            <MenuItem key={theme} value={theme}>
              {t(`views.settings.appearance.theme.select.${theme}`)}
            </MenuItem>
          ))}
        </TextField>
      </Card>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 1.5,
          pt: 1,
          mt: 2,
        }}
      >
        <Box mb={1}>
          <Typography variant="body1">
            {t('views.settings.language.preference.heading.title')}
          </Typography>
          <Typography variant="body2" mb={1} color="textSecondary">
            {t('views.settings.language.preference.heading.description')}
          </Typography>
        </Box>
        <TextField
          InputProps={{ sx: { maxWidth: 250 } }}
          onChange={onChangeLanguage}
          value={currentLocale}
          variant="outlined"
          size="small"
          select
        >
          {languageList.map((lng) => (
            <MenuItem key={lng} value={lng}>
              {t(`views.settings.language.preference.select.${lng}`)}
            </MenuItem>
          ))}
        </TextField>
      </Card>
    </Box>
  )
}

export default GeneralSettings
