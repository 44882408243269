import { StorageKey, StoreChannel, StoreOrderStatus } from '../../config/types'
import apiClient, { publicApiClient } from '../api'
import { ApiLoyaltyProgram } from '../customers/types'
import { getItemFromStorage, setItemInStorage } from '../storage'
import {
  ApiStore,
  ApiCatalog,
  ApiOrder,
  ApiCampaign,
  CreateOrderDto,
  UpdateOrderDto,
  ApiTransaction,
  CreateTransactionDto,
  FindStoreOrderOptions,
  FindStoreOptions,
  FindStoreQueryDto,
  RegisterDeviceDto,
} from './types'

export function findAllStores(opts: FindStoreOptions = {}) {
  const path = '/stores'
  const query: FindStoreQueryDto = {
    include:
      opts?.include && opts.include.length > 0
        ? opts.include.join(',')
        : undefined,
  }
  return apiClient.get<ApiStore[]>(path, query).then((res) => res.data)
}

export function findOneStore(storeId: string) {
  const path = `/stores/${storeId}`
  return apiClient.get<ApiStore>(path).then((res) => res.data)
}

export function findLatestPosCatalog(storeId: string, channel: StoreChannel) {
  const path = `/stores/${storeId}/channels/${channel}/catalog/versions/latest`
  return apiClient.get<ApiCatalog>(path).then((res) => res.data)
}

export function createOrder(storeId: string, order: CreateOrderDto) {
  const path = `/stores/${storeId}/orders`
  return apiClient
    .post<ApiOrder, CreateOrderDto>(path, order)
    .then((res) => res.data)
}

export function findAllStoreOrders(
  storeId: string,
  opts?: Partial<FindStoreOrderOptions>
) {
  const path = `/stores/${storeId}/orders`
  return apiClient
    .get<ApiOrder[], FindStoreOrderOptions>(path, opts)
    .then((res) => res.data)
}

export function findOrderById(storeId: string, orderId: string) {
  const path = `/stores/${storeId}/orders/${orderId}`
  return apiClient.get<ApiOrder>(path).then((res) => res.data)
}

export function updateOrder(
  storeId: string,
  orderId: string,
  order: UpdateOrderDto
) {
  const path = `/stores/${storeId}/orders/${orderId}`
  return apiClient
    .put<ApiOrder, UpdateOrderDto>(path, order)
    .then((res) => res.data)
}

export function cancelOrder(storeId: string, orderId: string) {
  return updateOrder(storeId, orderId, {
    status: StoreOrderStatus.Cancelled,
  })
}

export function createTransaction(
  storeId: string,
  transaction: CreateTransactionDto
) {
  const path = `/stores/${storeId}/transactions`
  return apiClient
    .post<ApiTransaction, CreateTransactionDto>(path, transaction)
    .then((res) => res.data)
}

export function findActiveChannelLoyalty(
  storeId: string,
  channel: StoreChannel
) {
  const path = `/stores/${storeId}/channels/${channel}/loyalty`
  return apiClient.get<ApiLoyaltyProgram>(path).then((res) => res.data)
}

export function findActiveChannelCampaigns(
  storeId: string,
  channel: StoreChannel
) {
  const path = `/stores/${storeId}/channels/${channel}/campaigns`
  return apiClient.get<ApiCampaign[]>(path).then((res) => res.data)
}

export function registerStoreDevice(
  orgId: string,
  storeId: string,
  token: string
) {
  const path = `/orgs/${orgId}/stores/${storeId}/devices/${token}`
  return publicApiClient.post<RegisterDeviceDto, object>(path).then((res) => {
    const deviceId = res.data?.did
    setItemInStorage(StorageKey.DeviceIdentifier, deviceId)
    return deviceId
  })
}

export function isDeviceRegistered() {
  return getItemFromStorage(StorageKey.DeviceIdentifier)
}
