import { VariantSelectionType } from './types'

export function isMultiSelect(minSelection: number, maxSelection: number) {
  if (minSelection === 0 && maxSelection === 0) {
    return false
  }

  if (minSelection === 1 && maxSelection === 1) {
    return false
  }

  return true
}

export function getVariantSelectionType(
  minSelection: number,
  maxSelection: number
) {
  if (minSelection === 0 && maxSelection === 0) {
    return VariantSelectionType.Disabled
  }

  if (minSelection === 1 && maxSelection === 1) {
    return VariantSelectionType.Single
  }

  if (minSelection === 0 && maxSelection > 0) {
    return VariantSelectionType.UnequalMax
  }

  if (minSelection === maxSelection) {
    return VariantSelectionType.Eqaul
  }

  return VariantSelectionType.Unequal
}
