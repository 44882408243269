import { useState } from 'react'
import { Button, IconButton } from '@mui/material'
import { CampaignOutlined } from '@mui/icons-material'
import SelectCampaignDialog from '../SelectCampaignDialog'
import { useStoreActiveCampaigns } from '../../services/stores/query'
import { ActiveCampaignButtonProps } from './types'

const buttonHeight = 28

function ActiveCampaignButton(props: ActiveCampaignButtonProps) {
  const { variant = 'contained', channel, sx } = props
  const [isCampaignListDialogOpen, setIsCampaignListDialogOpen] =
    useState(false)
  const { campaigns } = useStoreActiveCampaigns(channel)

  const toggleCampaignListDialog = () => {
    setIsCampaignListDialogOpen((open) => !open)
  }

  if (!campaigns || campaigns.length <= 0) {
    return null
  }

  return (
    <>
      {variant === 'contained' ? (
        <Button
          onClick={toggleCampaignListDialog}
          color="warning"
          variant="contained"
          disableElevation
          sx={{
            height: buttonHeight,
            minWidth: buttonHeight,
            width: buttonHeight,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            ...sx,
          }}
        >
          <CampaignOutlined fontSize="small" />
        </Button>
      ) : (
        <IconButton sx={sx} onClick={toggleCampaignListDialog} size="small">
          <CampaignOutlined
            sx={{ color: ({ palette }) => palette.warning.main }}
            fontSize="small"
          />
        </IconButton>
      )}
      <SelectCampaignDialog
        open={isCampaignListDialogOpen}
        onClose={toggleCampaignListDialog}
        channel={channel}
        readOnly
      />
    </>
  )
}

export default ActiveCampaignButton
