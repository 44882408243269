import { Container } from '@mui/material'
import { Outlet } from 'react-router-dom'

function SharedLayout() {
  return (
    <Container
      maxWidth="xs"
      disableGutters
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'auto',
      }}
    >
      <Outlet />
    </Container>
  )
}

export default SharedLayout
