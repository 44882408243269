import { useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { Box, Stack, Toolbar } from '@mui/material'
import { useAuthUser } from '../../services/auth/query'
import { useIsOrderMode, useLoginRedirectPath } from '../../utils/routing/hooks'
import { ORDER_SIDEBAR_WIDTH } from './OrderSidebar/config'
import { useDeviceType } from '../../utils/device/hooks'
import Header from './Header'
import Sidebar from './Sidebar'
import OrderSidebar from './OrderSidebar'

function MainLayout() {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const redirectPath = useLoginRedirectPath()
  const isOrderMode = useIsOrderMode()
  const isDesktop = useDeviceType('desktop')
  const { user } = useAuthUser()
  const showOrderSidebar = isOrderMode && isDesktop

  const toggleDrawerOpen = () => {
    setDrawerOpen((open) => !open)
  }

  return user ? (
    <Stack width="100%" height="100vh" sx={{ whiteSpace: 'pre-line' }}>
      <Header toggleDrawerOpen={toggleDrawerOpen} />
      <Sidebar open={drawerOpen} toggleOpen={toggleDrawerOpen} />
      <Box
        component="main"
        width={
          showOrderSidebar ? `calc(100% - ${ORDER_SIDEBAR_WIDTH}px)` : '100%'
        }
        height="100vh"
        display="flex"
        flexDirection="column"
      >
        <Toolbar />
        <Outlet />
      </Box>
      {showOrderSidebar && <OrderSidebar />}
    </Stack>
  ) : (
    <Navigate to={redirectPath} replace />
  )
}

export default MainLayout
