import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { PaymentBadgeProps } from './types'
import { paymentModeSelectConfig } from '@tokoku-universe/react-core/components/PaymentModeSelect/config'
import { useLocalization } from '@tokoku-universe/react-core/localization'

function PaymentBadge(props: PaymentBadgeProps) {
  const { paymentMode, sx } = props
  const { t } = useLocalization()

  const config = useMemo(() => {
    if (!paymentMode) {
      return null
    }

    return paymentModeSelectConfig[paymentMode]
  }, [paymentMode])

  if (!config) {
    return <Typography>-</Typography>
  }

  return (
    <Box display="flex" alignItems="center" sx={sx}>
      {config.adornment}
      <Typography ml={0.75}>{t(`payment.type.${paymentMode}`)}</Typography>
    </Box>
  )
}

export default PaymentBadge
