import { SettingsNavigationRoutes } from '../SettingsNavigation/types'
import GeneralSettings from './GeneralSettings'
import HardwareSettings from './HardwareSettings'
import SecuritySettings from './SecuritySettings'
import { SettingsViewConfig } from './types'

export const settingsViewConfig: SettingsViewConfig = {
  [SettingsNavigationRoutes.General]: GeneralSettings,
  [SettingsNavigationRoutes.Hardware]: HardwareSettings,
  [SettingsNavigationRoutes.Security]: SecuritySettings,
}
