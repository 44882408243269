import axios from 'axios'
import { LOCATION_API_URL } from './config'
import { IpApiResponse } from './types'

export async function findCurrentCountryCode() {
  const response = await axios.get<IpApiResponse>(LOCATION_API_URL)
  const location = response.data
  return location.country_code
}

export async function getCurrentLocation() {
  return new Promise<{ lat: number; lng: number }>((resolve, reject) => {
    if (navigator.geolocation) {
      return navigator.geolocation.getCurrentPosition(
        ({ coords }) =>
          resolve({
            lat: coords.latitude,
            lng: coords.longitude,
          }),
        () => reject('location_error')
      )
    } else {
      return reject('location_not_supported')
    }
  })
}
