import { CssBaseline } from '@mui/material'
import {
  LocalizationProvider,
  ThemeProvider,
} from '@tokoku-universe/react-core/components'
import SnackbarProvider from './components/Snackbar/SnackbarProvider'
import ErrorBoundary from './components/ErrorBoundary'
import PageLoader from './components/PageLoader'
import { initSentry } from './services/sentry'
import Routes from './routes'

// init translations
import './locales'

initSentry()

function App() {
  return (
    <LocalizationProvider>
      <ThemeProvider>
        <ErrorBoundary>
          <SnackbarProvider>
            <CssBaseline />
            <Routes />
            <PageLoader />
          </SnackbarProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
