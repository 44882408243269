import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Card,
  Typography,
  Divider,
  Box,
  TextField,
} from '@mui/material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { useStoreActiveLoyalty } from '../../services/stores/query'
import { useCurrentCustomerState } from '../../services/customers/store'
import { LoyaltyProgramType } from '../../services/customers/types'
import { formatPrice } from '../../utils/string'
import { RedeemPointsDialogProps } from './types'

function isValidPointRedemption(
  currentPoints: number,
  value?: number,
  min?: number,
  max?: number
) {
  if (typeof value !== 'number' || value < 0) {
    return false
  }

  if (value === 0) {
    return true
  }

  const lowerBound = typeof min === 'number' ? min : 0
  const upperBound = typeof max === 'number' ? max : currentPoints

  return value >= lowerBound && value <= upperBound
}

function RedeemPointsDialog(props: RedeemPointsDialogProps) {
  const {
    open,
    channel,
    redemptionAmount,
    onChangeRedemptionAmount,
    onClose,
    onConfirm,
  } = props
  const { loyalty } = useStoreActiveLoyalty(channel)
  const currentCustomer = useCurrentCustomerState(
    (state) => state.currentCustomer
  )
  const minRedemptionAmount = loyalty?.redemption?.minPerRedemption
  const maxRedemptionAmount = loyalty?.redemption?.maxPerRedemption
  const currentCustomerLoyalty = currentCustomer?.loyalties?.find(
    ({ program }) => {
      return (
        program?.type === LoyaltyProgramType.Points &&
        program?.id === loyalty?.id
      )
    }
  )
  const currentPoints = currentCustomerLoyalty?.data.current || 0
  const { t } = useLocalization()

  const formik = useFormik({
    initialValues: {
      amount: redemptionAmount,
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .test({
          test: (amt) =>
            isValidPointRedemption(
              currentPoints,
              amt,
              minRedemptionAmount,
              maxRedemptionAmount
            ),
        })
        .required(),
    }),
    onSubmit: onConfirm,
    enableReinitialize: true,
    validateOnMount: true,
  })

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          {t('views.pos.dialog.loyalty_redemption.title')}
        </DialogTitle>
        <DialogContent>
          <Card>
            <Box p={2}>
              <Typography variant="h6">
                {t('views.pos.dialog.loyalty_redemption.customer.header')}
              </Typography>
              {currentCustomer?.name && (
                <Typography>{currentCustomer?.name}</Typography>
              )}
              {currentCustomer?.phoneNumber && (
                <Typography color="textSecondary">
                  {currentCustomer?.phoneNumber}
                </Typography>
              )}
            </Box>
            <Divider />
            <Box p={2}>
              <Typography variant="h6">
                {t('views.pos.dialog.add_customer.loyalty.points.header')}
              </Typography>
              <Typography fontSize={20} color="error.light" fontWeight={600}>
                {currentPoints}
              </Typography>
            </Box>
          </Card>
          {minRedemptionAmount || maxRedemptionAmount ? (
            <Card sx={{ mt: 2 }}>
              {minRedemptionAmount ? (
                <Box p={2}>
                  <Typography variant="h6">
                    {t(
                      'views.pos.dialog.loyalty_redemption.min_redemption.header'
                    )}
                  </Typography>
                  <Typography fontSize={20} color="info.light" fontWeight={600}>
                    {minRedemptionAmount}
                  </Typography>
                </Box>
              ) : null}
              {maxRedemptionAmount ? (
                <>
                  <Divider />
                  <Box p={2}>
                    <Typography variant="h6">
                      {t(
                        'views.pos.dialog.loyalty_redemption.max_redemption.header'
                      )}
                    </Typography>
                    <Typography
                      fontSize={20}
                      color="info.light"
                      fontWeight={600}
                    >
                      {maxRedemptionAmount}
                    </Typography>
                  </Box>
                </>
              ) : null}
            </Card>
          ) : null}
          <Typography mt={3} mb={0.5} fontWeight={600}>
            {t('views.pos.dialog.loyalty_redemption.redemption_amount.label')}
          </Typography>
          <TextField
            type="number"
            inputProps={{
              step: 1,
            }}
            value={formik.values.amount}
            onChange={(event) =>
              onChangeRedemptionAmount(Number(event.target.value))
            }
            fullWidth
          />
          <Typography variant="body2" mt={1}>
            {t(
              'views.pos.dialog.loyalty_redemption.redemption_amount.caption',
              {
                amount: formatPrice(
                  redemptionAmount * (loyalty?.redemption.valuePerPoint || 0)
                ),
              }
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('button.label.cancel')}</Button>
          <Button type="submit" variant="contained" disabled={!formik.isValid}>
            {t('button.label.next')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default RedeemPointsDialog
