import { useQuery, useMutation } from '@tanstack/react-query'
import queryClient from '../../config/query'
import { findAttendanceReport, recordAttendance } from '.'
import { findTodayDateRange } from '../../utils/date'
import {
  ApiAttendanceReport,
  AttendanceType,
  RecordAttendanceDto,
} from './types'

const USER_TODAY_ATTENDANCE_QUERY_KEY = 'userTodayAttendance'
const { start, end } = findTodayDateRange()

export function useUserTodayAttendance(storeId?: string, userId?: string) {
  const { isLoading, data: userList } = useQuery<ApiAttendanceReport[] | null>({
    queryKey: [USER_TODAY_ATTENDANCE_QUERY_KEY, storeId, userId],
    queryFn: () =>
      findAttendanceReport({
        employeeIds: userId ? [userId] : [],
        storeIds: storeId ? [storeId] : [],
        start: start.toISOString(),
        end: end.toISOString(),
      }),
    enabled: Boolean(storeId && userId),
    staleTime: Infinity,
  })

  const attendanceList = userList?.[0]?.attendances || []
  const hasInAttendance =
    attendanceList.find(({ type }) => {
      return type === AttendanceType.In
    }) || false
  const hasOutAttendance =
    attendanceList.find(({ type }) => type === AttendanceType.Out) || false

  const inOutAction: 'in' | 'out' = !hasInAttendance ? 'in' : 'out'
  const action: typeof inOutAction | 'none' =
    hasInAttendance && hasOutAttendance ? 'none' : inOutAction

  return {
    isLoading,
    hasInAttendance,
    hasOutAttendance,
    action,
  }
}

export function invalidateUserTodayAttendance() {
  return queryClient.invalidateQueries({
    queryKey: [USER_TODAY_ATTENDANCE_QUERY_KEY],
  })
}

export function useRecordAttendanceMutation(storeId?: string) {
  const mutation = useMutation({
    mutationFn: async (params: Omit<RecordAttendanceDto, 'storeId'>) => {
      if (!storeId) {
        return
      }
      await recordAttendance({
        storeId,
        type: params.type,
        latitude: params.latitude,
        longitude: params.longitude,
      })
      invalidateUserTodayAttendance()
    },
  })

  return mutation.mutateAsync
}
