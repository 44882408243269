import { Box, Paper, Stack } from '@mui/material'
import ItemList from './ItemList'
import CategoryTabs from './CategoryTabs'
import OrderFloatingButton from './OrderFloatingButton'
import { StoreChannel } from '../../config/types'
import ChannelBadge from '../../components/ChannelBadge'
import { PointOfSaleProps } from './types'

const CATEGORY_TABS_HEIGHT = 62

function PointOfSale(props: PointOfSaleProps) {
  const { channel = StoreChannel.PointOfSale, onChannelClick } = props

  return (
    <Stack flex={1} minHeight={0}>
      <Box flex={1} sx={{ overflowY: 'auto', pb: 7 }}>
        <ItemList channel={channel} />
      </Box>
      <Box position="relative">
        <Paper
          variant="outlined"
          sx={{
            position: 'absolute',
            bottom: {
              xs: -1.5,
              sm: -1.5,
              md: 0,
            },
            display: 'flex',
            alignItems: 'center',
            left: 0,
            right: 0,
            borderRadius: 0,
            p: 0.85,
            height: CATEGORY_TABS_HEIGHT,
          }}
        >
          <OrderFloatingButton channel={channel} />
          <ChannelBadge
            size="small"
            onClick={onChannelClick}
            channel={channel}
            sx={{
              mr: 1,
              cursor: 'pointer',
            }}
          />
          <CategoryTabs channel={channel} />
        </Paper>
      </Box>
    </Stack>
  )
}

export default PointOfSale
