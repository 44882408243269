import {
  ReceiptOutlined,
  DashboardOutlined,
  PointOfSaleOutlined,
  SettingsOutlined,
} from '@mui/icons-material'
import { RoutePath } from '../routes/types'
import { NavigationItem } from './types'

const navigationItems: NavigationItem[] = [
  {
    key: 'home',
    url: RoutePath.Home,
    IconComponent: DashboardOutlined,
    external: false,
  },
  {
    key: 'point_of_sale',
    url: RoutePath.PointOfSale,
    IconComponent: PointOfSaleOutlined,
    external: false,
  },
  {
    key: 'orders',
    url: RoutePath.Orders,
    IconComponent: ReceiptOutlined,
    external: false,
  },
  {
    key: 'settings',
    url: RoutePath.Settings,
    IconComponent: SettingsOutlined,
    external: false,
  },
]

export default navigationItems
