import { Box } from '@mui/material'
import { motion, AnimatePresence } from 'framer-motion'
import { SettingsViewProps } from './types'
import { settingsViewConfig } from './config'

function SettingsView(props: SettingsViewProps) {
  const { selected } = props
  const Component = settingsViewConfig[selected]

  return (
    <Box width="100%">
      {Component && (
        <AnimatePresence mode="wait">
          <motion.div
            key={selected}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0, transition: { duration: 0.7 } }}
            exit={{ opacity: 0, y: 10 }}
          >
            <Component />
          </motion.div>
        </AnimatePresence>
      )}
    </Box>
  )
}

export default SettingsView
