import { Box, Card, Typography } from '@mui/material'
import { PrintOutlined } from '@mui/icons-material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { usePrinterConnection } from '../../../services/printer/hooks'
import CardHeader from './DashCardHeader'

function PrinterCard() {
  const { connected, connect, disconnect } = usePrinterConnection()
  const { t } = useLocalization()

  return (
    <Card
      onClick={connected ? disconnect : connect}
      role="button"
      sx={{
        cursor: 'pointer',
        display: 'flex',
        p: 2,
        width: '100%',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <CardHeader
        label={t('views.home.card.printer.header')}
        color="secondary"
        IconComponent={PrintOutlined}
      />
      <Box pt={2} pb={1} display="flex" alignItems="center">
        <Typography
          sx={{
            color: ({ palette }) =>
              connected ? palette.success.light : palette.error.dark,
          }}
          mr={1}
          variant="h5"
        >
          {t(
            `views.settings.hardware.section.thermal_printer.connection.${connected}.label`
          )}
        </Typography>
      </Box>
      <Typography variant="subtitle2" color="textSecondary">
        {t(`views.home.card.printer.description.connect.${connected}`)}
      </Typography>
    </Card>
  )
}

export default PrinterCard
