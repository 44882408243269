import { useState, useCallback } from 'react'
import { Box, Button, Dialog, Typography } from '@mui/material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import OrderList from '../../../components/OrderList'
import ActiveCampaignButton from '../../../components/ActiveCampaignButton'
import { OrderFloatingButtonProps } from './types'
import ActiveLoyaltyButton from '../../../components/ActiveLoyaltyButton'

const buttonHeight = 28

const relativeBottom = 62
const relativeRight = 8

function OrderFloatingButton(props: OrderFloatingButtonProps) {
  const { channel } = props
  const [isOrderOpen, setIsOrderOpen] = useState(false)
  const { t } = useLocalization()

  const toggleOrderOpen = useCallback(() => {
    setIsOrderOpen((open) => !open)
  }, [])

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          bottom: relativeBottom,
          right: relativeRight,
          display: {
            xs: 'flex',
            sm: 'flex',
            md: 'none',
          },
          gap: 1,
        }}
      >
        <ActiveLoyaltyButton channel={channel} />
        <ActiveCampaignButton channel={channel} />
        <Button
          onClick={toggleOrderOpen}
          color="secondary"
          variant="contained"
          disableElevation
          sx={{
            height: buttonHeight,
            pt: 1.2,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <Typography
            variant="overline"
            lineHeight={1}
            letterSpacing={12}
            mr={-1.4}
            sx={{
              color: ({ palette }) =>
                palette.getContrastText(palette.info.dark),
            }}
          >
            <b>{t('views.orders.floating_button.label')}</b>
          </Typography>
        </Button>
      </Box>
      <Dialog open={isOrderOpen} onClose={toggleOrderOpen} fullScreen>
        <OrderList
          closeVisible
          channel={channel}
          onCloseClick={toggleOrderOpen}
        />
      </Dialog>
    </>
  )
}

export default OrderFloatingButton
