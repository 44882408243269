import { Box, Card, Unstable_Grid2 as Grid, Skeleton } from '@mui/material'

function ItemListLoader() {
  return (
    <Box width="100%" height="100%" pt={2} px={2}>
      <Grid container spacing={1.5} pb={3} disableEqualOverflow>
        {Array(10)
          .fill(1)
          .map((_, idx) => (
            <Grid key={String(idx)} xs={6} sm={4} md={4} lg={3} xl={2}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box height={100} width="100%">
                  <Skeleton variant="rectangular" width="100%" height="100%" />
                </Box>
                <Box
                  px={1.5}
                  pt={1.5}
                  pb={1}
                  display="flex"
                  flexDirection="column"
                  flex={1}
                >
                  <Box flex={1}>
                    <Skeleton variant="rectangular" />
                  </Box>
                  <Box pt={1} display="flex" justifyContent="flex-end">
                    <Skeleton variant="rectangular" width="50%" />
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}

export default ItemListLoader
