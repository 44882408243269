import { useCallback, useEffect, useRef, useState } from 'react'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import Webcam from 'react-webcam'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { useDeviceType } from '../../../utils/device/hooks'
import { getCurrentLocation } from '../../../services/location'
import { useAppSnackbar } from '../../../utils/snackbar/hooks'
import { AttendanceType } from '../../../services/attendance/types'
import { useRecordAttendanceMutation } from '../../../services/attendance/query'
import { usePageLoadingState } from '../../../services/ui/store'
import { RecordAttendanceDialogProps } from './types'

const videoConstraints: MediaTrackConstraints = {
  facingMode: 'user',
  aspectRatio: 1,
}

function RecordAttendanceDialog(props: RecordAttendanceDialogProps) {
  const { open, type, onClose, storeId } = props
  const webcamRef = useRef<Webcam>(null)
  const [mediaLoaded, setMediaLoaded] = useState(false)
  const [mediaError, setMediaError] = useState(false)
  const { t } = useLocalization()
  const isMobile = useDeviceType('mobile')
  const setPageLoading = usePageLoadingState((state) => state.setPageLoading)
  const recordAttendanceMutation = useRecordAttendanceMutation(storeId)
  const { enqueueSnackbar } = useAppSnackbar()

  useEffect(() => {
    setMediaError(false)
    setMediaLoaded(false)
  }, [open])

  const onCapture = useCallback(async () => {
    if (!webcamRef.current) {
      return
    }

    const eventType = type === AttendanceType.In ? 'clock_in' : 'clock_out'
    try {
      setPageLoading(true)
      const location = await getCurrentLocation()
      webcamRef.current.getScreenshot()
      await recordAttendanceMutation({
        type,
        longitude: location.lng,
        latitude: location.lat,
      })
      enqueueSnackbar({
        event: eventType,
        variant: 'success',
      })
      onClose()
    } catch (e) {
      enqueueSnackbar({
        event: eventType,
        variant: 'error',
      })
    } finally {
      setPageLoading(false)
    }
  }, [
    webcamRef,
    type,
    setPageLoading,
    enqueueSnackbar,
    recordAttendanceMutation,
    onClose,
  ])

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullScreen={isMobile}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>
        {t(`views.home.dialog.attendance.${type}.title`)}
      </DialogTitle>
      <DialogContent sx={{ minHeight: 420 }}>
        {mediaError ? (
          <Alert severity="error">
            {t('views.home.dialog.attendance.media_error')}
          </Alert>
        ) : (
          open && (
            <Webcam
              ref={webcamRef}
              audio={false}
              width="100%"
              height="100%"
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              onUserMedia={() => setMediaLoaded(true)}
              onUserMediaError={() => setMediaError(true)}
              style={{ borderRadius: 16 }}
            />
          )
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          size="large"
          variant="contained"
          color="error"
          fullWidth
        >
          {t('button.label.cancel')}
        </Button>
        <Button
          color={type === AttendanceType.In ? 'primary' : 'warning'}
          onClick={onCapture}
          size="large"
          variant="contained"
          disabled={mediaError || !mediaLoaded}
          fullWidth
        >
          {t(
            type === AttendanceType.In
              ? 'button.label.clock_in'
              : 'button.label.clock_out'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RecordAttendanceDialog
