import apiClient from '../api'
import { ApiCustomer, CreateCustomerDto, UpdateCustomerDto } from './types'

export function findCustomerByPhone(phone: string, loyaltyChannelId?: string) {
  const path = `/customers/${phone}`
  return apiClient
    .get<ApiCustomer>(path, {
      loyaltyChannelId,
    })
    .then((res) => res.data)
}

export function createCustomer(customer: CreateCustomerDto) {
  const path = '/customers'
  return apiClient
    .post<ApiCustomer, CreateCustomerDto>(path, customer)
    .then((res) => res.data)
}

export function updateCustomer(phone: string, customer: UpdateCustomerDto) {
  const path = `/customers/${phone}`
  return apiClient
    .put<ApiCustomer, UpdateCustomerDto>(path, customer)
    .then((res) => res.data)
}
