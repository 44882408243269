import EscPosEncoder from 'esc-pos-encoder'
import { translate } from '@tokoku-universe/react-core/localization'
import { formatReadableDateTime } from '../../utils/date'
import {
  ORDER_PRICE_WIDTH,
  ORDER_PRODUCT_WIDTH,
  ORDER_QTY_WIDTH,
  ORDER_SUMMARY_WIDTH,
  PRINTER_LINE_WIDTH,
  PRINTER_SERVICE_ID,
} from './config'
import { ApiOrder, ApiStore, ApiTransaction } from '../stores/types'
import {
  calculateOrderItemTotal,
  calculateTotalDiscount,
} from '../../utils/order'

let printerDevice: BluetoothDevice | null = null
let printerCharacteristic: BluetoothRemoteGATTCharacteristic | null = null
const redemptionBaseURL = import.meta.env.VITE_REDEMPTION_BASE_URL

function waitFor(milliseconds: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null)
    }, milliseconds)
  })
}

export function isConnectedToPrinter() {
  if (!printerDevice) {
    return false
  }

  return Boolean(printerDevice.gatt?.connected)
}

export function findPrinterName() {
  if (!printerDevice) {
    return undefined
  }

  return printerDevice.name
}

export async function connectBlePrinter() {
  if (printerDevice && printerCharacteristic) {
    return false
  }

  const bleDevice = await navigator.bluetooth.requestDevice({
    filters: [{ services: [PRINTER_SERVICE_ID] }],
  })

  if (!bleDevice.gatt) {
    throw new Error('Bluetooth device not found')
  }

  if (bleDevice.gatt.connected) {
    return false
  }

  const server = await bleDevice.gatt.connect()
  const services = await server.getPrimaryServices()

  if (services.length <= 0) {
    throw new Error('Bluetooth device is not supported')
  }

  const printerService = services[0]
  const characteristics = await printerService.getCharacteristics()

  if (characteristics.length <= 0) {
    throw new Error('Bluetooth device is not supported')
  }

  const characteristic = characteristics[0]
  printerCharacteristic = characteristic
  printerDevice = bleDevice
  return true
}

export function disconnectPrinter() {
  if (!printerDevice) {
    return
  }

  printerDevice.gatt?.disconnect()
  printerDevice = null
  printerCharacteristic = null
}

async function writeToPrinter(data: Uint8Array[]) {
  const requireConnect = await connectBlePrinter()
  if (requireConnect) {
    await waitFor(1000) // 3 seconds for printer to recognize
  }

  for (let i = 0; i < data.length; i += 1) {
    await printerCharacteristic?.writeValueWithoutResponse(data[i])
    await waitFor(250)
  }
}

function buildReceiptOrder(
  order: ApiOrder,
  transaction?: ApiTransaction | null
) {
  const bufferList = []
  const orderHeaderEncoder = new EscPosEncoder()
  const orderTableConfig: Parameters<typeof orderHeaderEncoder['table']>[0] = [
    { width: ORDER_QTY_WIDTH, align: 'right', marginRight: 1 },
    { width: ORDER_PRODUCT_WIDTH, align: 'center' },
    { width: ORDER_PRICE_WIDTH, align: 'right', marginLeft: 1 },
  ]
  const dividerText = new Array(PRINTER_LINE_WIDTH).fill('-').join('')
  const orderHeaderBuffer = orderHeaderEncoder
    .initialize()
    .newline()
    .line(dividerText)
    .table(orderTableConfig, [
      [
        translate('receipt.label.quantity'),
        translate('receipt.label.product'),
        translate('receipt.label.price'),
      ],
    ])
    .line(dividerText)
    .encode()
  bufferList.push(orderHeaderBuffer)

  order.items?.forEach((item, idx) => {
    const isLastItem = idx === order.items.length - 1
    const itemTotal = calculateOrderItemTotal(item)
    const variantList = item.variants || []

    const orderItemEncoder = new EscPosEncoder()
    orderItemEncoder.initialize().table(orderTableConfig, [
      [String(item.quantity), item.name, String(item.price)],
      ...variantList.map((variant) => {
        return ['', variant.name, String(variant.price)]
      }),
      ['', '', String(itemTotal)],
    ])
    if (!isLastItem) {
      orderItemEncoder.newline()
    }

    bufferList.push(orderItemEncoder.encode())
  })

  const orderSummaryTableConfig: Parameters<
    typeof orderHeaderEncoder['table']
  >[0] = [
    { width: ORDER_SUMMARY_WIDTH, align: 'right' },
    { width: ORDER_PRICE_WIDTH, align: 'right', marginLeft: 1 },
  ]

  const subtotalEncoder = new EscPosEncoder()
  const orderDiscount = calculateTotalDiscount(order)
  const loyaltyDiscount = order.loyaltyDiscount || 0
  const subtotalBuffer = subtotalEncoder
    .initialize()
    .line(dividerText)
    .table(orderSummaryTableConfig, [
      [translate('receipt.label.subtotal'), String(order.subtotal)],
      ...(orderDiscount
        ? [[translate('receipt.label.discount'), `-${orderDiscount}`]]
        : []),
      ...(loyaltyDiscount
        ? [[translate('receipt.label.loyalty_discount'), `-${loyaltyDiscount}`]]
        : []),
      [translate('receipt.label.tax'), String(order.tax)],
    ])
    .line(dividerText)
    .encode()
  bufferList.push(subtotalBuffer)

  const totalEncoder = new EscPosEncoder()
  totalEncoder
    .initialize()
    .table(orderSummaryTableConfig, [
      [translate('receipt.label.total'), String(order.total)],
    ])
    .line(dividerText)
  if (transaction) {
    totalEncoder
      .table(orderSummaryTableConfig, [
        [
          translate('receipt.label.received'),
          String(transaction.amountReceived || 0),
        ],
        [translate('receipt.label.change'), String(transaction.change || 0)],
      ])
      .line(dividerText)
  }
  totalEncoder.newline()
  bufferList.push(totalEncoder.encode())

  return bufferList
}

function buildReceiptHeader(
  store: ApiStore,
  order: ApiOrder,
  transaction?: ApiTransaction | null
) {
  const storeHeaderEncoder = new EscPosEncoder()
  const storeHeaderBuffer = storeHeaderEncoder
    .initialize()
    .newline()
    .align('center')
    .line(store.name)
    .align('center')
    .line([store.address, store.address2].filter(Boolean).join(', '))
    .newline()
    .encode()

  const orderHeaderEncoder = new EscPosEncoder()
  const orderHeaderBuffer = orderHeaderEncoder
    .initialize()
    .align('left')
    .bold(true)
    .line(
      translate('receipt.label.from_channel', { channel: order.channel.type })
    )
    .align('left')
    .line(translate('receipt.label.order_no', { number: order.shortId }))
    .align('left')
    .line(
      formatReadableDateTime(
        transaction ? transaction.createdAt : new Date().toISOString()
      )
    )
    .bold(false)
    .encode()

  return [storeHeaderBuffer, orderHeaderBuffer]
}

function buildReceiptFooter(transaction?: ApiTransaction | null) {
  const orderFooterEncoder = new EscPosEncoder()
  const orderFooterBuffer = orderFooterEncoder.initialize().align('center')

  if (typeof transaction?.order?.loyaltyPointsAccrued === 'number') {
    orderFooterBuffer
      .line(
        translate('receipt.label.loyalty_points_accrued', {
          count: transaction.order.loyaltyPointsAccrued,
        })
      )
      .newline()
  }

  if (transaction?.redemptionToken) {
    orderFooterBuffer
      .line(translate('receipt.label.loyalty'))
      .qrcode(`${redemptionBaseURL}/${transaction?.redemptionToken}`)
      .newline()
  }

  orderFooterBuffer
    .line(translate('receipt.label.footer'))
    .newline()
    .newline()
    .newline()

  return [orderFooterBuffer.encode()]
}

export async function printOrder(
  store: ApiStore,
  order: ApiOrder,
  transaction?: ApiTransaction | null
) {
  const receiptHeaderBuffers = buildReceiptHeader(store, order, transaction)
  const receiptOrderBuffers = buildReceiptOrder(order, transaction)
  const receiptFooterBuffers = buildReceiptFooter(transaction)

  await writeToPrinter([
    ...receiptHeaderBuffers,
    ...receiptOrderBuffers,
    ...receiptFooterBuffers,
  ])
}
