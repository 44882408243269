import { Box, Button } from '@mui/material'
import { Clear, Add, Remove } from '@mui/icons-material'
import { OrderItemActionsProps, StyledButtonProps } from './types'

function OrderActionButton(props: StyledButtonProps) {
  return (
    <Button
      sx={{
        padding: '3px',
        minWidth: 0,
        borderWidth: 2,
        borderRadius: 1.5,
        p: 0.2,
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          borderWidth: 2,
        },
        '&:focus': {
          boxShadow: 'none',
          borderWidth: 2,
        },
        ...props.sx,
      }}
      size="small"
      onClick={props.onClick}
      variant="outlined"
      color={props.color}
    >
      <props.IconComponent fontSize="small" />
    </Button>
  )
}

function OrderItemActions(props: OrderItemActionsProps) {
  const { onIncrement, onDecrement, onDelete } = props

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Box display="flex">
        <OrderActionButton
          onClick={onDecrement}
          color="warning"
          IconComponent={Remove}
        />
        <OrderActionButton
          onClick={onIncrement}
          color="success"
          IconComponent={Add}
          sx={{ ml: 1.5 }}
        />
      </Box>
      <OrderActionButton
        onClick={onDelete}
        color="error"
        IconComponent={Clear}
      />
    </Box>
  )
}

export default OrderItemActions
