import { useMemo, useCallback } from 'react'
import { useLocation, useMatch, useNavigate } from 'react-router-dom'
import { StoreChannel } from '../../config/types'
import { channelConfig } from '../../config/channel'
import { parseQueryString, stringifyQuery } from '.'
import { RoutePath } from '../../routes/types'

export function useSearchParams<T>(): Partial<T> {
  const { search } = useLocation()
  const searchParams = useMemo(() => {
    const params = new URLSearchParams(search)
    const arr = Array.from(params.entries())
    return arr.reduce((prev, curr) => {
      return Object.assign(prev, { [curr[0]]: curr[1] })
    }, {})
  }, [search])

  return searchParams as Partial<T>
}

export function useLoginRedirectPath() {
  const { pathname, search } = useLocation()
  const path = useMemo(() => {
    const query = stringifyQuery({
      redirect: pathname + search,
    })
    return `/login${query}`
  }, [pathname, search])
  return path
}

export function useIsOrderMode() {
  const { search } = useLocation()
  const matchPointOfSale = useMatch(RoutePath.PointOfSale)
  const matchCreateOrder = useMatch(RoutePath.CreateOrder)

  const isOrderMode = useMemo(() => {
    if (matchPointOfSale) {
      return true
    }

    if (!matchCreateOrder) {
      return false
    }

    const query = parseQueryString(search)
    const channel = query.channel as StoreChannel
    if (typeof channel !== 'string') {
      return false
    }

    return Boolean(channel && channelConfig[channel]?.enabled)
  }, [matchPointOfSale, matchCreateOrder, search])

  return isOrderMode
}

export function useQueryNavigation<T>(): [
  Partial<T>,
  (params: Partial<T>) => void
] {
  const query = useSearchParams<T>()
  const navigate = useNavigate()

  const modifyQuery = useCallback(
    (params: Partial<T>) => {
      const currentQuery = {
        ...query,
        ...params,
      }
      const qstring = stringifyQuery(currentQuery)
      navigate(qstring)
    },
    [query, navigate]
  )

  return [query, modifyQuery]
}
