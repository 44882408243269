import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'
import { formatPrice } from '../../utils/string'
import { formatReadableDateRange } from '../../utils/date'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import {
  DiscountCampaignConfig,
  ProductDiscountCampaignConfig,
} from '../../services/stores/types'
import { useProductListById } from '../../services/products/query'
import { DiscountCampaignDescriptionProps } from './types'

function DiscountCampaignDescription(props: DiscountCampaignDescriptionProps) {
  const { config, start, end } = props
  const discountOrderConfig = config as DiscountCampaignConfig
  const discountProductConfig = config as ProductDiscountCampaignConfig
  const { productIds } = discountProductConfig
  const { minOrderAmount, maxOrderAmount } = discountOrderConfig
  const { type, scope, amount, paymentModes } = config
  const { t } = useLocalization()
  const productListById = useProductListById()

  const dateRange = useMemo(() => {
    return formatReadableDateRange(new Date(start), new Date(end))
  }, [start, end])

  const supportedPayments = useMemo(() => {
    let paymentModesText = t(
      'views.pos.dialog.campaign_list.discount.payment_modes.none'
    )

    if (!Array.isArray(paymentModes)) {
      paymentModesText = t(
        'views.pos.dialog.campaign_list.discount.payment_modes.all'
      )
    } else if (paymentModes.length <= 0) {
      paymentModesText = t(
        'views.pos.dialog.campaign_list.discount.payment_modes.none'
      )
    } else {
      paymentModesText = paymentModes.reduce<string>((prev, curr, idx) => {
        const mode = t(`payment.type.${curr}`)
        const next = idx !== 0 ? `, ${mode}` : mode
        return `${prev}${next}`
      }, '')
    }

    return (
      <Typography>
        {t('views.pos.dialog.campaign_list.discount.payment_modes', {
          modes: paymentModesText,
        })}
      </Typography>
    )
  }, [t, paymentModes])

  return (
    <>
      <Typography color="info.light">
        {t(`views.pos.dialog.campaign_list.discount.amount.${type}`, {
          amount: formatPrice(amount),
          scope: t(`discount.scope.${scope}`),
        })}
      </Typography>
      {productIds?.length > 0 && (
        <Box
          component="p"
          mt={0.2}
          sx={{ lineHeight: 1, paddingInlineStart: 2, marginBlockEnd: 1 }}
        >
          {productIds?.map((id) => {
            const product = productListById[id]
            return (
              product && (
                <Typography key={id} color="warning.main" variant="body2">
                  {product.name}
                </Typography>
              )
            )
          })}
        </Box>
      )}
      {typeof minOrderAmount === 'number' && (
        <Typography>
          {t('views.pos.dialog.campaign_list.discount.min_order_amount', {
            amount: formatPrice(minOrderAmount),
          })}
        </Typography>
      )}
      {typeof maxOrderAmount === 'number' && (
        <Typography>
          {t('views.pos.dialog.campaign_list.discount.max_order_amount', {
            amount: formatPrice(maxOrderAmount),
          })}
        </Typography>
      )}
      {supportedPayments}
      <Typography>{dateRange}</Typography>
    </>
  )
}

export default DiscountCampaignDescription
