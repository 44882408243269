import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Typography } from '@mui/material'
import { ReceiptOutlined } from '@mui/icons-material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import CardHeader from './DashCardHeader'
import { StoreOrderStatus } from '../../../config/types'
import { findTodayDateRange } from '../../../utils/date'
import { RoutePath } from '../../../routes/types'
import { useStoreOrderList } from '../../../services/stores/query'

const { start, end } = findTodayDateRange()

function OrderCard() {
  const { t } = useLocalization()
  const { orders: orderList } = useStoreOrderList(
    start.getTime(),
    end.getTime()
  )
  const navigate = useNavigate()

  const { total, pending } = useMemo(() => {
    if (!orderList) {
      return {
        total: 0,
        pending: 0,
        completed: 0,
      }
    }

    return orderList.reduce(
      (prev, curr) => {
        prev.total += 1

        if (curr.status === StoreOrderStatus.Complete) {
          prev.completed += 1
        }

        if (curr.status === StoreOrderStatus.Pending) {
          prev.pending += 1
        }

        return prev
      },
      {
        completed: 0,
        pending: 0,
        total: 0,
      }
    )
  }, [orderList])

  const onCardClick = () => {
    navigate(RoutePath.Orders)
  }

  return (
    <Card
      onClick={onCardClick}
      role="button"
      sx={{
        cursor: 'pointer',
        display: 'flex',
        p: 2,
        height: '100%',
        flexDirection: 'column',
        textDecoration: 'none',
      }}
    >
      <CardHeader
        label={t('views.home.card.order.header')}
        IconComponent={ReceiptOutlined}
        color="error"
      />
      <Typography mt={2} mb={1} variant="h5">
        {total}
        <Typography
          ml={1}
          display="inline"
          fontSize="1.25rem"
          sx={{ color: ({ palette }) => palette.warning.main }}
        >
          {t('views.home.card.order.label.pending', {
            count: pending,
          })}
        </Typography>
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        {t('views.home.card.order.timestamp')}
      </Typography>
    </Card>
  )
}

export default OrderCard
