import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { ViewBackButtonProps } from './types'

function ViewBackButton(props: ViewBackButtonProps) {
  const { onClick, sx } = props
  const navigate = useNavigate()

  const onBackClick = useCallback(() => {
    if (onClick) {
      onClick()
      return
    }

    navigate(-1)
  }, [navigate, onClick])

  return (
    <IconButton
      sx={{
        mr: 2,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 1,
        borderColor: ({ palette }) => palette.action.disabled,
        ...sx,
      }}
      onClick={onBackClick}
      size="small"
    >
      <ArrowBack fontSize="small" />
    </IconButton>
  )
}

export default ViewBackButton
