import { Box, styled } from '@mui/material'
import { channelConfig } from '../../config/channel'
import { StoreChannel } from '../../config/types'
import { ChannelBadgeProps } from './types'

const Img = styled('img')({})

const badgeConfig = {
  small: {
    width: 112,
    height: 34,
  },
  medium: {
    width: 140,
    height: 40,
  },
}

function ChannelBadge(props: ChannelBadgeProps) {
  const { channel, onClick, size = 'medium', sx } = props

  if (channel === StoreChannel.PointOfSale) {
    return null
  }

  const { height, width } = badgeConfig[size]

  return (
    <Box
      height={height}
      minWidth={width}
      width={width}
      borderRadius={2}
      onClick={onClick}
      p={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: channelConfig[channel].color,
        ...sx,
      }}
    >
      <Img
        src={channelConfig[channel].imageSrc}
        width="100%"
        height="100%"
        sx={{ objectFit: 'contain' }}
      />
    </Box>
  )
}

export default ChannelBadge
