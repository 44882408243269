import { SvgIconComponent } from '@mui/icons-material'

export interface SettingsNavigationConfig {
  key: SettingsNavigationRoutes
  IconComponent?: SvgIconComponent
}

export interface SettingsNavigationProps {
  selected: SettingsNavigationRoutes
  onChange: (route: SettingsNavigationRoutes) => void
}

export enum SettingsNavigationRoutes {
  General = 'general',
  Hardware = 'hardware',
  Security = 'security',
}
