import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  styled,
} from '@mui/material'
import {
  SupportedLocale,
  useLocalization,
} from '@tokoku-universe/react-core/localization'
import { FastokoLogo } from '@tokoku-universe/react-core/components'
import { Link, NavLink } from 'react-router-dom'
import { FeedbackOutlined, LogoutOutlined } from '@mui/icons-material'
import { useLogoutMutation, useAuthUser } from '../../../services/auth/query'
import { SIDEBAR_WIDTH } from './config'
import navigationItems from '../../../navigation'
import { RoutePath } from '../../../routes/types'
import NavItem from './NavItem'
import { SidebarProps } from './types'

const StyledNavLink = styled(NavLink)({ textDecoration: 'none' })
const feedbackLink: Record<SupportedLocale, string> = {
  [SupportedLocale.English]:
    'https://g5bq4d9xlej.sg.larksuite.com/share/base/form/shrlg6Vd4ZmYoCLVUoQxvDthbOb',
  [SupportedLocale.Indonesian]:
    'https://g5bq4d9xlej.sg.larksuite.com/share/base/form/shrlgVSqpqVq63McrqxdBVMMkqh',
}

function Sidebar(props: SidebarProps) {
  const { open, toggleOpen } = props
  const { currentLocale } = useLocalization()
  const { user } = useAuthUser()
  const logoutMutation = useLogoutMutation()
  const { t } = useLocalization()

  return (
    <Drawer
      open={open}
      anchor="left"
      onClose={toggleOpen}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: SIDEBAR_WIDTH,
        },
      }}
    >
      <Toolbar
        sx={{
          paddingLeft: { xs: 3 },
          paddingRight: { xs: 1.5 },
          display: 'flex',
          gap: 1.15,
          flexDirection: 'row',
          alignItems: 'center',
          '& .MuiToolbar-root': {
            borderBottomColor: 'white',
            borderBottomWidth: 1,
          },
          textDecoration: 'none',
          color: ({ palette }) => palette.text.primary,
        }}
        component={Link}
        to={RoutePath.Base}
        onClick={toggleOpen}
      >
        <FastokoLogo width={28} height={28} />
        <Typography variant="h5" mt={0.4}>
          {t('app.name')}
          <Typography
            variant="overline"
            color="primary.main"
            ml={0.9}
            fontWeight="bold"
          >
            {t('app.function')}
          </Typography>
        </Typography>
      </Toolbar>
      <Divider />
      <Box
        height="100%"
        pt={2}
        display="flex"
        flexDirection="column"
        sx={{ overflowY: 'auto' }}
      >
        {navigationItems.map(({ key, url, IconComponent }) => (
          <StyledNavLink key={key} onClick={toggleOpen} to={url}>
            {({ isActive }) => (
              <NavItem
                titleKey={key}
                active={isActive}
                IconComponent={IconComponent}
              />
            )}
          </StyledNavLink>
        ))}
      </Box>
      <StyledNavLink to={feedbackLink[currentLocale]} target="_blank">
        <NavItem
          titleKey="feedback"
          active={false}
          IconComponent={FeedbackOutlined}
          external
        />
      </StyledNavLink>
      {user && (
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          p={3}
          pr={1.5}
          sx={{
            borderTop: ({ palette }) => `1px solid ${palette.divider}`,
          }}
          title={user?.email}
        >
          <Avatar
            sx={{ width: 40, height: 40 }}
            src="https://www.odysseyhouse.com.au/wordpress/wp-content/uploads/2018/02/cute-kitten-SQUARE@.jpg"
          />
          <Stack gap={1} flex={1}>
            <Typography variant="subtitle2" lineHeight={1}>
              {[user.firstName, user.lastName].filter(Boolean).join(' ')}
            </Typography>
            <Typography
              variant="caption"
              textTransform="uppercase"
              color="primary.main"
              fontWeight="bold"
              lineHeight={1}
            >
              {user.org?.name}
            </Typography>
          </Stack>
          <IconButton
            title={t('drawer.item.title.logout')}
            onClick={() => logoutMutation()}
          >
            <LogoutOutlined fontSize="small" />
          </IconButton>
        </Stack>
      )}
    </Drawer>
  )
}

export default Sidebar
