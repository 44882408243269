import { useCallback, useMemo } from 'react'
import { Avatar, Box, Typography, Unstable_Grid2 as Grid } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import SettingsNavigation from './SettingsNavigation'
import { SettingsNavigationRoutes } from './SettingsNavigation/types'
import SettingsView from './SettingsView'
import MotionViewContainer from '../../components/MotionViewContainer'
import { useAuthUser } from '../../services/auth/query'
import NotFound from '../NotFound'
import { SettingsParams } from './types'

const validSettingsList = Object.values(SettingsNavigationRoutes)

function Settings() {
  const { item } = useParams<SettingsParams>()
  const navigate = useNavigate()
  const { user } = useAuthUser()
  const selectedItem = item || SettingsNavigationRoutes.General

  const onNavigationChange = useCallback(
    (route: SettingsNavigationRoutes) => {
      navigate(`/settings/${route}`)
    },
    [navigate]
  )

  const isItemValid = useMemo(() => {
    if (item && !validSettingsList.includes(item)) {
      return false
    }

    return true
  }, [item])

  return isItemValid ? (
    <MotionViewContainer maxWidth="lg" sx={{ py: 3 }}>
      {user && (
        <Box display="flex" mb={3} alignItems="center">
          <Avatar
            sx={{ width: 48, height: 48, mr: 2 }}
            src="https://www.odysseyhouse.com.au/wordpress/wp-content/uploads/2018/02/cute-kitten-SQUARE@.jpg"
          />
          <Box>
            <Typography>{`${user.firstName} ${user.lastName}`}</Typography>
            <Typography variant="body2" color="textSecondary">
              {user.email}
            </Typography>
          </Box>
        </Box>
      )}
      <Grid container spacing={3} pb={3}>
        <Grid xs={12} sm={12} md={3}>
          <SettingsNavigation
            onChange={onNavigationChange}
            selected={selectedItem}
          />
        </Grid>
        <Grid xs={12} sm={12} md={9}>
          <SettingsView selected={selectedItem} />
        </Grid>
      </Grid>
    </MotionViewContainer>
  ) : (
    <NotFound />
  )
}

export default Settings
