import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { useStoreActiveLoyalty } from '../../services/stores/query'
import { LoyaltyProgramType } from '../../services/customers/types'
import { LoyaltyDetailDialogProps } from './types'
import { formatPrice } from '../../utils/string'

function LoyaltyDetailDialog(props: LoyaltyDetailDialogProps) {
  const { open, channel, onClose } = props
  const { t } = useLocalization()
  const { loyalty } = useStoreActiveLoyalty(channel)

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        {loyalty?.name || t('views.pos.dialog.loyalty_list.title')}
      </DialogTitle>
      <DialogContent>
        {loyalty && loyalty.type === LoyaltyProgramType.Points && (
          <>
            <Typography variant="h6">
              {t('views.pos.dialog.loyalty_list.points.accrual_title')}
            </Typography>
            <Typography variant="body1" color="success.main">
              {t('views.pos.dialog.loyalty_list.points.accrual_amount', {
                amount: formatPrice(loyalty.accrual.valuePerPoint),
              })}
            </Typography>
            {loyalty.accrual.minPerAccrual && (
              <Typography variant="body2" color="textSecondary">
                {t('views.pos.dialog.loyalty_list.points.accrual_min', {
                  amount: formatPrice(
                    loyalty.accrual.valuePerPoint *
                      loyalty.accrual.minPerAccrual
                  ),
                })}
              </Typography>
            )}
            {loyalty.accrual.maxPerAccrual && (
              <Typography variant="body2" color="textSecondary">
                {t('views.pos.dialog.loyalty_list.points.accrual_max', {
                  amount: formatPrice(
                    loyalty.accrual.valuePerPoint *
                      loyalty.accrual.maxPerAccrual
                  ),
                })}
              </Typography>
            )}
            <Typography variant="h6" mt={2}>
              {t('views.pos.dialog.loyalty_list.points.redemption_title')}
            </Typography>
            <Typography variant="body1" color="success.main">
              {t('views.pos.dialog.loyalty_list.points.redemption_amount', {
                amount: formatPrice(loyalty.redemption.valuePerPoint),
              })}
            </Typography>
            {loyalty.redemption.minPerRedemption && (
              <Typography variant="body2" color="textSecondary">
                {t('views.pos.dialog.loyalty_list.points.redemption_min', {
                  amount: formatPrice(loyalty.redemption.minPerRedemption),
                })}
              </Typography>
            )}
            {loyalty.redemption.maxPerRedemption && (
              <Typography variant="body2" color="textSecondary">
                {t('views.pos.dialog.loyalty_list.points.redemption_max', {
                  amount: formatPrice(loyalty.redemption.maxPerRedemption),
                })}
              </Typography>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" disableElevation>
          {t('button.label.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LoyaltyDetailDialog
