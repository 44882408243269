import { StoreCountry, StoreCurrency } from '../../config/types'
import { ApiStoreChannel } from '../stores/types'

export interface CreateCustomerDto {
  phoneNumber: string
  name: string
  address?: string
  city?: string
  countryCode?: StoreCountry
}

export interface UpdateCustomerDto {
  name?: string
  address?: string
  city?: string
  countryCode?: StoreCountry
}

export interface ApiCustomer {
  id: string
  orgId: string
  phoneNumber: string
  name?: string
  address?: string
  city?: string
  loyalties?: ApiCustomerLoyalty[]
  countryCode?: StoreCountry
  version?: number
  isDeleted: boolean
  createdAt: string
  createdBy: string
  updateAt: string
  updatedBy: string
}

export interface CurrentCustomerState {
  currentCustomer: ApiCustomer | null
  changeCurrentCustomer: (customer: ApiCustomer | null) => void
}

export interface ApiPointsData {
  lastAccrued: number
  accrued: number
  lastRedeemed: number
  redeemed: number
  current: number
}

export interface ApiBaseLoyaltyProgram {
  id: string
  orgId: string
  name: string
  description: string
  currencyCode: StoreCurrency
  isActive: boolean
  createdAt: string
  updatedAt: string
  channels?: ApiStoreChannel[]
}

export enum LoyaltyProgramType {
  Points = 'POINTS',
}

export enum PointsLoyaltyRedemptionType {
  Discount = 'DISCOUNT',
}

export interface ApiPointsLoyaltyProgram {
  type: LoyaltyProgramType.Points
  accrual: {
    valuePerPoint: number
    minPerAccrual?: number
    maxPerAccrual?: number
  }
  redemption: {
    type: PointsLoyaltyRedemptionType
    valuePerPoint: number
    minPerRedemption?: number
    maxPerRedemption?: number
  }
}

export type ApiLoyaltyProgram = ApiBaseLoyaltyProgram & ApiPointsLoyaltyProgram

export interface ApiCustomerLoyalty {
  orgId: string
  customerId: string
  loyaltyId: string
  data: ApiPointsData
  program?: ApiLoyaltyProgram
}
