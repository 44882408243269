import { ApiUser } from '../auth/types'

export enum AttendanceType {
  In = 'IN',
  Out = 'OUT',
}

export interface RecordAttendanceDto {
  storeId: string
  type: AttendanceType
  latitude: number
  longitude: number
}

export interface ApiAttendance {
  orgId: string
  storeId: string
  userId: string
  deviceId?: string
  mediaId?: string
  type: AttendanceType
  latitude: number
  longitude: number
  notes?: string
}

export type ApiAttendanceReport = ApiUser & {
  attendances: ApiAttendance[]
}

export interface FindAttendanceQueryOptions {
  employeeIds?: string[]
  storeIds?: string[]
  start?: string
  end?: string
}

export interface FindAttendanceQueryDto {
  employeeIds?: string
  storeIds?: string
  start?: string
  end?: string
}
