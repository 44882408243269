import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { useLocalization } from '@tokoku-universe/react-core/localization'
import { getVariantSelectionType, isMultiSelect } from './utils'
import VariantSelection from './VariantSelection'
import { ApiVariant } from '../../../services/stores/types'
import { VariantSelectDialogProps } from './types'

function VariantSelectDialog(props: VariantSelectDialogProps) {
  const { item, open, onClose, onConfirm } = props
  const { t } = useLocalization()
  const variantList = item?.base.variants
  const [notes, setNotes] = useState('')
  const [selectedVariants, setSelectedVariants] = useState<
    Record<string, string[]>
  >({})

  const variantGroupsById = useMemo(() => {
    if (!variantList) {
      return {}
    }

    return variantList.reduce<Record<string, ApiVariant>>((prev, curr) => {
      prev[curr.id] = curr
      return prev
    }, {})
  }, [variantList])

  useEffect(() => {
    if (!variantList) {
      return
    }

    setSelectedVariants(
      variantList.reduce<Record<string, string[]>>(
        (prev, curr) => ({
          ...prev,
          [curr.id]: [],
        }),
        {}
      )
    )
  }, [variantList])

  const onCloseDialog = useCallback(() => {
    onClose()
    setSelectedVariants({})
  }, [onClose])

  const onConfirmClick = useCallback(() => {
    if (!item) {
      return
    }

    onConfirm(item, selectedVariants, notes || undefined)
    onCloseDialog()
  }, [onConfirm, item, selectedVariants, notes, onCloseDialog])

  const onVariantSelect = useCallback(
    (groupId: string, variantId: string) => {
      const variantGroup = variantGroupsById[groupId]
      const { minSelection, maxSelection } = variantGroup

      const isSingleSelect = !isMultiSelect(minSelection, maxSelection)

      if (isSingleSelect) {
        setSelectedVariants((currSelectedVariants) => ({
          ...currSelectedVariants,
          [groupId]: [variantId],
        }))
        return
      }

      const currVariantSet = new Set(selectedVariants[groupId])
      if (currVariantSet.has(variantId)) {
        currVariantSet.delete(variantId)
      } else {
        currVariantSet.add(variantId)
      }

      setSelectedVariants((currSelectedVariants) => ({
        ...currSelectedVariants,
        [groupId]: Array.from(currVariantSet),
      }))
    },
    [selectedVariants, variantGroupsById]
  )

  const isFormValid = useMemo(() => {
    return Object.keys(selectedVariants).reduce((prev, curr) => {
      const currVariantGroup = variantGroupsById[curr]
      const currSelectedVariants = selectedVariants[curr]
      const { minSelection, maxSelection } = currVariantGroup
      const selectedVariantsLength = currSelectedVariants?.length || 0

      return (
        prev &&
        selectedVariantsLength >= minSelection &&
        selectedVariantsLength <= maxSelection
      )
    }, true)
  }, [variantGroupsById, selectedVariants])

  const onChangeNotes = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNotes(event.target.value)
    },
    []
  )

  return (
    item && (
      <Dialog open={open} onClose={onCloseDialog} maxWidth="md">
        <DialogTitle sx={{ minWidth: 400 }}>{item.name}</DialogTitle>
        <DialogContent>
          {variantList &&
            variantList.map(
              ({ id, name, values, minSelection, maxSelection }, idx) => (
                <Box key={id}>
                  <Box display="flex" mt={idx !== 0 ? 2 : 0}>
                    <Typography component="span">{name}</Typography>
                    <Typography color="textSecondary" ml={1}>
                      {t(
                        `views.pos.dialog.variant_select.${getVariantSelectionType(
                          minSelection,
                          maxSelection
                        )}`,
                        {
                          min: minSelection,
                          max: maxSelection,
                        }
                      )}
                    </Typography>
                  </Box>
                  <VariantSelection
                    variantGroupId={id}
                    variants={values || []}
                    minSelection={minSelection}
                    maxSelection={maxSelection}
                    selected={selectedVariants[id] || []}
                    onSelect={onVariantSelect}
                  />
                </Box>
              )
            )}
          <Typography mt={variantList && variantList.length > 0 ? 3 : 0}>
            {t('views.pos.dialog.variant_select.notes.header')}
          </Typography>
          <TextField
            fullWidth
            value={notes}
            multiline
            placeholder={t('views.pos.dialog.variant_select.notes.placeholder')}
            onChange={onChangeNotes}
            minRows={3}
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog}>{t('button.label.cancel')}</Button>
          <Button onClick={onConfirmClick} disabled={!isFormValid}>
            {t('button.label.add')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}

export default VariantSelectDialog
