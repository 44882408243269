import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { ConfirmDialogProps } from './types'
import { useLocalization } from '@tokoku-universe/react-core/localization'

function ConfirmDialog(props: ConfirmDialogProps) {
  const {
    open = false,
    onClose,
    onConfirm,
    confirmDisabled = false,
    title,
    content,
    children,
  } = props
  const { t } = useLocalization()

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      {title && <DialogTitle>{title}</DialogTitle>}
      {content && <DialogContent>{content}</DialogContent>}
      {children}
      <DialogActions>
        <Button onClick={onClose}>{t('button.label.cancel')}</Button>
        <Button
          disabled={confirmDisabled}
          variant="contained"
          onClick={onConfirm}
        >
          {t('button.label.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
