import { StoreChannel } from './types'

export const channelConfig = {
  [StoreChannel.PointOfSale]: {
    key: StoreChannel.PointOfSale,
    enabled: false,
    color: '#fff',
    imageSrc: '',
  },
  [StoreChannel.GrabFood]: {
    key: StoreChannel.GrabFood,
    enabled: true,
    color: '#00b14f',
    imageSrc: 'https://tokoku-web-assets.pages.dev/images/grabfood-white.png',
  },
  [StoreChannel.GoFood]: {
    key: StoreChannel.GoFood,
    enabled: true,
    color: '#ef2234',
    imageSrc: 'https://tokoku-web-assets.pages.dev/images/gofood-white.webp',
  },
}
