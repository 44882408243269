import {
  Navigate,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom'
import Home from '../views/Home'
import PointOfSale from '../views/PointOfSale'
import OrderHistory from '../views/OrderHistory'
import CreateOrder from '../views/CreateOrder'
import Settings from '../views/Settings'
import NotFound from '../views/NotFound'
import Login from '../views/Login'
import ForgotPassword from '../views/ForgotPassword'
import RegisterDevice from '../views/RegisterDevice'
import MainLayout from '../layout/MainLayout'
import PublicLayout from '../layout/PublicLayout'
import SharedLayout from '../layout/SharedLayout'
import { RoutePath } from './types'

const privateRoutes: RouteObject = {
  path: RoutePath.Base,
  element: <MainLayout />,
  children: [
    {
      path: RoutePath.Base,
      element: <Navigate to={RoutePath.Home} replace />,
    },
    {
      path: RoutePath.Home,
      element: <Home />,
    },
    {
      path: RoutePath.PointOfSale,
      element: <PointOfSale />,
    },
    {
      path: RoutePath.Orders,
      element: <OrderHistory />,
    },
    {
      path: RoutePath.CreateOrder,
      element: <CreateOrder />,
    },
    {
      path: RoutePath.Settings,
      element: <Settings />,
    },
    {
      path: RoutePath.SettingsKey,
      element: <Settings />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ],
}

const publicRoutes: RouteObject = {
  path: RoutePath.Base,
  element: <PublicLayout />,
  children: [
    {
      path: RoutePath.Base,
      element: <Navigate to={RoutePath.Login} replace />,
    },
    {
      path: RoutePath.Login,
      element: <Login />,
    },
    {
      path: RoutePath.ForgotPassword,
      element: <ForgotPassword />,
    },
  ],
}

const sharedRoutes: RouteObject = {
  path: RoutePath.Base,
  element: <SharedLayout />,
  children: [
    {
      path: RoutePath.Base,
      element: <Navigate to={RoutePath.Home} replace />,
    },
    {
      path: RoutePath.RegisterDevice,
      element: <RegisterDevice />,
    },
  ],
}

const router = createBrowserRouter([sharedRoutes, privateRoutes, publicRoutes])

function Routes() {
  return <RouterProvider router={router} />
}

export default Routes
